const sessionStorageService = {
  removeItem: function(key) {
    try {
      sessionStorage.removeItem(key);
    } catch (err) {
      return undefined;
    }
  },

  setItem: function(key, value) {
    try {
      sessionStorage.setItem(key, value);
    } catch (err) {
      return undefined;
    }
  },

  getItem: function(key) {
    try {
      return sessionStorage.getItem(key);
    } catch (err) {
      return undefined;
    }
  }
};

export default sessionStorageService;
