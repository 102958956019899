import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  INBOX_PAGE_LINK,
  SHOW_PLATFORM_ANNOUNCEMENT,
  SHOW_NEWS_TAB,
  SHOW_NOTIFICATIONS_TAB,
  SHOW_MESSAGES_TAB,
} from "config";
import deviceServices from "services/deviceServices";
import PostIframe from "../../shared/PostIframe/PostIframe";

const propTypes = {
  sessionKey: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  language: PropTypes.string,
};

class InboxPage extends Component {
  render() {
    const SHOW_PROJECT_INBOX = !!this.props.projectId;

    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          hiddenFields={{
            session_key: this.props.sessionKey,
            singleProject: SHOW_PROJECT_INBOX ? "true" : "false",
            ...(SHOW_PROJECT_INBOX && { bundleId: this.props.projectId }),
            showPlatformAnnouncement: SHOW_PLATFORM_ANNOUNCEMENT,
            showNewsTab: SHOW_NEWS_TAB,
            showNotificationsTab: SHOW_NOTIFICATIONS_TAB,
            showChatTab: SHOW_MESSAGES_TAB,
          }}
          url={INBOX_PAGE_LINK}
        />
      </div>
    );
  }
}

InboxPage.propTypes = propTypes;

export default InboxPage;
