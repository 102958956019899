import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  LOGIN,
  PROJECT_LOGIN,
  LOGIN_SSO,
  PROJECT_LOGIN_SSO,
  REGISTER,
  PROJECT_REGISTER
} from "App/Routes";
import GuestLoginDialogContainer from "./GuestLoginDialog/GuestLoginDialogContainer";
import Link from "components/shared/Link/Link";

import {
  USE_LOGIN_HOME_TEMPLATE_1,
  ENABLE_OAUTH_LOGIN,
  ENABLE_FACEBOOK_LOGIN,
  ENABLE_GOOGLE_LOGIN,
  ENABLE_MICROSOFT_LOGIN,
  FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
  ENABLE_REGISTER_PAGES
} from "config";
import localize from "lang/localize";

import urlServices from "services/urlServices";
import deviceServices from "services/deviceServices";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const propTypes = {
  projectId: PropTypes.number,
  language: PropTypes.string,
  /* Guest Login Dialog */
  guestEnabled: PropTypes.bool.isRequired,
  showGuestLoginDialog: PropTypes.bool.isRequired,
  handleOpenGuestLoginDialog: PropTypes.func.isRequired,
  handleCloseGuestLoginDialog: PropTypes.func.isRequired,
  /* OAuth */
  responseFacebook: PropTypes.func.isRequired,
  responseFacebookError: PropTypes.func.isRequired,
  responseGoogle: PropTypes.func.isRequired,
  responseGoogleError: PropTypes.func.isRequired,
  responseMicrosoft: PropTypes.func.isRequired
};

class LoginHomePage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("loginPage")) {
      bodyDOM.className += " loginPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("loginPage")) {
      bodyDOM.classList.remove("loginPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  renderUseSSOButtonContainer() {
    if (USE_LOGIN_HOME_TEMPLATE_1 && ENABLE_OAUTH_LOGIN) {
      return (
        <div className="pure-g">
          <Link
            className="button fullwidth"
            to={
              this.props.projectId
                ? PROJECT_LOGIN_SSO.format(this.props.projectId)
                : LOGIN_SSO
            }
          >
            {localize("button_login_sso", this.props.language)}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  renderGuestLoginSection() {
    if (this.props.projectId && this.props.guestEnabled) {
      return (
        <div className="pure-g">
          <button
            className="button button-login-guest fullwidth cta"
            onClick={this.props.handleOpenGuestLoginDialog}
          >
            {localize("dialog_login_guest_title", this.props.language)}
          </button>
          {this.props.showGuestLoginDialog && this.renderGuestLoginDialog()}
        </div>
      );
    } else {
      return null;
    }
  }

  renderGuestLoginDialog() {
    return (
      <GuestLoginDialogContainer
        showGuestLoginDialog={this.props.showGuestLoginDialog}
        handleCloseGuestLoginDialog={this.props.handleCloseGuestLoginDialog}
      />
    );
  }

  renderSSOLoginSection() {
    if (ENABLE_OAUTH_LOGIN) {
      return (
        <div className="pure-g">
          <div className="pure-u-1-1 topmargin-30 box-sizing-border-box-all">
            <p className="bottommargin textcenter">
              <strong>
                {localize("login_view_sign_in_start", this.props.language)}
              </strong>
            </p>
          </div>
          <div className="button-group-social-login">
            {this.renderFacebookLoginButton()}
            {this.renderGoogleLoginButton()}
            {this.renderMicrosoftLoginButton()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderFacebookLoginButton() {
    if (ENABLE_FACEBOOK_LOGIN) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5 box-sizing-border-box-all">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            scope="email"
            callback={this.props.responseFacebook}
            onFailure={this.props.responseFacebookError}
            cssClass={"button button-fb"}
            textButton={""}
            icon="fas fa-facebook"
            isMobile={false}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderGoogleLoginButton() {
    if (ENABLE_GOOGLE_LOGIN) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5">
          <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
            <GoogleLogin
              onSuccess={this.props.responseGoogle}
              onError={this.props.responseGoogleError}
              type="icon"
              shape="circle"
            />
          </GoogleOAuthProvider>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMicrosoftLoginButton() {
    if (ENABLE_MICROSOFT_LOGIN) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5 box-sizing-border-box-all">
          <button
            id="msLoginButton"
            className="button button-microsoft"
            type="button"
            onClick={this.props.responseMicrosoft}
          >
            <i className="button-microsoft-icon" />
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  renderRegisterSection() {
    if (ENABLE_REGISTER_PAGES) {
      return (
        <div className="topmargin-30">
          <p className="textcenter">
            <strong>
              {localize("login_view_register_start", this.props.language) + " "}
            </strong>
            <Link
              to={
                this.props.projectId
                  ? PROJECT_REGISTER.format(this.props.projectId)
                  : REGISTER
              }
              className="default"
            >
              {localize("login_view_signup_text", this.props.language)}
            </Link>
          </p>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    if (USE_LOGIN_HOME_TEMPLATE_1) {
      return (
        <div className="login-register-container container verticalpadding">
          <div className="innerblock pure-g">
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
            <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2 login-panel">
              <div className="pure-g topmargin-20">
                <Link
                  className="button fullwidth cta"
                  to={
                    this.props.projectId
                      ? PROJECT_LOGIN.format(this.props.projectId)
                      : LOGIN
                  }
                >
                  {localize("button_login_email", this.props.language)}
                </Link>
              </div>
              {this.renderUseSSOButtonContainer()}
            </div>
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-register-container container verticalpadding">
          <div className="innerblock pure-g">
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
            <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2 login-panel">
              <div className="pure-g topmargin-20">
                <Link
                  className="button fullwidth cta"
                  to={
                    this.props.projectId
                      ? PROJECT_LOGIN.format(this.props.projectId)
                      : LOGIN
                  }
                >
                  {localize("button_login_email", this.props.language)}
                </Link>
              </div>
              {this.renderGuestLoginSection()}
              {/* Add hidesso=true to query string in order to hide sso login, e.g. /login?hidesso=true */}
              {urlServices.getUrlParamValueByKey("hidesso") !== "true" &&
                !deviceServices.isWebView() &&
                this.renderSSOLoginSection()}
              {ENABLE_REGISTER_PAGES && this.renderRegisterSection()}
            </div>
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          </div>
        </div>
      );
    }
  }
}

LoginHomePage.propTypes = propTypes;

export default LoginHomePage;
