import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./MobileProject.css";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import TopicCard from "./TopicCard";

import { SET_PROJECT_BG_IMG } from "config";
import localize from "lang/localize";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number,
  projectImage: PropTypes.string,
  buttons: PropTypes.array,
  topics: PropTypes.array.isRequired,
  user: PropTypes.object,
  team: PropTypes.object,
  categoryId: PropTypes.string,
  notifications: PropTypes.number.isRequired,
  inboxUnread: PropTypes.number.isRequired,
  more: PropTypes.bool,
  randomNumber: PropTypes.number.isRequired,
  showcaseProject: PropTypes.object,
  addAnchors: PropTypes.func,
  handleMore: PropTypes.func,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  renderTopics: PropTypes.func,
  language: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func,
};

const defaultProps = {
  projectImage: placeholder_project,
  projectId: null,
};

class MobileProjectPage extends Component {
  constructor() {
    super();
    this.state = {
      currentSlide: 0,
    };
  }
  /*
    react-slick's initialSlide does not pair well with React's re-renders.
    Thus, you want to use componentDidUpdate and slickGoTos for allowing
    the slider to jump to the first Topic card or the Project Title card
    in a Topic Category page.
  */
  componentDidUpdate(prevProps) {
    if (this.props.categoryId) {
      this.scrollToTopics();
    }
  }

  componentDidMount() {
    this.scrollToTopicIdFromHash();
  }

  /**
   * Scroll to current Topic ID based on URL hash
   */
  scrollToTopicIdFromHash() {
    /*
      Will not use parseInt() here first, because parseInt("123xyz", 10) === 123.
      Employing stricter controls by directly checking isNaN on the string.
    */
    let topicHashId =
      window.location.hash.indexOf("#topic") === 0
        ? window.location.hash.slice(6)
        : null;

    if (window.location.hash.indexOf("#topic") === 0) {
      topicHashId = window.location.hash.slice(6);
    } else if (window.location.hash.indexOf("#resettedtopic") === 0) {
      topicHashId = window.location.hash.slice(14);
    }

    let screen = document.getElementById("topicMobile" + topicHashId);
    screen && screen.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Retrieve Topic index by Topic ID from an array of Topics
   * @param {int} topicIdKey - Topic ID
   * @param {object} array - Array of Topics
   * @returns
   */
  searchTopicIndexByTopicId(topicIdKey, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === topicIdKey) {
        return i;
      }
    }
  }

  /**
   * Scroll to Project Information screen
   */
  scrollToProjectInfo() {
    // Use ID appPageBase instead of projectInformationContainer to ensure
    // screen scrolls directly to the top
    let screen = document.getElementById("appPageBase");
    screen && screen.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Scroll to Topic List screen
   */
  scrollToTopics() {
    let screen = document.getElementById("topicListContainer");
    screen && screen.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Render list of Topics in the Project
   */
  renderTopicCards() {
    return (
      <div
        className="pure-u-1 mobile-project-topics-wrapper"
        id="topicListContainer"
      >
        <div className="flex justify-content-center topmargin-20">
          <button
            className="button scroll-button"
            onClick={() => this.scrollToProjectInfo()}
          >
            {localize("button_project_information", this.props.language)} &nbsp;
            <i className="fas fa-caret-up" />
          </button>
        </div>
        {this.props.renderTopics(this.props.topics, true)}
        {!this.props.more &&
          !!this.props.showcaseProject &&
          this.renderProjectShowcaseCard()}
      </div>
    );
  }

  /**
   * Render project information
   *
   * @param {object} project - The project object
   */
  renderProjectInfo(project) {
    let anchoredProjectTitle = this.props.addAnchors(project.title);
    let anchoredProjectDescription = this.props.addAnchors(project.description);
    let projectImage = this.props.projectImage;

    return (
      <div className="pure-u-1" id="projectInformationContainer">
        <div className="mobile-project-title-wrapper">
          <div
            id="project"
            className="pure-g horizontalpadding-10 toppadding-20 bottompadding-20"
          >
            <div className="pure-u-1">
              <div className="pure-u-1-2 imgcenter">
                <span className="square-image-wrapper bottommargin-30">
                  <span className="square-image circle">
                    <img src={projectImage} alt={project.title} />
                  </span>
                </span>
              </div>
              <div className="mobile-project-description-wrapper bottommargin-20">
                <div className="mobile-project-description-content">
                  <h4
                    className="textcenter"
                    dangerouslySetInnerHTML={{ __html: anchoredProjectTitle }}
                  />
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: anchoredProjectDescription,
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-content-center">
                <button
                  className="button scroll-button"
                  onClick={() => this.scrollToTopics()}
                >
                  {localize("button_project_topics", this.props.language)}{" "}
                  &nbsp;
                  <i className="fas fa-caret-down" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Old UI - to be removed in next major version, keep for transition period to help with upstream
  /*
  renderMobileProjectHome() {
    let name = this.props.user ? this.props.user.name : "";
    let team = this.props.team;
    let teamImage = team && team.image ? team.image : placeholder_team;

    let points =
      this.props.user && this.props.user.points ? this.props.user.points : 0;

    return (
      <div
        key={"projecthome"}
        className="pure-u-g mobile-project-main toppadding-20"
      >
        <div className="swipe-to-nav">
          <img src={swipe_icon} alt="" />
          <div className="swipe-to-nav-text text-shadow">
            {localize("swipe_to_navigate", this.props.language)}
          </div>
        </div>
        {this.props.project && this.props.project.searchEnabled && (
          <SearchBar
            handleKeyPress={this.props.handleKeyPress}
            handleSearchChange={this.props.handleSearchChange}
            handleSubmit={this.props.handleSubmit}
            button={false}
            language={this.props.language}
          />
        )}
        <div className="mobile-project-sidebar">
          <div className="mobile-project-profile">
            <div className="pure-u-g mobile-project-profile-button-container">
              <div className="pure-u-7-24">
                {this.renderProfileImageLink(name)}
              </div>
              {this.renderMobileProjectInboxIcon()}
              {this.renderMobileProjectNotificationIcon()}
              {this.props.project &&
                this.props.project.teamEnabled &&
                team &&
                team.id && (
                  <div className="pure-u-4-24">
                    <Link
                      to={PROJECT_TEAM.format(this.props.projectId, team.id)}
                    >
                      <span className="square-image-wrapper">
                        <span className="square-image circle mobile-project-profile-button-border">
                          <img src={teamImage} alt="" />
                        </span>
                      </span>
                    </Link>
                  </div>
                )}
            </div>
            <div className="mobile-project-profile-link text-truncated">
              {this.renderProfileNameLink(name)}
            </div>
            <div className="mobile-project-profile-link text-truncated">
              {this.renderProfilePointsLink(points)}
            </div>
          </div>
          <SidebarLinks
            projectId={this.props.project.id}
            showTeams={this.props.project.teamEnabled}
            buttons={this.props.buttons}
            language={this.props.language}
          />
        </div>
      </div>
    );
  }

  renderProfileImageLink(name) {
    let photoSmall = this.props.user
      ? this.props.user.photoSmall
      : placeholder_profile;

    if (this.props.user && this.props.user.id) {
      return (
        <Link to={PROJECT_PROFILE.format(this.props.projectId)}>
          <span className="square-image-wrapper">
            <span className="square-image circle mobile-project-profile-button-border">
              <img src={photoSmall} alt={name} />
            </span>
          </span>
        </Link>
      );
    } else {
      return (
        <span className="square-image-wrapper">
          <span className="square-image circle mobile-project-profile-button-border">
            <img
              src={placeholder_profile}
              alt={localize("require_login_view_profile", this.props.language)}
            />
          </span>
        </span>
      );
    }
  }

  renderProfileNameLink(name) {
    if (this.props.user && this.props.user.id) {
      return (
        <Link
          to={PROJECT_PROFILE.format(this.props.projectId)}
          className="text-shadow"
        >
          {name}
        </Link>
      );
    } else {
      return (
        <span className="text-shadow">
          {localize("require_login_view_profile", this.props.language)}
        </span>
      );
    }
  }


  renderProfilePointsLink(points) {
    if (this.props.user && this.props.user.id) {
      return (
        <Link
          to={PROJECT_PROFILE.format(this.props.projectId)}
          className="text-shadow"
        >
          <strong className="mobile-project-profile-points-text">
            {points.abbreviateNumber() + " "}
          </strong>
          <span className="mobile-project-points-text">
            {localize("points_just_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return this.renderProfileLoginButton();
    }
  }

  renderProfileLoginButton() {
    return (
      <Link
        to={
          ENABLE_LOGIN_HOME
            ? PROJECT_LOGIN_HOME.format(this.props.projectId)
            : PROJECT_LOGIN.format(this.props.projectId)
        }
        className="topmargin-5 button button-login inline-block"
      >
        {localize("button_login", this.props.language)}
      </Link>
    );
  }

  renderMobileProjectInboxIcon() {
    if (ENABLE_INBOX && this.props.user && this.props.user.id) {
      return (
        <div className="pure-u-4-24">
          <Link
            to={PROJECT_INBOX.format(this.props.projectId)}
            className="mobile-project-profile-button mobile-project-profile-button-border"
          >
            <i className="fas fa-comments" aria-hidden="true" />
            {this.props.inboxUnread && this.props.inboxUnread > 0 ? (
              <div className="notification-dot" id="unreadInboxDot" />
            ) : null}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMobileProjectNotificationIcon() {
    if (this.props.user && this.props.user.id && ENABLE_NOTIFICATIONS_PAGES) {
      return (
        <div className="pure-u-4-24">
          <Link
            to={PROJECT_NOTIFICATIONS.format(this.props.projectId)}
            className="mobile-project-profile-button mobile-project-profile-button-border"
          >
            <i className="fas fa-bell" aria-hidden="true" />
            {this.props.notifications && this.props.notifications > 0 ? (
              <div className="notification-dot" id="unreadNotificationDot" />
            ) : null}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }*/

  // Old UI - to be removed in next major version, keep for transition period to help with upstream
  /*
  renderProjectTitle(project) {
    let anchoredProjectTitle = this.props.addAnchors(project.title);
    let anchoredProjectDescription = this.props.addAnchors(project.description);
    let projectImage = this.props.projectImage;

    return (
      <div className="pure-u-1" key={"projectittle"}>
        <div className="card project-title-card project-title-card-bg">
          <div className="card-height">
            <div className="inner-card">
              {this.renderProjectShareButton()}
              <img
                className="circle iconlarge imgcenter padding"
                src={projectImage}
                alt={project.title}
              />
              <h4 dangerouslySetInnerHTML={{ __html: anchoredProjectTitle }} />
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: anchoredProjectDescription }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProjectShareButton() {
    if (this.props.project && this.props.project.projectShareLink) {
      return (
        <button
          className="button icon info inline"
          onClick={this.props.handleOpenShareGameDialog}
        >
          <i className="fas fa-share-alt"></i>
        </button>
      );
    } else {
      return null;
    }
  }
  */

  /**
   * Render Showcase Topic Card for current Project
   */
  renderProjectShowcaseCard() {
    let showcaseImage = this.props.showcaseProject.showcaseImage;
    let showcaseTitle = this.props.showcaseProject.showcaseTagline;
    let showcaseDescription = this.props.showcaseProject.showcaseDescription;
    let showcaseProjectId = this.props.showcaseProject.projectId;

    return (
      <TopicCard
        sessionKey={this.props.sessionKey}
        projectId={this.props.projectId}
        isMobile={true}
        title={showcaseTitle}
        description={showcaseDescription}
        img={showcaseImage}
        id={showcaseProjectId}
        showcase={true}
        completed={false}
        language={this.props.language}
        totalChallenges={0}
        completedChallenges={0}
        started={false}
        locked={false}
        expired={false}
      />
    );
  }

  render() {
    let projectBg = this.props.project.backgroundImage;

    return (
      <Fragment>
        <div
          className="mobile-project-wrapper"
          style={
            SET_PROJECT_BG_IMG && projectBg
              ? { backgroundImage: `url(${projectBg})` }
              : null
          }
        >
          {this.renderProjectInfo(this.props.project)}
          {this.renderTopicCards()}
        </div>
      </Fragment>
    );
  }
}

MobileProjectPage.propTypes = propTypes;
MobileProjectPage.defaultProps = defaultProps;

export default MobileProjectPage;
