import { PublicClientApplication } from "@azure/msal-browser";
import { ENABLE_MICROSOFT_LOGIN, MICROSOFT_APP_ID, PLAYER_URL } from "config";
import deviceServices from "services/deviceServices";

// Domain used for login
const MICROSOFT_LOGIN_DOMAIN = "https://login.microsoftonline.com/common";

// For desktop, popup method is favourable
// For mobile, only redirect method works
// Popup method is buggy for first time login when popup permission is not granted yet.
const USE_REDIRECT_FOR_DESKTOP = true;

// Used for determining whether token is retrieved
export const MICROSOFT_SESSION_TOKEN = "msal_token";

// Used for determining whether session was created before
export const MICROSOFT_SESSION = "msal_session";

export const MICROSOFT_LOGIN_USE_REDIRECT =
  deviceServices.isMobile() || USE_REDIRECT_FOR_DESKTOP;

const MICROSOFT_APP_CONFIGS_POPUP = {
  auth: {
    clientId: MICROSOFT_APP_ID,
    authority: MICROSOFT_LOGIN_DOMAIN,
    redirectUri: PLAYER_URL
  },
  cache: {
    cacheLocation: "sessionStorage",
    // Set this to "true" if you are having issues on IE11 or Edge
    storeAuthStateInCookie: false
  }
};

const MICROSOFT_APP_CONFIGS_REDIRECT = {
  auth: {
    clientId: MICROSOFT_APP_ID,
    authority: MICROSOFT_LOGIN_DOMAIN
    // Leave redirectUri empty so that we can cater to all 3 login layouts
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const MICROSOFT_APP_LOGIN_REQUEST = {
  scopes: ["User.Read"]
};

// To show account selection prompt for every login
export const MICROSOFT_APP_LOGIN_PROMPT_REQUEST = {
  scopes: ["User.Read"],
  prompt: "select_account"
};

export const MICROSOFT_CLIENT_INSTANCE = ENABLE_MICROSOFT_LOGIN
  ? new PublicClientApplication(
      MICROSOFT_LOGIN_USE_REDIRECT
        ? MICROSOFT_APP_CONFIGS_REDIRECT
        : MICROSOFT_APP_CONFIGS_POPUP
    )
  : null;
