import React, { Component } from "react";
import { connect } from "react-redux";

import ChallengeCompletedModal from "./ChallengeCompletedModal";

import { clearChallengeCompleted } from "actions";

/* for routing after challenge completed */
import Router from "router";
import { TOPIC, CHALLENGE, CHALLENGE_A_FRIEND } from "App/Routes";

export const mapStateToProps = (state, ownProps) => {
  return {
    challengeCompletedArr: state.challengeCompleted,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearChallengeCompleted: id => {
      dispatch(clearChallengeCompleted(id));
    }
  };
};

export class ChallengeCompletedModalContainer extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.goToNextChallenge = this.goToNextChallenge.bind(this);
    this.goToChallengeAFriend = this.goToChallengeAFriend.bind(this);
    this.goToNextTopic = this.goToNextTopic.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.challengeCompletedArr !== prevProps.challengeCompletedArr) {
      this.handleOpenModal();
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal(challengeCompletedId) {
    this.setState({ showModal: false }, () => {
      this.props.clearChallengeCompleted(challengeCompletedId);
    });
  }

  goToNextTopic(topicIdNext, lastChallengeCompletedId) {
    Router.navigate(TOPIC.format(topicIdNext));
    this.handleCloseModal(lastChallengeCompletedId);
  }

  goToNextChallenge(nextChallengeId, lastChallengeCompletedId) {
    Router.navigate(CHALLENGE.format(nextChallengeId));
    this.handleCloseModal(lastChallengeCompletedId);
  }

  goToChallengeAFriend(challengeId, lastChallengeCompletedId) {
    Router.navigate(CHALLENGE_A_FRIEND.format(challengeId));
    this.handleCloseModal(lastChallengeCompletedId);
  }

  render() {
    return (
      <ChallengeCompletedModal
        challengeCompletedArr={this.props.challengeCompletedArr}
        showModal={this.state.showModal}
        handleCloseModal={this.handleCloseModal}
        goToNextChallenge={this.goToNextChallenge}
        goToChallengeAFriend={this.goToChallengeAFriend}
        language={this.props.language}
        goToNextTopic={this.goToNextTopic}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCompletedModalContainer);
