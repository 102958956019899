import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchBar from "components/shared/SearchBar/SearchBar";
import User from "./User";
import localize from "lang/localize";
import Loading from "components/shared/Loading";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  players: PropTypes.array.isRequired,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  searchedPlayersExist: PropTypes.bool,
  language: PropTypes.string,
  isLoadingMore: PropTypes.bool,
  handleFollow: PropTypes.func,
  error: PropTypes.string
};

const defaultProps = {
  searchedPlayersExist: true
};

class UsersPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("usersPage")) {
      bodyDOM.className += " usersPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("usersPage")) {
      bodyDOM.classList.remove("usersPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderPlayers() {
    return this.props.players.map(player => (
      <User
        projectId={this.props.projectId}
        userId={this.props.userId}
        isFollowing={player.followed}
        key={player.id}
        id={player.id}
        name={player.name}
        img={player.photoLarge}
        handleFollow={this.props.handleFollow}
        error={this.props.error}
      />
    ));
  }

  render() {
    return (
      <div className="teams container verticalpadding bottompadding-floating">
        <div className="innerblock pure-g">
          <div className="pure-u-1 pure-u-md-16-24 automargin">
            <div className="verticalpadding" />
            <SearchBar
              handleSubmit={this.props.handleSubmit}
              handleSearchChange={this.props.handleSearchChange}
              placeholder={localize(
                "general_search_placeholder_text",
                this.props.language
              )}
              language={this.props.language}
            />
            <div className="verticalpadding" />
            <div />
            {!this.props.searchedPlayersExist && (
              <p className="textcenter">
                {localize("empty_users_search_text", this.props.language)}
              </p>
            )}
            {this.renderPlayers()}
            {this.props.isLoadingMore ? <Loading /> : null}
            {/* {this.props.more && (
              <button
                className="button automargin"
                onClick={this.props.handleMore}
              >
                {localize("more_text", this.props.language)}
              </button>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = propTypes;
UsersPage.defaultProps = defaultProps;

export default UsersPage;
