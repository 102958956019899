import React, { Component } from "react";
import PropTypes from "prop-types";

import MyProjectCard from "./MyProjectCard";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  img: PropTypes.string,
  removeProject: PropTypes.func.isRequired,
  private: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  language: PropTypes.string
};

class MyProjectCardContainer extends Component {
  constructor() {
    super();
    this.state = {
      showUnjoinProjectDialog: false,
      showShareGameDialog: false
    };

    this.handleOpenUnjoinProjectDialog = this.handleOpenUnjoinProjectDialog.bind(
      this
    );
    this.handleCloseUnjoinProjectDialog = this.handleCloseUnjoinProjectDialog.bind(
      this
    );
    this.handleOpenShareGameDialog = this.handleOpenShareGameDialog.bind(this);
    this.handleCloseShareGameDialog = this.handleCloseShareGameDialog.bind(
      this
    );
  }

  handleOpenUnjoinProjectDialog(event) {
    event.preventDefault();
    this.setState({
      showUnjoinProjectDialog: true
    });
  }

  handleCloseUnjoinProjectDialog() {
    this.setState({
      showUnjoinProjectDialog: false
    });
  }

  handleOpenShareGameDialog(event) {
    event.preventDefault();

    this.setState({
      showShareGameDialog: true
    });
  }

  handleCloseShareGameDialog() {
    this.setState({
      showShareGameDialog: false
    });
  }

  render() {
    return (
      <MyProjectCard
        title={this.props.title}
        description={this.props.description}
        img={this.props.img}
        id={this.props.id}
        showUnjoinProjectDialog={this.state.showUnjoinProjectDialog}
        handleOpenUnjoinProjectDialog={this.handleOpenUnjoinProjectDialog}
        handleCloseUnjoinProjectDialog={this.handleCloseUnjoinProjectDialog}
        showShareGameDialog={this.state.showShareGameDialog}
        handleOpenShareGameDialog={this.handleOpenShareGameDialog}
        handleCloseShareGameDialog={this.handleCloseShareGameDialog}
        removeProject={this.props.removeProject}
        private={this.props.private}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }
}

MyProjectCardContainer.propTypes = propTypes;

export default MyProjectCardContainer;
