import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AchievementsPage from "./AchievementsPage";
import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { setProject, setButtons } from "actions";
import {
  GET_USER_ITEMS,
  GET_PROJECT_ITEMS,
  GET_TOPICS,
  GET_USER,
} from "services/api";
import { USER, PROJECT_USER } from "App/Routes";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

const propTypes = {
  id: PropTypes.string,
};

const defaultProps = {
  id: null,
};

export class AchievementsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      user: null,
      teamItems: null,
      individualItems: null,
      ownProfile: !props.id || props.user.id === parseInt(props.id, 10),
      id: props.id || props.user.id,
      topics: null,
      currentBoard: "individual",
      teamAvailable: false,
      error: null,
      code: null,
    };
  }

  getPageData() {
    if (this.props.projectId) {
      this.getProjectItems();
      /* Retrieve Project information */
      this.getTopics();
    } else {
      this.getUserItems();
    }
    this.getUserProfile();
  }

  componentDidMount() {
    if (!this.state.ownProfile || this.props.sessionKey) {
      this.getPageData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getPageData();
    }
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });
        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  getUserItems() {
    // does not support pagination
    getApiGenerator(
      GET_USER_ITEMS.format(this.state.id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        // 500 return means no achievements
        if (res.body.code === 500) {
          this.setState({ items: [] });
        }
      } else {
        const categorizedItems = res.body.data.reduce(
          (acc, item) => {
            if (item.achievementType === "team") {
              acc.team.push(item);
            } else {
              acc.individual.push(item);
            }
            return acc;
          },
          { team: [], individual: [] },
        );
        this.setState(
          {
            items: res.body.data,
            teamItems: categorizedItems.team,
            individualItems: categorizedItems.individual,
          },
          () => {
            if (
              this.state.items.some((item) => item.achievementType === "team")
            ) {
              this.setState({ currentBoard: "team", teamAvailable: true });
            }
          },
        );
      }
    });
  }

  getProjectItems() {
    // does not support pagination
    getApiGenerator(
      GET_PROJECT_ITEMS.format(this.props.projectId),
      {
        user_id: this.state.id,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ items: [] });
        }
      } else {
        const categorizedItems = res.body.data.reduce(
          (acc, item) => {
            if (item.achievementType === "team") {
              acc.team.push(item);
            } else {
              acc.individual.push(item);
            }
            return acc;
          },
          { team: [], individual: [] },
        );
        this.setState(
          {
            items: res.body.data,
            teamItems: categorizedItems.team,
            individualItems: categorizedItems.individual,
          },
          () => {
            if (
              this.state.items.some((item) => item.achievementType === "team")
            ) {
              this.setState({ currentBoard: "team", teamAvailable: true });
            }
          },
        );
      }
    });
  }

  /**
   * Calls the user profile API to retrieve user information
   */
  getUserProfile() {
    getApiGenerator(
      GET_USER.format(this.state.id),
      {
        bundle_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            user: false,
          });
        }
      } else {
        this.setState({
          user: res.body,
        });
      }
    });
  }

  /**
   * Get profile link
   */
  getProfileLink() {
    if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.state.id);
    } else {
      return USER.format(this.state.id);
    }
  }

  handleToggleBoard = (event) => {
    this.setState({ currentBoard: event.currentTarget.id });
  };

  render() {
    const { currentBoard, teamAvailable, items, teamItems, individualItems } =
      this.state;

    if (this.state.ownProfile && !this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
          fullHeight={true}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (items && items.length !== 0) {
      let currentItems = currentBoard === "team" ? teamItems : individualItems;
      return (
        <AchievementsPage
          items={currentItems ? currentItems : []}
          language={this.props.language}
          projectId={this.props.projectId}
          board={currentBoard}
          teamAvailable={teamAvailable}
          profileLink={this.getProfileLink()}
          user={this.state.user}
          handleToggleBoard={this.handleToggleBoard}
        />
      );
    } else if (this.state.items) {
      return (
        <GenericErrorPage
          message={localize("empty_item_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

AchievementsContainer.propTypes = propTypes;
AchievementsContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AchievementsContainer);
