import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./DesktopProject.css";
import Loading from "components/shared/Loading";

import { SHOW_TOPIC_CATEGORIES } from "config";
import localize from "lang/localize";
import TopicCard from "./TopicCard";

const propTypes = {
  categoryId: PropTypes.string,
  categories: PropTypes.array,
  topics: PropTypes.array.isRequired,
  more: PropTypes.bool,
  randomNumber: PropTypes.number.isRequired,
  showcaseProject: PropTypes.object,
  addAnchors: PropTypes.func,
  handleMore: PropTypes.func,
  renderTopics: PropTypes.func,
  language: PropTypes.string,
  isLoadingMore: PropTypes.bool,
};

class DesktopProjectPage extends Component {
  renderCategory() {
    let category = this.props.categories.filter(
      (category) =>
        category.hasOwnProperty("selected") && category.selected === true,
    )[0];

    let categoryTitle = category ? category.title : "";
    let anchoredCategoryTitle =
      categoryTitle !== "" ? this.props.addAnchors(categoryTitle) : "";

    let categoryDescription = category ? category.description : "";
    let anchoredCategoryDescription =
      categoryDescription !== ""
        ? this.props.addAnchors(categoryDescription)
        : "";

    if (this.props.categoryId) {
      return (
        <div className={"pure-u-1 " + (category ? "category-container" : "")}>
          <div className="category-title-icon">
            {/* Commented out the below until Topic Category icons are supported */}
            {/*{category &&
              category.image &&
              this.renderCategoryImage(category.image)}
            {category && category.image && (
              <div className="pure-u-1 pure-u-sm-1-12" />
            )}
            <div
              className={
                "pure-u-1 " +
                (category && category.image
                  ? "pure-u-sm-19-24 "
                  : "pure-u-sm-1-1 ")
              }
            >*/}

            {/* When Topic Category icons are supported, remove the 3 lines below and uncomment the lines above */}
            <div className="pure-u-1 pure-u-sm-1-1">
              <h3
                className="category-title"
                dangerouslySetInnerHTML={{ __html: anchoredCategoryTitle }}
              />
            </div>
          </div>
          <div className="category-description">
            <p
              dangerouslySetInnerHTML={{ __html: anchoredCategoryDescription }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderCategoryImage(img) {
    return (
      <div className="pure-u-1 pure-u-sm-1-8">
        <div
          className="category-img"
          style={{
            backgroundImage: "url(" + img + ")",
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
    );
  }

  renderProjectShowcaseCard() {
    let showcaseImage = this.props.showcaseProject.showcaseImage;
    let showcaseTitle = this.props.showcaseProject.showcaseTagline;
    let showcaseDescription = this.props.showcaseProject.showcaseDescription;
    let showcaseProjectId = this.props.showcaseProject.projectId;

    return (
      <TopicCard
        sessionKey={this.props.sessionKey}
        projectId={this.props.projectId}
        isMobile={false}
        title={showcaseTitle}
        description={showcaseDescription}
        img={showcaseImage}
        id={showcaseProjectId}
        showcase={true}
        completed={false}
        language={this.props.language}
        totalChallenges={0}
        completedChallenges={0}
        started={false}
        locked={false}
        expired={false}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <div className="pure-u-1-1 pure-u-lg-2-24" />
        <div className="pure-u-1-1 pure-u-lg-20-24">
          {SHOW_TOPIC_CATEGORIES &&
            this.props.categories &&
            this.renderCategory()}
          {this.props.topics.length !== 0 ? (
            /* isMobile is false */
            this.props.renderTopics(this.props.topics, false)
          ) : (
            <p className="textcenter verticalpadding">
              {localize("empty_game_table_text", this.props.language)}
            </p>
          )}
          {this.props.topics.length !== 0 &&
            !this.props.more &&
            !!this.props.showcaseProject &&
            this.renderProjectShowcaseCard()}
          {this.props.isLoadingMore ? <Loading /> : null}
        </div>
        <div className="pure-u-1-1 pure-u-lg-2-24" />
      </Fragment>
    );
  }
}

DesktopProjectPage.propTypes = propTypes;

export default DesktopProjectPage;
