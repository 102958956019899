import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CLAIM } from "App/Routes";
import ClaimResult from "./ClaimResult";

import Router from "router";
import getApiGenerator from "services/getApiGenerator";
import { GET_TOPICS, DELETE_CLAIM } from "services/api";
import localize from "lang/localize";

import { showAlertWithTimeout } from "actions";

const propTypes = {
  id: PropTypes.number.isRequired,
  claimUserId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  type: PropTypes.oneOf(["project", "challenge", "claim"]).isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  claimImage: PropTypes.string,
  claimVideo: PropTypes.string,
  claimVideoContainer: PropTypes.string,
  claimVideoThumbnail: PropTypes.string,
  entries: PropTypes.array,
  claimMessage: PropTypes.string,
  likeNo: PropTypes.number,
  commentNo: PropTypes.number,
  ratedLike: PropTypes.bool,
  handleLike: PropTypes.func,
  removeClaim: PropTypes.func,
  privateClaim: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  handleComments: PropTypes.func,
  context: PropTypes.string,
};

const defaultProps = {
  ratedLike: false,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export class ClaimResultContainer extends Component {
  constructor() {
    super();

    this.state = {
      showConfirmDeleteCompletionDialog: false,
      showLoginDialog: false,
      contactEmail: null,
      showVideoDialog: false,
    };

    this.handleClaimClick = this.handleClaimClick.bind(this);
    this.handleDeleteCompletion = this.handleDeleteCompletion.bind(this);

    // Confirmation Dialog: Delete Completion
    this.handleOpenConfirmDeleteCompletionDialog =
      this.handleOpenConfirmDeleteCompletionDialog.bind(this);
    this.handleCloseConfirmDeleteCompletionDialog =
      this.handleCloseConfirmDeleteCompletionDialog.bind(this);

    // Login Dialogs
    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this);
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this);

    // Video Dialogs
    this.handleOpenVideoDialog = this.handleOpenVideoDialog.bind(this);
    this.handleCloseVideoDialog = this.handleCloseVideoDialog.bind(this);
  }

  componentDidMount() {
    if (this.props.projectId) {
      this.getSupportEmail();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projectId !== prevProps.projectId) {
      this.getSupportEmail();
    }
  }

  /**
   * Execute delete completion API call
   *
   * @param {e} event - The click event
   * @param {int} type_id - Completion ID
   */
  deleteCompletion(type_id) {
    getApiGenerator(
      DELETE_CLAIM,
      {
        type_id: type_id,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        showAlertWithTimeout({
          text: localize("claim_cell_delete_success", this.props.language),
          type: "success",
        });
      }
    });
  }

  /**
   * Handle delete completion
   */
  handleDeleteCompletion() {
    this.deleteCompletion(this.props.id);
    this.props.removeClaim(this.props.id);
    this.handleCloseConfirmDeleteCompletionDialog();
  }

  /**
   * Handle clicks on completion
   * @param {e} event
   */
  handleClaimClick(event) {
    if (!event.target.classList.contains("secondaryLink")) {
      event.preventDefault();
      Router.navigate(CLAIM.format(this.props.id));
    }
  }

  /**
   * Open the "Confirm Delete Completion" Dialog
   */
  handleOpenConfirmDeleteCompletionDialog() {
    this.setState({
      showConfirmDeleteCompletionDialog: true,
    });
  }

  /**
   * Close the "Confirm Delete Completion" Dialog
   */
  handleCloseConfirmDeleteCompletionDialog() {
    this.setState({
      showConfirmDeleteCompletionDialog: false,
    });
  }

  /**
   * Open the Login Prompt Dialog
   */
  handleOpenLoginDialog() {
    this.setState({
      showLoginDialog: true,
    });
  }

  /**
   * Close the Login Prompt Dialog
   */
  handleCloseLoginDialog() {
    this.setState({
      showLoginDialog: false,
    });
  }

  /**
   * Open the Video Player Dialog
   */
  handleOpenVideoDialog() {
    this.setState({
      showVideoDialog: true,
    });
  }

  /**
   * Close the Video Player Dialog
   */
  handleCloseVideoDialog() {
    this.setState({
      showVideoDialog: false,
    });
  }

  /**
   * Call Project API to retrieve support email
   */
  getSupportEmail(page = 1, category_id = "") {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
        }
      } else {
        this.setState({ contactEmail: res.body.game.contactEmail });
      }
    });
  }

  /**
   * Renders the component
   */
  render() {
    return (
      <ClaimResult
        sessionKey={this.props.sessionKey}
        id={this.props.id}
        claimUserId={this.props.claimUserId}
        userId={this.props.userId}
        type={this.props.type}
        challengeTypeId={this.props.challengeTypeId}
        claimImage={this.props.claimImage}
        claimVideo={this.props.claimVideo}
        claimVideoContainer={this.props.claimVideoContainer}
        claimVideoThumbnail={this.props.claimVideoThumbnail}
        entries={this.props.entries}
        claimMessage={this.props.claimMessage}
        likeNo={this.props.likeNo}
        commentNo={this.props.commentNo}
        ratedLike={this.props.ratedLike}
        handleLike={this.props.handleLike}
        removeClaim={this.props.removeClaim}
        privateClaim={this.props.privateClaim}
        userName={this.props.userName}
        handleComments={this.props.handleComments}
        isLoggedIn={!!this.props.userId}
        language={this.props.language}
        contactEmail={this.state.contactEmail}
        handleClaimClick={this.handleClaimClick}
        handleDeleteCompletion={this.handleDeleteCompletion}
        // Confirmation Dialog: Delete Completion
        showConfirmDeleteCompletionDialog={
          this.state.showConfirmDeleteCompletionDialog
        }
        handleOpenConfirmDeleteCompletionDialog={
          this.handleOpenConfirmDeleteCompletionDialog
        }
        handleCloseConfirmDeleteCompletionDialog={
          this.handleCloseConfirmDeleteCompletionDialog
        }
        // Login Dialogs
        showLoginDialog={this.state.showLoginDialog}
        handleOpenLoginDialog={this.handleOpenLoginDialog}
        handleCloseLoginDialog={this.handleCloseLoginDialog}
        // Video Dialogs
        showVideoDialog={this.state.showVideoDialog}
        handleOpenVideoDialog={this.handleOpenVideoDialog}
        handleCloseVideoDialog={this.handleCloseVideoDialog}
        context={this.props.context}
      />
    );
  }
}

ClaimResultContainer.propTypes = propTypes;
ClaimResultContainer.defaultProps = defaultProps;

export default connect(mapStateToProps)(ClaimResultContainer);
