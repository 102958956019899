import React, { Component } from "react";
import PropTypes from "prop-types";

import { PROJECT_USER, USER, PROJECT_PROFILE, PROFILE } from "App/Routes";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

import "./Follows.css";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  userRankStatusImage: PropTypes.string,
  userRankStatus: PropTypes.string,
  type: PropTypes.oneOf(["following", "followers"]).isRequired,
  userFriended: PropTypes.bool,
  userFollowed: PropTypes.bool,
  userIsFollowedBy: PropTypes.bool,
  handleFollow: PropTypes.func.isRequired,
};

class Follows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: this.props.userFollowed
        ? localize("button_user_following")
        : this.props.userIsFollowedBy
          ? localize("button_user_follow_back")
          : localize("button_user_follow"),
      buttonActive: !this.props.userFollowed,
    };
    this.setButtonState = this.setButtonState.bind(this);
    this.renderFollowButton = this.renderFollowButton.bind(this);
  }

  getLink() {
    if (this.props.userId === this.props.id && this.props.projectId) {
      return PROJECT_PROFILE.format(this.props.projectId);
    } else if (this.props.userId === this.props.id) {
      return PROFILE;
    } else if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.props.id);
    } else {
      return USER.format(this.props.id);
    }
  }

  setButtonState() {
    let buttonText;
    if (this.state.buttonText === localize("button_user_following")) {
      buttonText = this.props.userIsFollowedBy
        ? localize("button_user_follow_back")
        : localize("button_user_follow");
    } else {
      buttonText = localize("button_user_following");
    }

    this.setState({
      buttonText: buttonText,
      buttonActive: !this.state.buttonActive,
    });
  }

  renderFollowButton() {
    return (
      <button
        className={
          "button verticalcenter follows-button " +
          (this.state.buttonActive ? "cta" : "inactive")
        }
        onClick={(e) => {
          e.preventDefault();
          this.props.handleFollow(this.props.id);
          this.setButtonState();
        }}
      >
        {this.state.buttonText}
      </button>
    );
  }

  render() {
    let img = this.props.img;
    let name = this.props.name;
    let link = this.getLink();

    return (
      <Link to={link}>
        <div className="pure-g bottompadding">
          <div className="pure-u-2-24">
            <img
              className="hide-from-sm iconsmall circle"
              src={img}
              alt={name}
            />
            <img
              className="hide-below-sm iconmedium circle"
              src={img}
              alt={name}
            />
            {/* <div id="rankStatus" className="photo-icon-wrap">
                <img
                  className="rank-status"
                  src={"https://contents.playtowin.asia/files/admin/admin/1/level_0.png"}
                  alt={"Aspiring Speaker"}
                />
              </div> */}
            {this.props.userRankStatusImage && (
              <div id="rankStatus" className="photo-icon-wrap">
                <img
                  className="rank-status"
                  src={this.props.userRankStatusImage}
                  alt={this.props.userRankStatus}
                />
              </div>
            )}
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-10-24 pure-u-sm-12-24 relative">
            <div className="verticalcenter">
              <div className="text-truncated">
                <strong>{name}</strong>
              </div>
              {this.props.type === "following" && this.props.userFriended && (
                <div className="text-gray">
                  {" "}
                  {localize("user_follows_you_text", this.props.language)}{" "}
                </div>
              )}
            </div>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-10-24 pure-u-sm-6-24">
            {!(this.props.userId === this.props.id) &&
              this.renderFollowButton()}
          </div>
        </div>
      </Link>
    );
  }
}

Follows.propTypes = propTypes;

export default Follows;
