import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Topbar.css";
import Link from "components/shared/Link/Link";
import TopbarMenu from "components/Topbar/TopbarMenu/TopbarMenu";
import SearchChallengeDialogContainer from "./SearchChallengeDialog/SearchChallengeDialogContainer";
import TopicsListDialog from "components/shared/Dialogs/TopicsListDialog/TopicsListDialog";
import SelectCategoryDialogContainer from "./SelectCategoryDialog/SelectCategoryDialogContainer";
import LanguageSelectionDialogContainer from "./LanguageSelectionDialog/LanguageSelectionDialogContainer";
import TourModalContainer from "components/shared/Tour/TourModal/TourModalContainer";
import ShareGameDialog from "components/shared/Dialogs/ShareGameDialog/ShareGameDialog";

import {
  SHOW_TOPIC_CATEGORIES,
  SHOW_CHALLENGE_CATEGORIES,
  ENABLE_LANGUAGE_SELECTION,
  USE_LOGIN_HOME_TEMPLATE_2,
  ENABLE_LOGIN_HOME,
} from "config";
import localize from "lang/localize";
import reduceLanguages from "lang/reduceLanguages";
import { SINGLE_PROJECT_APP } from "../../config";

const propTypes = {
  showMenu: PropTypes.bool.isRequired,
  title: PropTypes.string,
  state: PropTypes.string,
  isMobileProjectPage: PropTypes.bool,
  isHome: PropTypes.bool.isRequired,
  isEmbeddedProject: PropTypes.bool,
  loggedIn: PropTypes.bool,
  user: PropTypes.object.isRequired,
  projectId: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
  backLink: PropTypes.string.isRequired,
  project: PropTypes.object,
  topicCategory: PropTypes.object,
  topicsList: PropTypes.array,
  challengeCategory: PropTypes.object,
  topic: PropTypes.object,
  challenge: PropTypes.object,
  board: PropTypes.object,
  inboxUnread: PropTypes.number.isRequired,
  handleShowMenu: PropTypes.func.isRequired,
  handleHideMenu: PropTypes.func.isRequired,
  showSearchChallengeDialog: PropTypes.bool,
  handleOpenSearchChallengeDialog: PropTypes.func,
  handleCloseSearchChallengeDialog: PropTypes.func,
  showShareGameDialog: PropTypes.bool,
  handleOpenShareGameDialog: PropTypes.func,
  handleCloseShareGameDialog: PropTypes.func,
  showSelectTopicCategoryDialog: PropTypes.bool,
  handleOpenSelectTopicCategoryDialog: PropTypes.func,
  handleCloseSelectTopicCategoryDialog: PropTypes.func,
  showTopicsListDialog: PropTypes.bool,
  handleOpenTopicsListDialog: PropTypes.func,
  handleCloseTopicsListDialog: PropTypes.func.isRequired,
  showSelectChallengeCategoryDialog: PropTypes.bool,
  handleOpenSelectChallengeCategoryDialog: PropTypes.func,
  handleCloseSelectChallengeCategoryDialog: PropTypes.func,
  showLanguageSelectionDialog: PropTypes.bool,
  handleOpenLanguageSelectionDialog: PropTypes.func,
  handleCloseLanguageSelectionDialog: PropTypes.func,
  showTourModal: PropTypes.bool,
  handleOpenTourModal: PropTypes.func,
  handleCloseTourModal: PropTypes.func,
  handleRefresh: PropTypes.func.isRequired,
  language: PropTypes.string,
  sessionKey: PropTypes.string,
};

class Topbar extends Component {
  /**
   * Render the topbar component
   */
  renderTopbar() {
    /* No back button */
    if (
      (ENABLE_LOGIN_HOME && this.props.state === "login-home") ||
      (!ENABLE_LOGIN_HOME && this.props.state === "login")
    ) {
      return (
        <div className="topbar topbarbg pure-g fixed fullwidth">
          <div className="pure-u-sm-7-24" />
          <div className="pure-u-sm-1-24" />
          <div className="pure-u-13-24 pure-u-sm-8-24">
            <div
              className="title-wrap text-truncated-parent not-logged-in"
              dangerouslySetInnerHTML={{ __html: this.renderTopbarTitle() }}
            />
          </div>
          <div className="pure-u-1-24 hide-from-sm" />
          <div className="pure-u-10-24 pure-u-sm-8-24">
            {this.renderRightTopbar()}
          </div>
        </div>
      );
    } else {
      /* With back button */
      return (
        <div className="topbar topbarbg pure-g fixed fullwidth">
          <div className="pure-u-5-24 pure-u-sm-4-24 pure-u-md-6-24 relative">
            {this.renderBack()}
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-12-24 pure-u-sm-14-24 pure-u-md-10-24">
            <div
              className="title-wrap text-truncated-parent"
              dangerouslySetInnerHTML={{ __html: this.renderTopbarTitle() }}
            />
          </div>
          <div className="pure-u-6-24 pure-u-sm-5-24 pure-u-md-7-24">
            {this.renderRightTopbar()}
          </div>
        </div>
      );
    }
  }

  /**
   * Render the topbar title based on current app location.
   */
  renderTopbarTitle() {
    switch (this.props.state) {
      case "home-single":
      case "projecthome":
        return this.props.project ? this.props.project.title : "";
      case "topic":
        return this.props.topic ? this.props.topic.title : "";
      case "challenge":
        if (!!this.props.challenge && !!this.props.challenge.title) {
          return this.props.challenge.title;
        } else if (
          !!this.props.challenge &&
          this.props.challenge.error.indexOf("locked") !== -1
        ) {
          return (
            localize("button_quest_locked", this.props.language)
              .charAt(0)
              .toUpperCase() +
            localize("button_quest_locked", this.props.language).substring(1)
          );
        } else {
          return "";
        }
      case "challenge-comments":
        if (!!this.props.challenge && !!this.props.challenge.title) {
          return (
            localize("comments_novariable_text", this.props.language) +
            ": " +
            this.props.challenge.title
          );
        } else if (
          !!this.props.challenge &&
          this.props.challenge.error.indexOf("locked") !== -1
        ) {
          return (
            localize("button_quest_locked", this.props.language)
              .charAt(0)
              .toUpperCase() +
            localize("button_quest_locked", this.props.language).substring(1)
          );
        } else {
          return "";
        }
      case "topic-category":
        return this.props.topicCategory ? this.props.topicCategory.title : "";
      case "challenge-category":
        return this.props.challengeCategory
          ? this.props.challengeCategory.title
          : "";
      case "discussions-posts":
      case "discussions-comments":
        // Show board title if filtered by board
        if (this.props.board && this.props.board.boardTitle) {
          return this.props.board.boardTitle;
        }

        return this.props.project ? this.props.project.title : "";
      default:
        return this.props.title ? this.props.title : "";
    }
  }

  /**
   * Determine text to display for back link.
   */
  renderBackText() {
    switch (this.props.state) {
      case "login":
        if (ENABLE_LOGIN_HOME) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "login-secondary":
        if (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_2) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "reset-password":
      case "post-login":
        return localize("nav_bar_title_login_text", this.props.language);
      case "projects-list":
      case "search-projects":
        return localize("nav_bar_title_games_home_text", this.props.language);
      case "projecthome":
        if (this.props.isEmbeddedProject) {
          return null;
        } else {
          return localize("nav_bar_title_games_home_text", this.props.language);
        }
      case "topic":
      case "topic-category":
        let text =
          this.props.project && this.props.project.title
            ? this.props.project.title
            : localize("back_button_back_text", this.props.language);
        if (SINGLE_PROJECT_APP) {
          text = localize("icon_home", this.props.language);
        }
        return text;
      case "challenge":
      case "challenge-category":
        return this.props.topic && this.props.topic.title
          ? this.props.topic.title
          : localize("back_button_back_text", this.props.language);
      case "challenge-comments":
        return this.props.challenge && this.props.challenge.title
          ? this.props.challenge.title
          : localize("back_button_back_text", this.props.language);
      case "home-single":
      case "home-multi":
      case "login-tour":
        return null;
      case "discussions-posts":
        /*
          Source 1: Filtered board > Main Page of Discussions (show project title)
          Source 2: Main Page > Previous Page (show back)
        */
        if (this.props.board && this.props.board.boardTitle) {
          if (this.props.project && this.props.project.title) {
            return this.props.project.title;
          }
        }

        return localize("back_button_back_text", this.props.language);
      case "discussions-comments":
        /*
          Source: Replies > Filtered board

          Unable to differentiate source of link,
          so we can't switch between PROJECT_BOARD_POSTS and PROJECT_DISCUSSIONS.
          Use main page (PROJECT_DISCUSSIONS) for all routing then
        */
        // if (this.props.board && this.props.board.boardTitle) {
        //   return this.props.board.boardTitle;
        // }
        if (this.props.board && this.props.board.boardTitle) {
          if (this.props.project && this.props.project.title) {
            return this.props.project.title;
          }
        }

        // Unexpected error?
        return localize("back_button_back_text", this.props.language);
      default:
        return localize("back_button_back_text", this.props.language);
    }
  }

  /**
   * Render back link
   */
  renderBack() {
    /* Special case: at topic page - you are a project administrator, but not a project player */
    if (
      (this.props.state === "topic" || this.props.state === "topic-category") &&
      !this.props.projectId
    ) {
      return null;
    } else if (
      this.props.state === "home-single" ||
      this.props.state === "home-multi" ||
      this.props.state === "login-tour" ||
      this.props.state === "home"
    ) {
      /* There's no reason for a back button in the Player home page */
      return null;
    } else if (
      this.props.state === "projecthome" &&
      this.props.isEmbeddedProject
    ) {
      /* For embedded projects, no way to go back home */
      return null;
    } else if (this.props.backLink !== "") {
      /*
        For cases that involve routing
      */
      return (
        <div className="back-button-wrap">
          {/* Use Back with no copywriting for mobile sizes */}
          <Link to={this.props.backLink} className="hide-from-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {localize("back_button_back_text", this.props.language)}
            </span>
          </Link>
          {/* Use renderBackText() for desktop sizes */}
          <Link to={this.props.backLink} className="hide-below-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {this.renderBackText()}
            </span>
          </Link>
        </div>
      );
    } else {
      /*
        For cases that involve simulated browser back button
      */
      if (window.history.length <= 1) {
        return null; // Remove back button if no history
      }

      return (
        <div className="back-button-wrap">
          <span
            className="back-button-text text-truncated back-button-history-text"
            onClick={this.props.handleBack}
          >
            <i className="fas fa-angle-left" aria-hidden="true" />
            {this.renderBackText()}
          </span>
        </div>
      );
    }
  }

  /**
   * Render language.
   */
  renderLangText() {
    let language = this.props.language;
    let langText;

    langText = reduceLanguages(language);

    return langText.toUpperCase();
  }

  /**
   * Render topbar menu icon.
   */
  renderRightTopbar() {
    /* No menu icon on login and register pages */
    return (
      <div className="icons-wrap floatright">
        {ENABLE_LANGUAGE_SELECTION && (
          <div
            className="language-icon cursor-pointer"
            id="languageIcon"
            onClick={this.props.handleOpenLanguageSelectionDialog}
          >
            <i className="fas fa-globe" />
            <div className="language-badge floatright">
              {this.renderLangText()}
            </div>
          </div>
        )}
        {this.props.state !== "login" &&
          this.props.state !== "login-secondary" &&
          this.props.state !== "login-home" &&
          this.props.state !== "reset-password" &&
          this.props.state !== "post-login" &&
          this.renderTopbarMenu()}
      </div>
    );
  }

  /**
   * Render topbar menu.
   */
  renderTopbarMenu() {
    return (
      <React.Fragment>
        <div
          className="menu-icon cursor-pointer"
          id="menuIcon"
          onClick={this.props.handleShowMenu}
        />
        <TopbarMenu
          isMobileProjectPage={this.props.isMobileProjectPage}
          showMenu={this.props.showMenu}
          handleShowMenu={this.props.handleShowMenu}
          handleHideMenu={this.props.handleHideMenu}
          state={this.props.state}
          loggedIn={this.props.loggedIn}
          projectId={this.props.projectId}
          project={this.props.project}
          topic={this.props.topic}
          topicsList={this.props.topicsList}
          challenge={this.props.challenge}
          inboxUnread={this.props.inboxUnread || 0}
          handleOpenSearchChallengeDialog={
            this.props.handleOpenSearchChallengeDialog
          }
          handleOpenSelectTopicCategoryDialog={
            this.props.handleOpenSelectTopicCategoryDialog
          }
          handleOpenTopicsListDialog={this.props.handleOpenTopicsListDialog}
          handleOpenSelectChallengeCategoryDialog={
            this.props.handleOpenSelectChallengeCategoryDialog
          }
          handleOpenTourModal={this.props.handleOpenTourModal}
          handleOpenShareGameDialog={this.props.handleOpenShareGameDialog}
          handleRefresh={this.props.handleRefresh}
          language={this.props.language}
        />
      </React.Fragment>
    );
  }

  /**
   * Render dialog for search.
   */
  renderSearchChallengeDialog() {
    return (
      <SearchChallengeDialogContainer
        showModal={this.props.showSearchChallengeDialog}
        handleCloseSearchChallengeDialog={
          this.props.handleCloseSearchChallengeDialog
        }
      />
    );
  }

  /**
   * Render dialog for share.
   */
  renderShareGameDialog() {
    return (
      <ShareGameDialog
        showDialog={this.props.showShareGameDialog}
        handleCloseDialog={this.props.handleCloseShareGameDialog}
        projectId={this.props.projectId}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }

  /**
   * Render dialog for topic category selection.
   */
  renderSelectTopicCategoryDialog() {
    return (
      <SelectCategoryDialogContainer
        showModal={this.props.showSelectTopicCategoryDialog}
        handleCloseSelectCategoryDialog={
          this.props.handleCloseSelectTopicCategoryDialog
        }
        type="topic"
      />
    );
  }

  /**
   * Render dialog for topic list selection.
   */
  renderTopicsListDialog() {
    return (
      <TopicsListDialog
        showDialog={this.props.showTopicsListDialog}
        handleCloseDialog={this.props.handleCloseTopicsListDialog}
        projectTitle={this.props.project ? this.props.project.title : ""}
        topics={this.props.topicsList}
        language={this.props.language}
      />
    );
  }

  /**
   * Render dialog for challenge category selection.
   */
  renderSelectChallengeCategoryDialog() {
    return (
      <SelectCategoryDialogContainer
        showModal={this.props.showSelectChallengeCategoryDialog}
        handleCloseSelectCategoryDialog={
          this.props.handleCloseSelectChallengeCategoryDialog
        }
        type="challenge"
      />
    );
  }

  /**
   * Render dialog for language selection.
   */
  renderLanguageSelectionDialog() {
    return (
      <LanguageSelectionDialogContainer
        showModal={this.props.showLanguageSelectionDialog}
        handleCloseLanguageSelectionDialog={
          this.props.handleCloseLanguageSelectionDialog
        }
      />
    );
  }

  /**
   * Render tour screens modal popup.
   */
  renderTourModal() {
    return (
      <TourModalContainer
        projectId={this.props.projectId}
        showModal={this.props.showTourModal}
        handleCloseModal={this.props.handleCloseTourModal}
        language={this.props.language}
      />
    );
  }

  /**
   * Renders the component.
   */
  render() {
    return (
      <nav
        className={
          "topbar-wrap" +
          (this.props.loggedIn ? " logged-in" : "") +
          (this.props.isMobileProjectPage ? " mobile-project-topbar-wrap" : "")
        }
      >
        {this.renderTopbar()}
        {this.props.showSearchChallengeDialog &&
          this.renderSearchChallengeDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        {SHOW_TOPIC_CATEGORIES &&
          this.props.showSelectTopicCategoryDialog &&
          this.renderSelectTopicCategoryDialog()}
        {this.props.showTopicsListDialog && this.renderTopicsListDialog()}
        {SHOW_CHALLENGE_CATEGORIES &&
          this.props.showSelectChallengeCategoryDialog &&
          this.renderSelectChallengeCategoryDialog()}
        {this.props.showLanguageSelectionDialog &&
          this.renderLanguageSelectionDialog()}
        {this.props.showTourModal && this.renderTourModal()}
      </nav>
    );
  }
}

Topbar.propTypes = propTypes;

export default Topbar;
