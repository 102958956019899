import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import TextboxInput from "components/shared/Inputs/TextboxInput";
import CountdownButton from "components/shared/Countdown/CountdownButton";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";

/* For video challenge */
import VideoInput from "components/shared/Inputs/VideoInput";

import localize from "lang/localize";

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  videoDurationLimitFormatted: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  challengeMinViewDuration: PropTypes.number,
  isTimerActive: PropTypes.bool,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string,
  charLimit: PropTypes.number,
};

class VideoChallenge extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      videoname: null,
      video: null,
    };
  }

  onTextChange(event) {
    this.setState({ text: event.target.value });
  }

  onVideoChange(video) {
    if (video[0]) {
      this.setState({
        video: video[0],
        videoname: video[0].name,
      });
    }
  }

  onVideoReset() {
    this.setState({
      video: null,
      videoname: null,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.handleSubmit(
      { comment: this.state.text },
      this.state.video,
      this.state.videoname,
    );
    return true;
  }

  render() {
    return (
      <Fragment>
        <div className="pure-form">
          {/*
              Set the above back to <form className="pure-form">
              if we are no longer using <input type="file">
              for image inputs
          */}
          <div className="pure-u-1-1 pure-u-md-16-24 align-fields-to-image-input relative">
            <TextboxInput
              placeholder={localize(
                "comment_placeholder_text",
                this.props.language,
              )}
              onChange={this.onTextChange.bind(this)}
              readOnly={!this.props.isLoggedIn}
              className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
            />
            <CharacterCounter
              input={this.state.text}
              charLimit={this.props.charLimit}
            />
          </div>
          <div className="pure-u-1-1 pure-u-md-8-24">
            <VideoInput
              videoname={this.state.videoname}
              videoDurationLimit={this.props.videoDurationLimit}
              videoDurationLimitFormatted={
                this.props.videoDurationLimitFormatted
              }
              onDrop={this.onVideoChange.bind(this)}
              resetFiles={this.onVideoReset.bind(this)}
              showAlertWithTimeout={this.props.showAlertWithTimeout}
              disabled={!this.props.isLoggedIn}
              language={this.props.language}
            />
          </div>
        </div>
        <div>
          {this.props.isLoggedIn ? (
            <button
              className={
                "button automargin " +
                (this.props.submitted ||
                this.state.video == null ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive) ||
                (this.props.charLimit !== 0 &&
                  this.state.text.length > this.props.charLimit)
                  ? "inactive"
                  : "cta")
              }
              onClick={this.handleSubmit.bind(this)}
              disabled={
                this.props.submitted ||
                this.state.video == null ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive)
              }
              id="submitCompletionButton"
            >
              {this.props.challengeMinViewDuration &&
              this.props.countdownTime &&
              this.props.isTimerActive ? (
                <CountdownButton
                  countdownTime={this.props.countdownTime}
                  setTimerActive={this.props.setTimerActive}
                  language={this.props.language}
                />
              ) : this.props.submitted ? (
                localize("button_claim_submitting", this.props.language)
              ) : this.state.video === null ? (
                localize(
                  "button_claim_upload_video_to_submit",
                  this.props.language,
                )
              ) : (
                localize("button_claim_get_points", this.props.language)
              )}
            </button>
          ) : (
            <button
              className={
                "button automargin " +
                (this.props.submitted ||
                this.state.video == null ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive) ||
                (this.props.charLimit !== 0 &&
                  this.state.text.length > this.props.charLimit)
                  ? "inactive"
                  : "cta")
              }
              disabled={
                this.props.submitted ||
                this.state.video == null ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive)
              }
              id="loginBeforeSubmitCompletionButton"
            >
              {this.props.challengeMinViewDuration &&
              this.props.countdownTime &&
              this.props.isTimerActive ? (
                <CountdownButton
                  countdownTime={this.props.countdownTime}
                  setTimerActive={this.props.setTimerActive}
                  language={this.props.language}
                />
              ) : this.props.submitted ? (
                localize("button_claim_submitting", this.props.language)
              ) : this.state.video === null ? (
                localize(
                  "button_claim_upload_video_to_submit",
                  this.props.language,
                )
              ) : (
                localize("button_claim_get_points", this.props.language)
              )}
            </button>
          )}
          {/*
            Set the below back to </form> if we are
            no longer using <input type="file"> for
            image inputs
          */}
        </div>
      </Fragment>
    );
  }
}

VideoChallenge.propTypes = propTypes;

export default VideoChallenge;
