import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import FollowsPage from "./FollowsPage";
import Loading from "components/shared/Loading";
import { USER, PROJECT_USER } from "App/Routes";
import Link from "components/shared/Link/Link";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import {
  GET_USER,
  GET_FOLLOWING,
  GET_FOLLOWERS,
  GET_TOPICS,
  FOLLOW_USER,
} from "services/api";
import { setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";
import getApiGenerator2 from "services/getApiGenerator2";
import urlServices from "services/urlServices";
import localize from "lang/localize";

import { LOAD_MORE_COUNT } from "config";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    user: state.user,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

const propTypes = {
  id: PropTypes.string,
};

const defaultProps = {
  id: null,
};

export class FollowsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(props.id) || props.user.id,
      user: null,
      players: null,
      more: false,
      page: 1,
      searchInput: "",
      beganSearch: false,
      topics: null,
      error: null,
      code: null,
      isLoadingMore: false,
      currentType:
        urlServices.getUrlParamValueByKey("type") === "following"
          ? "following"
          : "followers",
    };
    this.handleMore = this.handleMore.bind(this);
    this.getPlayers = this.getPlayers.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
    this.handleToggleType = this.handleToggleType.bind(this);
  }

  componentDidMount() {
    if (this.props.projectId) {
      /* Retrieve Project information */
      this.getTopics();
      this.getUserProfile();
      this.getPlayers(this.state.page);
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  /**
   * Handle followers/following search
   */
  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value }, () =>
      this.getPlayers(1, this.state.searchInput),
    );
  }

  /**
   * Handle submit button for followers/following search
   */
  handleSubmit(event) {
    event.preventDefault();
  }

  /**
   * Handle loading more followers/following
   */
  handleMore = debounce(() => {
    const {
      getPlayers,
      state: { more, isLoadingMore, page, search },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getPlayers(page, search);
      }
    }
  }, 100);

  /**
   * Calls the topics API to retrieve project information for left sidebar
   */
  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  /**
   * Calls the user profile API to retrieve user information
   */
  getUserProfile() {
    getApiGenerator(
      GET_USER.format(this.state.id),
      {
        bundle_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            user: false,
          });
        }
      } else {
        this.setState({
          user: res.body,
        });
      }
    });
  }

  /**
   * Get profile link
   */
  getProfileLink() {
    if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.state.id);
    } else {
      return USER.format(this.state.id);
    }
  }

  /**
   * Retrieve list of following/followers
   */
  getPlayers(page, search = null) {
    let apiRoute;
    switch (this.state.currentType) {
      case "followers":
        apiRoute = GET_FOLLOWERS;
        break;
      default:
        apiRoute = GET_FOLLOWING;
    }
    getApiGenerator2(
      apiRoute.format(this.state.id),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
        keywords: search,
        project_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            players: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        if (page === 1) {
          this.setState({
            players: res.body.data,
            more: res.body.more,
            page: page + 1,
            isLoadingMore: false,
          });
        } else {
          this.setState({
            players: this.state.players
              ? this.state.players.slice().concat(res.body.data)
              : res.body.data,
            more: res.body.more,
            page: this.state.page + 1,
            isLoadingMore: false,
          });
        }
      }
    });
  }

  /**
   * Follow/unfollow a a user
   */
  handleFollow(id) {
    if (id && this.props.sessionKey) {
      getApiGenerator(
        FOLLOW_USER,
        {
          type_id: id,
          project_id: this.props.projectId,
        },
        this.props.sessionKey,
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        }
      });
    }
  }

  /**
   * Handles tab navigation toggling
   */
  handleToggleType = (event) => {
    this.setState(
      {
        currentType: event.currentTarget.id,
        players: null,
      },
      () => {
        this.getPlayers(1);
      },
    );
  };

  /**
   * Render tab navigation view
   */
  renderBoardNav() {
    return (
      <div className="container verticalpadding">
        <nav className="nav-buttons">
          <button
            id="followers"
            className={
              "button " +
              (this.state.currentType === "followers" ? "active" : "inactive")
            }
            onClick={this.handleToggleType}
          >
            <span>
              <i className="fas fa-user-alt"></i>
              {localize("button_user_followers", this.props.language)}
            </span>
          </button>
          <button
            id="following"
            className={
              "button " +
              (this.state.currentType === "following" ? "active" : "inactive")
            }
            onClick={this.handleToggleType}
          >
            <span>
              <i className="fas fa-user-friends"></i>
              {localize("button_user_following", this.props.language)}
            </span>
          </button>
        </nav>
      </div>
    );
  }

  /**
   * Render the profile header
   */
  renderProfileHeader() {
    if (this.state.user) {
      return (
        <Link to={this.getProfileLink()}>
          <div className="container verticalpadding darkbg">
            <div className="innerblock">
              <div className="pure-g profile-container flex align-items-center">
                <div className="pure-u-2-24">
                  <span className="hide-below-md fas fa-chevron-left font-size-lg"></span>
                  <span className="hide-from-md fas fa-chevron-left"></span>
                </div>
                <div className="pure-u-md-1-24 pure-u-2-24">
                  <span className="square-image-wrapper">
                    <span className="square-image circle border-gray-light">
                      <img
                        src={this.state.user.photoLarge}
                        alt={this.state.user.name}
                      />
                    </span>
                  </span>
                  {this.state.user.userRankStatusImage && (
                    <div id="rankStatus" className="photo-icon-wrap">
                      <img
                        className="rank-status"
                        src={this.state.user.userRankStatusImage}
                        alt={this.state.user.userRankStatus}
                      />
                    </div>
                  )}
                </div>
                <div className="leftmargin horizontalpadding relative">
                  <h5 className="automargin">{this.state.user.name}</h5>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }
  }

  /**
   * Render list of followers/following
   */
  renderFollows() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.players) {
      return (
        <FollowsPage
          isLoadingMore={this.state.isLoadingMore}
          projectId={this.props.projectId}
          userId={this.props.user.id}
          players={this.state.players}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          handleSubmit={this.handleSubmit.bind(this)}
          handleSearchChange={this.handleSearchChange.bind(this)}
          handleFollow={this.handleFollow}
          type={this.state.currentType}
          handleToggleType={this.handleToggleType}
          searchedPlayersExist={
            !(this.state.players.length === 0 && this.state.beganSearch)
          }
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderProfileHeader()}
        {this.renderBoardNav()}
        {this.renderFollows()}
      </React.Fragment>
    );
  }
}

FollowsContainer.propTypes = propTypes;
FollowsContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(FollowsContainer);
