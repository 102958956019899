import React, { Component } from "react";
import PropTypes from "prop-types";

import TourPage from "components/shared/Tour/TourPage";

import Modal from "react-modal";

const propTypes = {
  projectId: PropTypes.number,
  showModal: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string,
  completedTour: PropTypes.bool,
  tourScreens: PropTypes.array,
  setCompletedTour: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  apiCallComplete: PropTypes.bool,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class TourModal extends Component {
  /**
   * Render the tour component
   */
  renderTourPage() {
    return (
      <TourPage
        projectId={this.props.projectId}
        redirectUrl={this.props.redirectUrl}
        completedTour={this.props.completedTour}
        tourScreens={this.props.tourScreens}
        setCompletedTour={this.props.setCompletedTour}
        handleCloseClick={this.props.handleCloseClick}
        apiCallComplete={this.props.apiCallComplete}
        language={this.props.language}
      />
    );
  }

  /**
   * Render the tour popup
   */
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel=""
        onRequestClose={this.props.handleCloseModal}
        shouldCloseOnOverlayClick={false}
        className="tourModal tour-modal modal-content-custom modal-content-fullscreen"
        overlayClassName="modal-overlay"
      >
        <div className="modal-body">{this.renderTourPage()}</div>
      </Modal>
    );
  }
}

TourModal.propTypes = propTypes;

export default TourModal;
