import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import Loading from "components/shared/Loading";
import DiscussionsPage from "./DiscussionsPage";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import {
  showAlertWithTimeout,
  setButtons,
  setProject,
  setBoard,
} from "actions";
import { LOAD_MORE_COUNT, ENABLE_LOGIN_HOME, SINGLE_PROJECT_APP } from "config";
import {
  GET_BOARDS,
  GET_PROJECT_POSTS,
  GET_BOARDS_POSTS,
  BOOKMARK_POST,
  LIKE_POST,
  GET_PROJECT_BUTTONS,
} from "services/api";
import {
  HOME,
  PROJECT_LOGIN_HOME,
  PROJECT_LOGIN,
  LOGIN_HOME,
  LOGIN,
} from "App/Routes";
import localize from "lang/localize";
import listenerServices from "services/listenerServices";
import getApiGenerator from "services/getApiGenerator";
import getApiGenerator2 from "services/getApiGenerator2";

import sessionStorageService from "services/sessionStorageService";

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  id2: PropTypes.string,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setBoard: (board) => {
      dispatch(setBoard(board));
    },
  };
};

export class DiscussionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      id2: props.id2,
      selectedBoard: !!props.id2 ? props.id2 : 0, // filtered/initial board
      sort: null, // submitted sort option
      search: null, // submitted search
      more: false,
      page: 1,
      input: "",
      // character limit
      charLimit: 0,
      // discussions
      boards: null,
      board: null,
      posts: null,
      isBookmarked: false,
      isLiked: false,
      likeNo: 0,
      showBoardFilterDialog: false,
      activeBoard: !!props.id2 ? props.id2 : 0, // selected board in form, may not be submitted
      activeSort: null, // selected sort option, may not be submitted
      activeSearch: null, // input text typed, may not be submitted
      defaultBoard: null,
      project: null,
      code: null,
      showLoginDialog: false,
    };

    this.removePost = this.removePost.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getBoards = this.getBoards.bind(this);
    this.getProjectPosts = this.getProjectPosts.bind(this);
    this.handleBookmark = this.handleBookmark.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.handleBoardFilters = this.handleBoardFilters.bind(this);
    this.handleSortToggle = this.handleSortToggle.bind(this);
    this.handleBoardChange = this.handleBoardChange.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.handleFilterReset = this.handleFilterReset.bind(this);
    this.handleOpenBoardFilterDialog =
      this.handleOpenBoardFilterDialog.bind(this);
    this.handleCloseBoardFilterDialog =
      this.handleCloseBoardFilterDialog.bind(this);
    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this);
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this);
  }

  componentDidMount() {
    this.getBoards();
    this.getProjectPosts(1);
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedBoard !== prevState.selectedBoard ||
      this.state.sort !== prevState.sort ||
      this.state.search !== prevState.search
    ) {
      this.getProjectPosts(1);
    }
  }

  /**
   * Retrieve link to login page
   */
  getLoginRoute() {
    if (this.props.id && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.id);
    } else if (this.props.id) {
      return PROJECT_LOGIN.format(this.props.id);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  getBoards() {
    getApiGenerator2(
      GET_BOARDS.format(this.state.id),
      {
        page: 1,
        limit: 50, // Use a high limit to display all
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            boards: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }

        this.setState({
          code: res.body.code,
        });
      } else {
        this.setState({
          boards: this.state.boards
            ? this.state.boards.slice().concat(res.body.data)
            : res.body.data,
          more: res.body.more,
          // No pagination for board list
          page: 1,
          isLoadingMore: false,
          charLimit: res.body.charLimit,
          code: res.body.code,
        });
      }
    });
  }

  getProjectPosts(page) {
    if (this.state.selectedBoard) {
      this.getBoardPosts(page);
      return;
    }

    this.props.setBoard(null);

    getApiGenerator2(
      GET_PROJECT_POSTS.format(this.state.id),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
        keywords: !!this.state.search ? this.state.search : null,
        order: !!this.state.sort ? this.state.sort : null,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            posts: [],
            board: null,
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        this.setState({
          posts:
            this.state.posts && page > 1
              ? this.state.posts.slice().concat(res.body.data)
              : res.body.data,
          board: null,
          more: res.body.more,
          page: page + 1,
          isLoadingMore: false,
          charLimit: res.body.charLimit,
        });
      }
      this.getProjectButtons(this.state.id);
    });
  }

  getBoardPosts(page) {
    getApiGenerator2(
      GET_BOARDS_POSTS.format(this.state.selectedBoard),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
        keywords: !!this.state.search ? this.state.search : null,
        order: !!this.state.sort ? this.state.sort : null,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            posts: [],
            more: false,
            board: res.body.board,
            page: 1,
            isLoadingMore: false,
          });
        }

        this.props.setBoard(res.body.board);
      } else {
        this.setState({
          posts:
            this.state.posts && page > 1
              ? this.state.posts.slice().concat(res.body.data)
              : res.body.data,
          more: res.body.more,
          board: res.body.board,
          page: page + 1,
          isLoadingMore: false,
          charLimit: res.body.charLimit,
        });
      }

      this.getProjectButtons(this.state.id);

      if (!!this.props.id2) {
        this.setState({
          defaultBoard: res.body.board,
        });
        this.props.setBoard(res.body.board);
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
        this.props.setProject(res.body.game);

        this.setState({ project: res.body.game });
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getProjectPosts,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getProjectPosts(page);
      }
    }
  }, 100);

  handleBookmark = (event, postId) => {
    event.preventDefault();
    this.bookmarkPost(postId);
  };

  handleOpenLoginDialog() {
    this.setState({
      showLoginDialog: true,
    });
  }

  handleCloseLoginDialog() {
    this.setState({
      showLoginDialog: false,
    });
  }

  handleFilterReset = () => {
    this.setState({
      activeSort: null,
      activeSearch: null,
      activeBoard:
        !!this.props.id2 && this.state.board ? this.state.board.id : 0,
      selectedBoard: this.state.board ? this.state.board.id : 0,
      selectedOption: !!this.props.id2
        ? {
            value: this.state.board.id,
            label: this.state.board.boardTitle,
          }
        : null,
    });
  };

  // Store selected sort mode
  handleSortToggle = (sort) => {
    this.setState({ activeSort: sort });
  };

  // Store selected board
  handleBoardChange = (name, option) => {
    // react-select returns the object rather than the event
    this.setState({
      activeBoard: option ? option.value : 0,
      selectedOption: option,
    });
  };

  // Store search text
  handleSearchInput = (event) => {
    event.preventDefault();
    this.setState({ activeSearch: event.target.value });
  };

  // Submit filters
  handleBoardFilters = (event) => {
    event.preventDefault();

    this.setState({
      // transfer to actual values used for filter/sort
      selectedBoard: this.state.activeBoard,
      search: this.state.activeSearch,
      sort: this.state.activeSort,
    });

    this.handleCloseBoardFilterDialog();
  };

  bookmarkPost = (post_id) => {
    getApiGenerator2(
      BOOKMARK_POST.format(post_id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        const posts = this.state.posts.slice();
        const post = posts.filter((post) => post.id === post_id)[0];
        post.postBookmarked = res.body.postBookmarked;
        post.postBookmarkCount = res.body.postBookmarkCount;
        this.setState({
          posts: posts,
        });
      }
    });
  };

  handleLike = (event, postId) => {
    event.preventDefault();
    this.likePost(postId);
  };

  likePost = (post_id) => {
    getApiGenerator2(LIKE_POST.format(post_id), {}, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        } else {
          const posts = this.state.posts.slice();
          const post = posts.filter((post) => post.id === post_id)[0];
          post.postVoted = res.body.postVoted;
          post.postVoteCount = res.body.postVoteCount;
          this.setState({
            posts: posts,
          });
        }
      },
    );
  };

  removePost(postId) {
    this.setState({
      posts: this.state.posts.filter((element) => element.id !== postId),
    });
  }

  handleOpenBoardFilterDialog(e) {
    e.preventDefault();

    this.setState({
      showBoardFilterDialog: true,
    });
  }

  handleCloseBoardFilterDialog() {
    this.setState({
      showBoardFilterDialog: false,
    });
  }

  render() {
    const UNAVAILABLE_GAME_ERROR_LIST = [
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_invalid", this.props.language),
      localize("unavailable_game_private", this.props.language),
    ];

    if (this.state.boards && this.state.project && this.state.posts) {
      return (
        <DiscussionsPage
          sessionKey={this.props.sessionKey}
          isLoadingMore={this.state.isLoadingMore}
          more={this.state.more}
          handleMore={this.handleMore}
          id={this.props.id}
          type={this.props.type}
          language={this.props.language}
          projectId={this.props.projectId}
          userId={this.props.userId}
          removePost={this.removePost}
          charLimit={
            this.state.charLimit !== undefined ? this.state.charLimit : 0
          }
          boards={this.state.boards}
          posts={this.state.posts}
          board={!!this.state.board ? this.state.board : null}
          defaultBoard={
            !!this.state.defaultBoard ? this.state.defaultBoard : null
          }
          selectedOption={this.state.selectedOption}
          search={!!this.state.search ? this.state.search : null}
          sort={!!this.state.sort ? this.state.sort : null}
          handleLike={this.handleLike}
          handleBookmark={this.handleBookmark}
          // board filter
          showBoardFilterDialog={this.state.showBoardFilterDialog}
          handleOpenBoardFilterDialog={this.handleOpenBoardFilterDialog}
          handleCloseBoardFilterDialog={this.handleCloseBoardFilterDialog}
          handleSortToggle={this.handleSortToggle}
          handleBoardChange={this.handleBoardChange}
          handleSearchInput={this.handleSearchInput}
          handleBoardFilters={this.handleBoardFilters}
          handleFilterReset={this.handleFilterReset}
          showLoginDialog={this.state.showLoginDialog}
          handleOpenLoginDialog={this.handleOpenLoginDialog}
          handleCloseLoginDialog={this.handleCloseLoginDialog}
        />
      );
    } else if (
      (!this.state.boards || this.state.boards.length < 1) &&
      this.state.code === 500
    ) {
      /* No information is returned */

      const IS_EMBEDDED_PROJECT =
        sessionStorageService.getItem("embedded_project") === "true";

      // TODO: Differentiate a private project

      if (this.props.sessionKey) {
        /* Other project error scenarios (eg. unpublished, private, deleted... etc) */
        return (
          <GenericErrorPage
            routeUrl={SINGLE_PROJECT_APP || IS_EMBEDDED_PROJECT ? null : HOME}
            routeName={localize("icon_home", this.props.language)}
            message={localize("unavailable_game_long", this.props.language)}
            messageList={UNAVAILABLE_GAME_ERROR_LIST}
            language={this.props.language}
          />
        );
      } else {
        return (
          <GenericErrorPage
            routeUrl={this.getLoginRoute()}
            routeName={localize("button_login", this.props.language)}
            message={localize("unavailable_game_long", this.props.language)}
            messageList={UNAVAILABLE_GAME_ERROR_LIST}
            isBackRoute={false}
            language={this.props.language}
          />
        );
      }
    } else {
      // TODO: handle private project
      /* Everything else */
      return <Loading />;
    }
  }
}

DiscussionsContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscussionsContainer);
