import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import GuestLoginDialog from "./GuestLoginDialog";

import { setSessionKey, setUserId, showAlertWithTimeout } from "actions";
import localStorageService from "services/localStorageService";
import sessionStorageService from "services/sessionStorageService";
import pushApiGenerator from "services/pushApiGenerator";
import { POST_LOGIN_GUEST } from "services/api";
import loginServices from "services/loginServices";

const propTypes = {
  showGuestLoginDialog: PropTypes.bool.isRequired,
  handleCloseGuestLoginDialog: PropTypes.func.isRequired
};

export const mapStateToProps = (state, ownProps) => {
  return {
    projectId: state.projectId,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: user => {
      dispatch(setUserId(user));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

class GuestLoginDialogContainer extends Component {
  constructor() {
    super();
    this.state = {
      username: ""
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsernameChange = event => {
    this.setState({ username: event.currentTarget.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const data = {
      user_name: this.state.username
    };
    pushApiGenerator(POST_LOGIN_GUEST, data).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        localStorageService.saveState({
          user_id: res.body.user.id,
          sessionKey: res.body.sessionKey
        });
        if (
          res.body.sessionKey !== undefined &&
          res.body.user.id !== undefined
        ) {
          this.props.setUserId(res.body.user.id);
          this.props.setSessionKey(res.body.sessionKey);
        }

        // loads loginRedirectUrl from sessionStorage (if available)
        // eventually loginRedirectUrl will be dumped when new page is loaded
        // (not REGISTER nor LOGIN nor RESET_PASSWORD)
        // for the aforementioned dumping, see SingleProjectApp.js
        let loginRedirectUrl = sessionStorageService.getItem(
          "loginRedirectUrl"
        );

        /*
          We assume all guest login users are first-time users, thus firstLogin flag is unnecessary,
          thus the last parameter is true to allow no bypassing of tours.
        */
        loginServices.postLoginReplaceAndNavigate(
          this.props.projectId,
          loginRedirectUrl,
          true
        );
      }
    });
  };

  render() {
    return (
      <GuestLoginDialog
        projectId={this.props.projectId}
        username={this.state.username}
        showGuestLoginDialog={this.props.showGuestLoginDialog}
        handleCloseGuestLoginDialog={this.props.handleCloseGuestLoginDialog}
        handleUsernameChange={this.handleUsernameChange}
        handleSubmit={this.handleSubmit}
        language={this.props.language}
      />
    );
  }
}

GuestLoginDialogContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestLoginDialogContainer);
