import React, { Component } from "react";
import PropTypes from "prop-types";

import TextInput from "components/shared/Inputs/TextInput";
import CountdownButton from "components/shared/Countdown/CountdownButton";

import localize from "lang/localize";

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  challengeMinViewDuration: PropTypes.number,
  isTimerActive: PropTypes.bool,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string,
};

class FixedChallenge extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      imagename: null,
      image: null,
    };
  }

  onTextChange(event) {
    this.setState({ text: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.text.length === 0) {
      this.props.showAlertWithTimeout({
        text: localize("write_something_to_claim_text", this.props.language),
        type: "error",
      });
      return false;
    }
    this.props.handleSubmit({ comment: this.state.text });
    return true;
  }

  render() {
    return (
      <form className="pure-form">
        <div className="pure-u-1-24 pure-u-md-2-24" />
        <div className="pure-u-22-24 pure-u-md-20-24">
          {/* TODO: onKeyPress is required by TextInput */}
          <TextInput
            placeholder={localize(
              "challenge_type_key_text",
              this.props.language,
            )}
            onChange={this.onTextChange.bind(this)}
            onKeyPress={() => {}}
            readOnly={!this.props.isLoggedIn}
            className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
          />
        </div>
        <div className="pure-u-1-24 pure-u-md-2-24" />
        {this.props.isLoggedIn ? (
          <button
            className={
              "button automargin " +
              (this.props.submitted ||
              (this.props.challengeMinViewDuration && this.props.isTimerActive)
                ? "inactive"
                : "cta")
            }
            onClick={this.handleSubmit.bind(this)}
            disabled={
              this.props.submitted ||
              (this.props.challengeMinViewDuration && this.props.isTimerActive)
            }
            id="submitCompletionButton"
          >
            {this.props.challengeMinViewDuration &&
            this.props.countdownTime &&
            this.props.isTimerActive ? (
              <CountdownButton
                countdownTime={this.props.countdownTime}
                setTimerActive={this.props.setTimerActive}
                language={this.props.language}
              />
            ) : this.props.submitted ? (
              localize("button_claim_submitting", this.props.language)
            ) : (
              localize("button_claim_get_points", this.props.language)
            )}
          </button>
        ) : (
          <button
            className={
              "button automargin " +
              (this.props.submitted ||
              (this.props.challengeMinViewDuration && this.props.isTimerActive)
                ? "inactive"
                : "cta")
            }
            disabled={
              this.props.submitted ||
              (this.props.challengeMinViewDuration && this.props.isTimerActive)
            }
            id="loginBeforeSubmitCompletionButton"
          >
            {this.props.challengeMinViewDuration &&
            this.props.countdownTime &&
            this.props.isTimerActive ? (
              <CountdownButton
                countdownTime={this.props.countdownTime}
                setTimerActive={this.props.setTimerActive}
                language={this.props.language}
              />
            ) : this.props.submitted ? (
              localize("button_claim_submitting", this.props.language)
            ) : (
              localize("button_claim_get_points", this.props.language)
            )}
          </button>
        )}
      </form>
    );
  }
}

FixedChallenge.propTypes = propTypes;

export default FixedChallenge;
