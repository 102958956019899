import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export function handleNumberKeyPress(event) {
  // Enter Key
  if (event.which === 13) {
    return;
  }

  // all other inputs
  if (event.which < 48 || event.which > 57) {
    event.preventDefault();
    return;
  }
}

const NumberInput = props => {
  let placeholder = props.placeholder;

  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={props.onChange}
      onKeyPress={handleNumberKeyPress}
      {...props}
    />
  );
};

NumberInput.propTypes = propTypes;

export default NumberInput;
