import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export function handleDecimalKeyPress(event) {
  // Enter Key
  if (event.which === 13) {
    return;
  }

  // decimal point
  if (event.which === 46 && event.target.value.search(/\./) === -1) {
    return;
  }

  // all other inputs
  if (event.which < 48 || event.which > 57) {
    event.preventDefault();
    return;
  }
}

const DecimalInput = props => {
  let placeholder = props.placeholder;

  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={props.onChange}
      onKeyPress={handleDecimalKeyPress}
      {...props}
    />
  );
};

DecimalInput.propTypes = propTypes;

export default DecimalInput;
