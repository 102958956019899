import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import SurveyResult from "./SurveyResult";
import QuizGrid from "./../QuizGrid";

import localize from "lang/localize";

const propTypes = {
  correctAnswerNo: PropTypes.number.isRequired,
  wrongAnswerNo: PropTypes.number.isRequired,
  claimed: PropTypes.bool.isRequired,
  claimedBefore: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  quizOptions: PropTypes.array,
  userName: PropTypes.string,
  userPhoto: PropTypes.string,
  gridWidth: PropTypes.number,
  privateClaim: PropTypes.bool,
  language: PropTypes.string
};

/**
 * Display Results for Quiz Challenge Types
 */
class QuizResult extends Component {
  /**
   * Render private completion info
   */
  renderPrivateCompletionText() {
    if (this.props.privateClaim) {
      return (
        <p className="privateClaimResultText text-align-center bottommargin-20">
          <small>
            <em>
              {localize(
                "challenge_profile_private_claim_result_text",
                this.props.language
              )}
            </em>
          </small>
        </p>
      );
    } else {
      return null;
    }
  }

  /**
   * Renders the number of players who got the answer correct/wrong
   * (This will not render if completions are private.)
   */
  renderPlayerResultCount() {
    if (!this.props.privateClaim) {
      return (
        <div
          className={
            "container verticalpadding" +
            (this.props.claimed && !this.props.repeatUntilCorrect
              ? " verticalpadding"
              : "")
          }
        >
          <div className="innerblock">
            <h5 className="text-align-center bottommargin-30">
              {localize("other_completions", this.props.language)}
            </h5>
            <div className="pure-g" id="quizActivityLinkContainer">
              <div className="pure-u-4-24">
                <span className="challengeicon quiz-result floatright rightmargin right" />
              </div>
              <div className="pure-u-8-24">
                <strong>{this.props.correctAnswerNo}</strong>
                <div className="smalltext uppercase">
                  {localize(
                    "challenge_profile_quiz_correct_short",
                    this.props.language
                  )}
                </div>
              </div>
              <div className="pure-u-4-24">
                <span className="challengeicon quiz-result floatright rightmargin wrong" />
              </div>
              <div className="pure-u-8-24">
                <strong>{this.props.wrongAnswerNo}</strong>
                <div className="smalltext uppercase">
                  {localize(
                    "challenge_profile_quiz_incorrect_short",
                    this.props.language
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Display the correct answer for quiz (text)
   */
  renderCorrectAnswerText() {
    if (this.props.claimed && this.props.correctAnswerTitle) {
      return (
        <p
          id="correctAns"
          className="text-align-center nomargin align-items-center"
        >
          <small>
            {this.props.correctAnswer
              ? localize(
                  "challenge_profile_quiz_correct",
                  this.props.language
                ).format(this.props.correctAnswerTitle)
              : localize(
                  "challenge_profile_quiz_incorrect",
                  this.props.language
                ).format(this.props.correctAnswerTitle)}
          </small>
        </p>
      );
    } else if (this.props.correctAnswer === false) {
      return (
        <p
          id="wrongAns"
          className="text-align-center nomargin align-items-center"
        >
          <small>
            {localize(
              "challenge_profile_quiz_incorrect_without_answer",
              this.props.language
            )}
          </small>
        </p>
      );
    } else {
      return null;
    }
  }

  /**
   * Renders quiz options result in default view
   */
  renderDefaultQuizResultOptions() {
    if (this.props.claimed && this.props.correctAnswerTitle) {
      return (
        <Fragment>
          <SurveyResult
            quizOptions={this.props.quizOptions}
            userName={this.props.userName}
            userPhoto={this.props.userPhoto}
          />
          {!this.props.privateClaim ? (
            <div className="challenge-page-divider" />
          ) : null}
        </Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Renders quiz options result in grid view
   */
  renderGridQuizResultOptions() {
    if (this.props.claimed && this.props.correctAnswerTitle) {
      return (
        <Fragment>
          <div className="container bottompadding">
            <div className="innerblock">
              <div className="pure-u-md-2-24 pure-u-0-24" />
              <div className="pure-u-md-20-24 pure-u-1">
                <QuizGrid
                  gridWidth={this.props.gridWidth}
                  quizOptions={this.props.quizOptions}
                />
              </div>
            </div>
          </div>
          {!this.props.privateClaim ? (
            <div className="challenge-page-divider" />
          ) : null}
        </Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Determines quiz result layout and renders accordingly
   */
  renderQuizResult() {
    if (this.props.gridWidth === undefined || this.props.gridWidth === 0) {
      return this.renderDefaultQuizResult();
    } else {
      return this.renderGridQuizResult();
    }
  }

  /**
   * Render quiz results for default view
   */
  renderDefaultQuizResult() {
    return (
      <Fragment>
        <div className="challenge-page-divider" />
        {this.props.claimed && this.props.correctAnswerTitle ? (
          <div>
            <div className="container verticalpadding">
              <h5 className="text-align-center nomargin">
                {localize("nav_bar_title_feedback_view", this.props.language)}
              </h5>
              {this.renderPrivateCompletionText()}
              {/* {this.renderCorrectAnswerText()} */}
            </div>
            {this.renderDefaultQuizResultOptions()}
          </div>
        ) : null}
        {!this.props.privateClaim ? this.renderPlayerResultCount() : null}
      </Fragment>
    );
  }

  /**
   * Render quiz results for grid view
   */
  renderGridQuizResult() {
    return (
      <Fragment>
        <div className="challenge-page-divider" />
        {this.props.claimed && this.props.correctAnswerTitle ? (
          <div>
            <div className="container verticalpadding">
              <h5 className="text-align-center">
                {localize("nav_bar_title_feedback_view", this.props.language)}
              </h5>
              {this.renderPrivateCompletionText()}
            </div>
            {this.renderGridQuizResultOptions()}
          </div>
        ) : null}
        {!this.props.privateClaim ? this.renderPlayerResultCount() : null}
      </Fragment>
    );
  }

  /**
   * Render quiz result view
   */
  render() {
    return this.renderQuizResult();
  }
}

QuizResult.propTypes = propTypes;

export default QuizResult;
