import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import ProjectsListPage from "./ProjectsListPage";

import getApiGenerator from "services/getApiGenerator";
import Router from "router";
import { LOGIN, HOME } from "App/Routes";
import { LOAD_MORE_COUNT, SHOW_PUBLIC_PROJECT_LIST } from "config";
import { GET_PROJECTS_LIST } from "services/api";
import listenerServices from "services/listenerServices";
import { MASTERS_ID } from "config";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    language: state.language,
  };
};

export class ProjectsListContainer extends Component {
  constructor() {
    super();
    this.state = {
      projects: null,
      more: false,
      page: 1,
      user: null,
      isLoadingMore: false,
    };
    this.handleMore = this.handleMore.bind(this);
    this.getProjects = this.getProjects.bind(this);
  }

  componentDidMount() {
    this.getProjects(this.state.page);
    window.addEventListener("scroll", this.handleMore);

    if (this.props.sessionKey === null) {
      if (SHOW_PUBLIC_PROJECT_LIST) {
        if (window.location.pathname !== HOME) {
          Router.navigate(LOGIN);
          Router.check();
        }
      } else {
        Router.navigate(LOGIN);
        Router.check();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getProjects(this.state.page);
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
    window.removeEventListener("scroll", this.handleMore);
  }

  getProjects(page) {
    // lack sessionless api support for now
    getApiGenerator(
      GET_PROJECTS_LIST.format(MASTERS_ID),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ projects: [], isLoadingMore: false });
        }
      } else {
        this.setState({
          projects: this.state.projects
            ? this.state.projects.slice().concat(res.body.data)
            : res.body.data,
          more: res.body.more,
          page: this.state.page + 1,
          user: res.body.user,
          isLoadingMore: false,
        });
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getProjects,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getProjects(page);
      }
    }
  }, 100);

  render() {
    /*
      api failing due to lack of valid session key will show
      "No games are available." instead of NotLoggedInContainer
      as there is no way to differentiate between lack of
      session key and lack of projects in api call
      will be fixed when sessionless api is implemented
    */
    if (!SHOW_PUBLIC_PROJECT_LIST && !this.props.user.id) {
      return (
        <NotLoggedInContainer
          language={this.props.language}
          showLogin={false}
          fullHeight={true}
        />
      );
    }

    if (this.state.projects && this.state.projects.length !== 0) {
      return (
        <ProjectsListPage
          projects={this.state.projects}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          language={this.props.language}
          user={this.state.user}
          isLoadingMore={this.state.isLoadingMore}
        />
      );
    } else if (this.state.projects) {
      return (
        <GenericErrorPage
          message={localize("empty_bundles_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps)(ProjectsListContainer);
