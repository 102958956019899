import React, { Component } from "react";
import PropTypes from "prop-types";

import { TEAM_INVITE_PAGE_LINK } from "config";
import deviceServices from "services/deviceServices";
import PostIframe from "../../../shared/PostIframe/PostIframe";

import localize from "lang/localize";

const propTypes = {
  sessionKey: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  language: PropTypes.string
};

class TeamInvitePage extends Component {
  render() {
    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          title={localize("invite_players_text", this.props.language)}
          hiddenFields={{
            session_key: this.props.sessionKey,
            team_id: this.props.teamId
          }}
          url={TEAM_INVITE_PAGE_LINK}
        />
      </div>
    );
  }
}

TeamInvitePage.propTypes = propTypes;

export default TeamInvitePage;
