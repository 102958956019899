function scrollToItem(item) {
  let diff = (item.offsetTop - window.scrollY) / 10;
  if (!window._lastDiff) {
    window._lastDiff = 0;
  }

  if (Math.abs(diff) > 2) {
    window.scrollTo(0, window.scrollY + diff);
    clearTimeout(window._TO);

    if (diff !== window._lastDiff) {
      window._lastDiff = diff;
      window._TO = setTimeout(scrollToItem, 15, item);
    }
  } else {
    window.scrollTo(0, item.offsetTop);
  }
}

function scrollToId(id) {
  let dest = document.getElementById(id);
  if (dest) {
    scrollToItem(dest);
  }
}

export default scrollToId;
