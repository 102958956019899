import React, { Component } from "react";
import { connect } from "react-redux";

import { POST_LOGIN_INTERCEPT, PROJECT_POST_LOGIN_INTERCEPT } from "App/Routes";
import LoginHomePage from "./LoginHomePage";

import Router from "router";
import {
  setSessionKey,
  setUserId,
  setProject,
  showAlertWithTimeout
} from "actions";
import {
  PLAYER_URL,
  CALLBACK_URL_PARAMETER_APPENDED,
  CALLBACK_ID_DEFAULT,
  CALLBACK_ID_PARAMETER_APPENDED,
  SECRET_KEY,
  ENABLE_MICROSOFT_LOGIN
} from "config";
import {
  GET_TOPICS,
  GET_FACEBOOK_OAUTH,
  GET_GOOGLE_OAUTH,
  GET_MICROSOFT_OAUTH
} from "services/api";

import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import localStorageService from "services/localStorageService";
import sessionStorageService from "services/sessionStorageService";
import loginServices from "services/loginServices";
import localize from "lang/localize";

import {
  MICROSOFT_CLIENT_INSTANCE,
  MICROSOFT_APP_LOGIN_REQUEST,
  MICROSOFT_LOGIN_USE_REDIRECT,
  MICROSOFT_SESSION_TOKEN,
  MICROSOFT_SESSION
} from "services/microsoftServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    guestEnabled:
      state.project && state.project.guestEnabled
        ? state.project.guestEnabled
        : false,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: user => {
      dispatch(setUserId(user));
    },
    setProject: project => {
      dispatch(setProject(project));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

export class LoginHomeContainer extends Component {
  constructor() {
    super();
    this.state = {
      showGuestLoginDialog: false
    };

    /* Guest Login Dialogs */
    this.handleOpenGuestLoginDialog = this.handleOpenGuestLoginDialog.bind(
      this
    );
    this.handleCloseGuestLoginDialog = this.handleCloseGuestLoginDialog.bind(
      this
    );

    /* OAuth */
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseFacebookError = this.responseFacebookError.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseGoogleError = this.responseGoogleError.bind(this);
    this.responseMicrosoft = this.responseMicrosoft.bind(this);
    this.handleSaveSessionAndRedirect = this.handleSaveSessionAndRedirect.bind(
      this
    );
  }

  componentDidMount() {
    let projectId = this.props.projectId;

    if (!projectId) {
      projectId = localStorageService.getItem("last_project_id");
      localStorageService.removeItem("last_project_id");
    }

    if (projectId) {
      this.getProjectGuestEnabled();
    }

    if (this.props.sessionKey && this.props.userId) {
      let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

      /* loginRedirectUrl check is necessary for IE. */
      /* Somehow componentWillMount() will run for LoginContainer in IE, */
      /* even after hitting login submit where routing is supposed to occur */
      loginServices.postLoginReplaceAndNavigate(
        projectId,
        loginRedirectUrl,
        false
      );
    } else if (MICROSOFT_LOGIN_USE_REDIRECT && ENABLE_MICROSOFT_LOGIN) {
      const parent = this;

      MICROSOFT_CLIENT_INSTANCE.handleRedirectPromise()
        .then(loginResponse => {
          this.redirectResponseMicrosoft(parent, loginResponse);
        })
        .catch(error => {
          // console.error(error);
        });
    }
  }

  getProjectGuestEnabled() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (!(err || res.body.code !== 200)) {
        this.props.setProject(res.body.game);
      }
    });
  }

  handleOpenGuestLoginDialog = event => {
    event.preventDefault();

    this.setState({
      showGuestLoginDialog: true
    });
  };

  handleCloseGuestLoginDialog = event => {
    event.preventDefault();

    this.setState({
      showGuestLoginDialog: false
    });
  };

  handleSaveSessionAndRedirect(userId, sessionKey, isFirstLogin) {
    localStorageService.saveState({
      user_id: userId,
      sessionKey: sessionKey
    });

    if (!!userId && !!sessionKey) {
      this.props.setUserId(userId);
      this.props.setSessionKey(sessionKey);
    }

    // loads loginRedirectUrl from sessionStorage (if available)
    // eventually loginRedirectUrl will be dumped when new page is loaded
    // (not REGISTER nor LOGIN nor RESET_PASSWORD)
    // for the aforementioned dumping, see SingleProjectApp.js
    let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

    /* Use isFirstLogin flag to determine if tours should be displayed */
    loginServices.postLoginReplaceAndNavigate(
      this.props.projectId,
      loginRedirectUrl,
      isFirstLogin
    );
  }

  redirectResponseMicrosoft(parent, loginResponse) {
    if (!loginResponse) {
      return;
    }

    const hasMicrosoftResponse = localStorageService.getItem(
      MICROSOFT_SESSION_TOKEN
    );
    if (hasMicrosoftResponse != null) {
      return;
    }

    let query = {
      mobile: true,
      type: "login",
      request_token: loginResponse.accessToken,
      api_key: SECRET_KEY
    };

    let req = pushApiGenerator(GET_MICROSOFT_OAUTH, query);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          parent.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else if (res.body.sessionKey && res.body.interceptLogin) {
        // Use markers to prevent unintended logout or auto login
        localStorageService.setItem(MICROSOFT_SESSION_TOKEN, "true");
        localStorageService.setItem(MICROSOFT_SESSION, "true");

        /* Intercept Login */
        const INTERCEPT_LOGIN_URL =
          res.body.interceptLoginUrl +
          "?session_key=" +
          res.body.sessionKey +
          (CALLBACK_ID_DEFAULT != null
            ? CALLBACK_ID_PARAMETER_APPENDED + CALLBACK_ID_DEFAULT
            : CALLBACK_URL_PARAMETER_APPENDED +
              PLAYER_URL +
              "frameloginsuccess?sessionKey=" +
              res.body.sessionKey);

        if (parent.props.projectId) {
          Router.navigate(
            PROJECT_POST_LOGIN_INTERCEPT.format(parent.props.projectId) +
              "?intercept_url=" +
              encodeURIComponent(INTERCEPT_LOGIN_URL)
          );
        } else {
          Router.navigate(
            POST_LOGIN_INTERCEPT +
              "?intercept_url=" +
              encodeURIComponent(INTERCEPT_LOGIN_URL)
          );
        }
      } else {
        // Use markers to prevent unintended logout or auto login
        localStorageService.setItem(MICROSOFT_SESSION_TOKEN, "true");
        localStorageService.setItem(MICROSOFT_SESSION, "true");

        let isFirstLogin = res.body.firstLogin;

        if (!isFirstLogin) {
          // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
          // 1. If user has never logged in to any account in this browser, show tour screen
          // 2. If previous login is for a different account, show tour screen
          // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
          const lastUserId = localStorageService.getItem("last_user_id");

          if (!lastUserId) {
            // First time logging in to this app in current browser
            isFirstLogin = true;
          } else if (parseInt(lastUserId) !== parent.state.userId) {
            // Check if previous login in current browser is for the same account
            isFirstLogin = true;
          }

          localStorageService.setItem("last_user_id", parent.state.userId);
        }

        parent.handleSaveSessionAndRedirect(
          res.body.userId,
          res.body.sessionKey,
          isFirstLogin
        );
      }
    });
  }

  responseMicrosoft() {
    localStorageService.removeItem("last_project_id");
    localStorageService.removeItem(MICROSOFT_SESSION);

    if (MICROSOFT_LOGIN_USE_REDIRECT) {
      localStorageService.removeItem(MICROSOFT_SESSION_TOKEN);

      if (this.props.projectId) {
        // Use whitelisted redirect URL and store project id in storage instead
        localStorageService.setItem("last_project_id", this.props.projectId);

        MICROSOFT_CLIENT_INSTANCE.loginRedirect({
          ...MICROSOFT_APP_LOGIN_REQUEST,
          redirectUri: PLAYER_URL
        });
      } else {
        MICROSOFT_CLIENT_INSTANCE.loginRedirect(MICROSOFT_APP_LOGIN_REQUEST);
      }

      return;
    }

    const parent = this;

    MICROSOFT_CLIENT_INSTANCE.loginPopup(MICROSOFT_APP_LOGIN_REQUEST)
      .then(function(loginResponse) {
        let query = {
          mobile: true,
          type: "login",
          request_token: loginResponse.accessToken,
          api_key: SECRET_KEY
        };

        let req = pushApiGenerator(GET_MICROSOFT_OAUTH, query);

        req.end((err, res) => {
          if (err || res.body.code !== 200) {
            if (res.body.error) {
              parent.props.showAlertWithTimeout({
                text: res.body.error,
                type: "error"
              });
            }
          } else if (res.body.sessionKey && res.body.interceptLogin) {
            // Use marker to prevent unintended logout
            localStorageService.setItem(MICROSOFT_SESSION, "true");

            /* Intercept Login */
            const INTERCEPT_LOGIN_URL =
              res.body.interceptLoginUrl +
              "?session_key=" +
              res.body.sessionKey +
              (CALLBACK_ID_DEFAULT != null
                ? CALLBACK_ID_PARAMETER_APPENDED + CALLBACK_ID_DEFAULT
                : CALLBACK_URL_PARAMETER_APPENDED +
                  PLAYER_URL +
                  "frameloginsuccess?sessionKey=" +
                  res.body.sessionKey);

            if (parent.props.projectId) {
              Router.navigate(
                PROJECT_POST_LOGIN_INTERCEPT.format(parent.props.projectId) +
                  "?intercept_url=" +
                  encodeURIComponent(INTERCEPT_LOGIN_URL)
              );
            } else {
              Router.navigate(
                POST_LOGIN_INTERCEPT +
                  "?intercept_url=" +
                  encodeURIComponent(INTERCEPT_LOGIN_URL)
              );
            }
          } else {
            // Use marker to prevent unintended logout
            localStorageService.setItem(MICROSOFT_SESSION, "true");

            let isFirstLogin = res.body.firstLogin;

            if (!isFirstLogin) {
              // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
              // 1. If user has never logged in to any account in this browser, show tour screen
              // 2. If previous login is for a different account, show tour screen
              // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
              const lastUserId = localStorageService.getItem("last_user_id");

              if (!lastUserId) {
                // First time logging in to this app in current browser
                isFirstLogin = true;
              } else if (parseInt(lastUserId) !== parent.state.userId) {
                // Check if previous login in current browser is for the same account
                isFirstLogin = true;
              }

              localStorageService.setItem("last_user_id", parent.state.userId);
            }

            parent.handleSaveSessionAndRedirect(
              res.body.userId,
              res.body.sessionKey,
              isFirstLogin
            );
          }
        });
      })
      .catch(e => {
        parent.props.showAlertWithTimeout({
          text: localize("login_failed_text", this.props.language),
          type: "error"
        });

        // console.error(e);
      });
  }

  responseFacebook(response) {
    let query = {
      mobile: true,
      type: "login",
      request_token: response.accessToken,
      api_key: SECRET_KEY
    };

    let req = pushApiGenerator(GET_FACEBOOK_OAUTH, query);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else if (res.body.sessionKey && res.body.interceptLogin) {
        /* Intercept Login */
        const INTERCEPT_LOGIN_URL =
          res.body.interceptLoginUrl +
          "?session_key=" +
          res.body.sessionKey +
          (CALLBACK_ID_DEFAULT != null
            ? CALLBACK_ID_PARAMETER_APPENDED + CALLBACK_ID_DEFAULT
            : CALLBACK_URL_PARAMETER_APPENDED +
              PLAYER_URL +
              "frameloginsuccess?sessionKey=" +
              res.body.sessionKey);

        if (this.props.projectId) {
          Router.navigate(
            PROJECT_POST_LOGIN_INTERCEPT.format(this.props.projectId) +
              "?intercept_url=" +
              encodeURIComponent(INTERCEPT_LOGIN_URL)
          );
        } else {
          Router.navigate(
            POST_LOGIN_INTERCEPT +
              "?intercept_url=" +
              encodeURIComponent(INTERCEPT_LOGIN_URL)
          );
        }
      } else {
        let isFirstLogin = res.body.firstLogin;

        if (!isFirstLogin) {
          // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
          // 1. If user has never logged in to any account in this browser, show tour screen
          // 2. If previous login is for a different account, show tour screen
          // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
          const lastUserId = localStorageService.getItem("last_user_id");

          if (!lastUserId) {
            // First time logging in to this app in current browser
            isFirstLogin = true;
          } else if (parseInt(lastUserId) !== this.state.userId) {
            // Check if previous login in current browser is for the same account
            isFirstLogin = true;
          }

          localStorageService.setItem("last_user_id", this.state.userId);
        }

        this.handleSaveSessionAndRedirect(
          res.body.userId,
          res.body.sessionKey,
          isFirstLogin
        );
      }
    });
  }

  responseFacebookError(error) {
    return false;
  }

  responseGoogle(response) {
    let query = {
      mobile: true,
      type: "login",
      request_token: response.credential,
      api_key: SECRET_KEY
    };

    let req = pushApiGenerator(GET_GOOGLE_OAUTH, query);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else if (res.body.sessionKey && res.body.interceptLogin) {
        /* Intercept Login */
        const INTERCEPT_LOGIN_URL =
          res.body.interceptLoginUrl +
          "?session_key=" +
          res.body.sessionKey +
          (CALLBACK_ID_DEFAULT != null
            ? CALLBACK_ID_PARAMETER_APPENDED + CALLBACK_ID_DEFAULT
            : CALLBACK_URL_PARAMETER_APPENDED +
              PLAYER_URL +
              "frameloginsuccess?sessionKey=" +
              res.body.sessionKey);

        if (this.props.projectId) {
          Router.navigate(
            PROJECT_POST_LOGIN_INTERCEPT.format(this.props.projectId) +
              "?intercept_url=" +
              encodeURIComponent(INTERCEPT_LOGIN_URL)
          );
        } else {
          Router.navigate(
            POST_LOGIN_INTERCEPT +
              "?intercept_url=" +
              encodeURIComponent(INTERCEPT_LOGIN_URL)
          );
        }
      } else {
        let isFirstLogin = res.body.firstLogin;

        if (!isFirstLogin) {
          // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
          // 1. If user has never logged in to any account in this browser, show tour screen
          // 2. If previous login is for a different account, show tour screen
          // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
          const lastUserId = localStorageService.getItem("last_user_id");

          if (!lastUserId) {
            // First time logging in to this app in current browser
            isFirstLogin = true;
          } else if (parseInt(lastUserId) !== this.state.userId) {
            // Check if previous login in current browser is for the same account
            isFirstLogin = true;
          }

          localStorageService.setItem("last_user_id", this.state.userId);
        }

        this.handleSaveSessionAndRedirect(
          res.body.userId,
          res.body.sessionKey,
          isFirstLogin
        );
      }
    });
  }

  responseGoogleError(error, details) {
    if (error && details) {
      this.props.showAlertWithTimeout({
        text: details,
        type: "error"
      });
    }
  }

  render() {
    return (
      <LoginHomePage
        projectId={this.props.projectId}
        language={this.props.language}
        /* Guest Login Dialog */
        guestEnabled={this.props.guestEnabled}
        showGuestLoginDialog={this.state.showGuestLoginDialog}
        handleOpenGuestLoginDialog={this.handleOpenGuestLoginDialog}
        handleCloseGuestLoginDialog={this.handleCloseGuestLoginDialog}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseMicrosoft={this.responseMicrosoft}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginHomeContainer);
