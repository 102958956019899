import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { showAlertWithTimeout } from "actions";

import ActionConfirmationDialog from "./ActionConfirmationDialog";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  // Confirmation text to display
  confirmText: PropTypes.string,
  // Action to execute when user cancels the action
  handleCloseActionConfirmationDialog: PropTypes.func.isRequired,
  // Action to execute when user confirms the action
  handleAction: PropTypes.func.isRequired,
};

const defaultProps = {
  showModal: false,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
  };
};

/**
 * Confirmation Dialog
 * To be implemented every time a deletion action is taken, or
 * any action that cannot be undone.
 */
class ActionConfirmationDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
    };
  }

  /**
   * Renders the confirmation dialog
   */
  render() {
    return (
      <ActionConfirmationDialog
        showModal={this.props.showModal}
        confirmText={this.props.confirmText}
        handleCloseActionConfirmationDialog={
          this.props.handleCloseActionConfirmationDialog
        }
        handleAction={this.props.handleAction}
        language={this.props.language}
      />
    );
  }
}

ActionConfirmationDialogContainer.propTypes = propTypes;
ActionConfirmationDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionConfirmationDialogContainer);
