import React, { Component } from "react";
import PropTypes from "prop-types";

import "./StandardChallenge.css";
import TextboxInput from "components/shared/Inputs/TextboxInput";
import ImageInput from "components/shared/Inputs/ImageInput";
import CountdownButton from "components/shared/Countdown/CountdownButton";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";

import localize from "lang/localize";

import { ENABLE_PHOTO_CHALLENGE_TEXTBOX } from "config";

const propTypes = {
  photoRequired: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  challengeMinViewDuration: PropTypes.number,
  isTimerActive: PropTypes.bool,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string,
  charLimit: PropTypes.number,
};

const defaultProps = {
  photoRequired: false,
};

class StandardChallenge extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      imagename: null,
      image: null,
    };
  }

  onTextChange(event) {
    this.setState({ text: event.target.value });
  }

  onImageChange(image) {
    if (image[0]) {
      this.setState({
        image: image[0],
        imagename: image[0].name,
      });
    }
  }

  onImageReset() {
    this.setState({
      image: null,
      imagename: null,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.handleSubmit(
      { comment: this.state.text },
      this.state.image,
      this.state.imagename,
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="pure-form">
          {/*
              Set the above back to <form className="pure-form">
              if we are no longer using <input type="file">
              for image inputs
          */}
          {(!this.props.photoRequired || ENABLE_PHOTO_CHALLENGE_TEXTBOX) && (
            <div className="pure-u-1-1 pure-u-md-16-24 align-fields-to-image-input relative">
              <TextboxInput
                placeholder={localize(
                  "comment_placeholder_text",
                  this.props.language,
                )}
                onChange={this.onTextChange.bind(this)}
                readOnly={!this.props.isLoggedIn}
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
              <CharacterCounter
                input={this.state.text}
                charLimit={this.props.charLimit}
              />
            </div>
          )}
          <div
            className={
              "pure-u-1-1 " +
              (!this.props.photoRequired || ENABLE_PHOTO_CHALLENGE_TEXTBOX
                ? "pure-u-md-8-24"
                : "pure-u-md-1-1 automargin")
            }
          >
            <ImageInput
              imagename={this.state.imagename}
              onDrop={this.onImageChange.bind(this)}
              resetFiles={this.onImageReset.bind(this)}
              showAlertWithTimeout={this.props.showAlertWithTimeout}
              disabled={!this.props.isLoggedIn}
              language={this.props.language}
            />
          </div>
        </div>
        <div>
          {this.props.isLoggedIn ? (
            <button
              className={
                "button automargin " +
                (this.props.submitted ||
                (this.props.photoRequired && this.state.image == null) ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive) ||
                (this.props.charLimit !== 0 &&
                  this.state.text.length > this.props.charLimit)
                  ? "inactive"
                  : "cta")
              }
              onClick={this.handleSubmit.bind(this)}
              disabled={
                this.props.submitted ||
                (this.props.photoRequired && this.state.image == null) ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive)
              }
              id="submitCompletionButton"
            >
              {this.props.challengeMinViewDuration &&
              this.props.countdownTime &&
              this.props.isTimerActive ? (
                <CountdownButton
                  countdownTime={this.props.countdownTime}
                  setTimerActive={this.props.setTimerActive}
                  language={this.props.language}
                />
              ) : this.props.submitted ? (
                localize("button_claim_submitting", this.props.language)
              ) : this.props.photoRequired && this.state.image == null ? (
                localize(
                  "button_claim_upload_photo_to_submit",
                  this.props.language,
                )
              ) : (
                localize("button_claim_get_points", this.props.language)
              )}
            </button>
          ) : (
            <button
              className={
                "button automargin " +
                (this.props.submitted ||
                (this.props.photoRequired && this.state.image == null) ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive) ||
                (this.props.charLimit !== 0 &&
                  this.state.text.length > this.props.charLimit)
                  ? "inactive"
                  : "cta")
              }
              disabled={
                this.props.submitted ||
                (this.props.photoRequired && this.state.image == null) ||
                (this.props.challengeMinViewDuration &&
                  this.props.isTimerActive)
              }
              id="loginBeforeSubmitCompletionButton"
            >
              {this.props.challengeMinViewDuration &&
              this.props.countdownTime &&
              this.props.isTimerActive ? (
                <CountdownButton
                  countdownTime={this.props.countdownTime}
                  setTimerActive={this.props.setTimerActive}
                  language={this.props.language}
                />
              ) : this.props.submitted ? (
                localize("button_claim_submitting", this.props.language)
              ) : this.props.photoRequired ? (
                localize(
                  "button_claim_upload_photo_to_submit",
                  this.props.language,
                )
              ) : (
                localize("button_claim_get_points", this.props.language)
              )}
            </button>
          )}
          {/*
            Set the below back to </form> if we are
            no longer using <input type="file"> for
            image inputs
          */}
        </div>
      </React.Fragment>
    );
  }
}

StandardChallenge.propTypes = propTypes;
StandardChallenge.defaultProps = defaultProps;

export default StandardChallenge;
