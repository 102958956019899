import React, { Fragment } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

import mentionsClass from "./../mentions.module.css";
import { MentionsInput, Mention } from "react-mentions";
import AdvancedSelectInput from "components/shared/Inputs/AdvancedSelectInput";
import TextInput from "components/shared/Inputs/TextInput";
import MultipleImageInput from "components/shared/Inputs/MultipleImageInput";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";

import localize from "lang/localize";
import getApiGenerator from "services/getApiGenerator";
import { SEARCH_PROJECT } from "services/api";
import { MENTIONS_LIMIT } from "config";

import "./NewPost.css";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  more: PropTypes.bool,
  handleMore: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.string,
  userId: PropTypes.number,
  removePost: PropTypes.func,
  // character limit
  charLimit: PropTypes.number,
  boards: PropTypes.array,
  posts: PropTypes.array,
  handleLike: PropTypes.func,
  handleBookmark: PropTypes.func,
  isBookmarked: PropTypes.bool,
  showInput: PropTypes.bool,
  titleInput: PropTypes.string,
  selectedOption: PropTypes.object,
  handleTitleInputChange: PropTypes.func,
  input: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleBoardInputChange: PropTypes.func,
  showAlertWithTimeout: PropTypes.func,
  fileUploadLimit: PropTypes.number,
  fileTotalLimit: PropTypes.number,
};

const TitleInput = (props) => {
  const handleTitleInput = (event) => {
    if (
      event.target.value.trim() === "" ||
      (props.charLimit !== 0 && event.target.value.length > props.charLimit)
    ) {
      props.setTitleCheck(false);
    } else {
      props.setTitleCheck(true);
    }
    props.handleTitleInputChange(event);
  };

  return (
    <div id="titleInput">
      <h5>{localize("discussion_new_post_title", props.language)}:</h5>
      <div className="pure-u-1 relative">
        <TextInput
          value={props.titleInput}
          placeholder={localize(
            "discussion_new_post_title_placeholder",
            props.language,
          )}
          onChange={handleTitleInput}
          onKeyPress={() => {}}
        ></TextInput>
        <CharacterCounter
          charLimit={props.charLimit}
          input={props.titleInput}
        />
      </div>
    </div>
  );
};

const ContentInput = (props) => {
  const [mentionsUserList, setMentionsUserList] = React.useState([]);
  const [storedUserList, setStoredUserList] = React.useState([]);
  const [numberOfMentions, setNumberOfMentions] = React.useState(0);

  const getPlayers = debounce((search, callback) => {
    getApiGenerator(
      SEARCH_PROJECT.format(props.projectId),
      {
        type: "user",
        keywords: search,
      },
      props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          return [];
        }
      } else {
        const formattedPlayers = res.body.data.map((user) => ({
          id: user.id,
          display: user.userName ? user.userName : " ",
          userImage: user.userImage,
        }));
        setMentionsUserList(formattedPlayers);
        callback(formattedPlayers);
      }
    });
  }, 500);

  const onAdd = (search) => {
    const foundUser = mentionsUserList.find(
      (user) => user.id === parseInt(search),
    );
    setStoredUserList([...storedUserList, foundUser]);
  };

  // checks input against regex to count accurate number of mentions
  // checks input to ensure textbox is not empty, number of mentions do not exceed limit and character limit is not exceeded
  const checkInput = (event) => {
    const regexp = /(\[uid:[0-9]+\])/gm;
    const transformedInput = event.target.value.replace(
      /(\[uid:[0-9]+\])/gm,
      "",
    );
    let mentionsInInput = event.target.value.match(regexp);
    if (mentionsInInput !== null) {
      setNumberOfMentions(event.target.value.match(regexp).length);
    } else {
      setNumberOfMentions(0);
    }
    if (
      event.target.value.trim() === "" ||
      numberOfMentions > MENTIONS_LIMIT ||
      (props.charLimit !== 0 && transformedInput.length > props.charLimit)
    ) {
      props.setContentCheck(false);
    } else {
      props.setContentCheck(true);
    }
    props.handleInputChange(event);
  };

  if (!!props.showInput) {
    return (
      <div id="contentInput">
        <div className="pure-u-1 relative">
          <h5>{localize("discussion_post_description", props.language)}:</h5>
          <MentionsInput
            value={props.input}
            placeholder={localize("generic_placeholder_text", props.language)}
            onChange={checkInput}
            classNames={mentionsClass}
            allowSpaceInQuery={true}
            a11ySuggestionsListLabel="Suggested mentions"
          >
            <Mention
              trigger="@"
              data={getPlayers}
              renderSuggestion={(suggestion, search, highlightedDisplay) => (
                <div className="flex align-items-center">
                  <div className="rightmargin-20 suggestion-image-container">
                    <img
                      className="suggestion-image"
                      src={suggestion.userImage}
                      alt="pfp"
                    />
                  </div>
                  {suggestion.display}
                </div>
              )}
              displayTransform={(id) => {
                const foundUser = mentionsUserList.find(
                  (user) => user.id === parseInt(id),
                );
                const storedUser = storedUserList.find(
                  (user) => user.id === parseInt(id),
                );
                return foundUser
                  ? `@${foundUser.display}`
                  : `@${storedUser.display}`;
              }}
              onAdd={onAdd}
              markup="[uid:__id__]"
              className={mentionsClass.mentions__mention}
            />
          </MentionsInput>
          <CharacterCounter charLimit={props.charLimit} input={props.input} />
        </div>
        {numberOfMentions > MENTIONS_LIMIT && (
          <div className="pure-u-1">
            <span className="text-red">
              {localize("alert_exceeded_mentions_limit", props.language).format(
                MENTIONS_LIMIT,
              )}
            </span>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

const BoardInput = (props) => {
  const options = props.boards.map((board) => ({
    value: board.id,
    label: board.boardTitle,
  }));

  let defaultOption = null;

  if (props.currentBoard && options) {
    for (const option of options) {
      if (parseInt(option.value) === parseInt(props.currentBoard)) {
        defaultOption = option;
        break;
      }
    }
  }

  if (!defaultOption) {
    // Use the first board as it is most likely the default board for project
    defaultOption = options[0];
  }

  return (
    <div id="boardInput">
      <h5>{localize("discussion_board", props.language)}:</h5>
      <div className="pure-u-1 relative">
        <AdvancedSelectInput
          name="boards"
          className="bottommargin board-select"
          defaultOption={defaultOption}
          selectedOption={props.selectedOption}
          options={options}
          placeholder={localize("discussion_board_select", props.language)}
          onChange={props.handleBoardInputChange}
          disabled={!!props.currentBoard}
          height={300}
        />
      </div>
    </div>
  );
};

const UploadInput = (props) => {
  const onImageChange = (images) => {
    if (images) {
      Array.from(images).forEach((image) =>
        props.setImages(!!props.images ? [...props.images, image] : [image]),
      );
    }
  };

  const onImageReset = () => {
    props.setImages([]);
  };

  return (
    <div id="fileInput" className="discussions-post-image-upload">
      <div className="pure-u-1-1">
        <MultipleImageInput
          imagename={props.imageName}
          images={props.images} // for resetting images after submit
          onDrop={onImageChange}
          resetFiles={onImageReset}
          showAlertWithTimeout={props.showAlertWithTimeout}
          disabled={!props.isLoggedIn}
          fromDiscussions={true}
          language={props.language}
          fileUploadLimit={props.fileUploadLimit}
          fileTotalLimit={props.fileTotalLimit}
        />
      </div>
    </div>
  );
};

const NewPostPage = (props) => {
  const [titleCheck, setTitleCheck] = React.useState(false);
  const [contentCheck, setContentCheck] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [imageName, setImageName] = React.useState(null);

  const resetImages = () => {
    setImages(null);
    setImageName(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(images);
    resetImages();
  };

  return (
    <Fragment>
      <div className="discussions-components discussions-new-post-container container verticalpadding bottompadding-floating-extended">
        <div className="innerblock">
          {/* Using div as there would be nested form with file upload */}
          <div className="pure-form flex flex-direction-column">
            <TitleInput
              titleInput={props.titleInput}
              charLimit={props.charLimit}
              handleTitleInputChange={props.handleTitleInputChange}
              setTitleCheck={setTitleCheck}
              language={props.language}
            />
            <ContentInput
              projectId={props.projectId}
              sessionKey={props.sessionKey}
              showInput={props.showInput}
              input={props.input}
              handleSubmit={props.handleSubmit}
              handleInputChange={props.handleInputChange}
              language={props.language}
              charLimit={props.charLimit}
              setContentCheck={setContentCheck}
            />
            <BoardInput
              boards={props.boards}
              currentBoard={props.id2}
              handleBoardInputChange={props.handleBoardInputChange}
              selectedOption={props.selectedOption}
              language={props.language}
            />
            <UploadInput
              setImageName={setImageName}
              imageName={imageName}
              isLoggedIn={!!props.userId}
              showAlertWithTimeout={props.showAlertWithTimeout}
              setImages={setImages}
              images={images}
              fileUploadLimit={props.fileUploadLimit}
              fileTotalLimit={props.fileTotalLimit}
              language={props.language}
            />
            <div className="discussions-post-submit">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={!titleCheck && !contentCheck}
                className={
                  "button nomargin floatright " +
                  (titleCheck || contentCheck ? "cta" : "")
                }
              >
                {localize("button_submit", props.language)}
              </button>
            </div>
          </div>
        </div>
        <div className="pure-u-1-24" />
      </div>
    </Fragment>
  );
};

NewPostPage.propTypes = propTypes;

export default NewPostPage;
