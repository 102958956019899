import React, { Component } from "react";
import PropTypes from "prop-types";
import TextInput from "components/shared/Inputs/TextInput";
import "./SearchBar.css";

import localize from "lang/localize";

const propTypes = {
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  button: PropTypes.bool,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  language: PropTypes.string
};

const defaultProps = {
  button: true,
  defaultValue: ""
};

class SearchBar extends Component {
  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  render() {
    return (
      <form className="pure-form">
        <div className="search-bar flex align-items-center textcenter">
          <TextInput
            id="searchInput"
            className="search-input zeromargin mobile-radius"
            onChange={this.props.handleSearchChange}
            onKeyPress={
              this.props.handleKeyPress
                ? this.props.handleKeyPress
                : this.handleKeyPress
            }
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : localize(
                    "general_search_placeholder_text",
                    this.props.language
                  )
            }
            autoFocus={this.props.autoFocus}
            defaultValue={this.props.defaultValue}
          />
          {this.props.button ? (
            <button
              id="submitButton"
              className="button icon clear"
              onClick={this.props.handleSubmit}
            >
              <span className="search-icon active nomargin" />
            </button>
          ) : null}
        </div>
      </form>
    );
  }
}

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;

export default SearchBar;
