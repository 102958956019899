import React from "react";
import PropTypes from "prop-types";

import TourModal from "./TourModal";

import urlServices from "services/urlServices";
import { TourContainer } from "../TourContainer";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  language: PropTypes.string
};

class TourModalContainer extends TourContainer {
  /**
   * Constructor - called before the component is mounted
   * @param {} props
   */
  constructor(props) {
    super(props);
    this.state = {
      completedTour: false,
      tourScreens: null,
      apiCallComplete: false
    };

    this.getTourScreens = this.getTourScreens.bind(this);
    this.setCompletedTour = this.setCompletedTour.bind(this);
  }

  /**
   * Invoked immediately after this component is mounted
   */
  componentDidMount() {
    this.getTourScreens();
  }

  /**
   * Returns the URL path the user is currently on
   */
  getCurrentUrlPath() {
    return urlServices.defaultPath();
  }

  /**
   * Handles the event when the user clicks the close button
   * @param {Event} e
   */
  handleCloseClick = e => {
    if (e != null) {
      e.preventDefault();
    }

    this.props.handleCloseModal();
  };

  /**
   * Render the tour popup
   */
  render() {
    return (
      <TourModal
        showModal={this.props.showModal}
        redirectUrl={this.getCurrentUrlPath()}
        tourScreens={this.state.tourScreens}
        completedTour={this.state.completedTour}
        setCompletedTour={this.setCompletedTour}
        apiCallComplete={this.state.apiCallComplete}
        handleCloseModal={this.props.handleCloseModal}
        handleCloseClick={this.handleCloseClick}
        language={this.props.language}
      />
    );
  }
}

TourModalContainer.propTypes = propTypes;

export default TourModalContainer;
