import React, { Component } from "react";
import PropTypes from "prop-types";

import "./LeaderboardPlayer.css";
import {
  PROJECT_USER,
  USER,
  PROJECT_PROFILE,
  PROFILE,
  PROJECT_TEAM,
  TEAM,
} from "App/Routes";
import { ABBREVIATE_LEADERBOARD_POINTS } from "config";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  rank: PropTypes.number.isRequired,
  userRankStatus: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  self: PropTypes.bool.isRequired,
  isTeam: PropTypes.bool.isRequired,
  language: PropTypes.string,
};

class LeaderboardPlayer extends Component {
  getLink() {
    if (this.props.isTeam && this.props.projectId) {
      return PROJECT_TEAM.format(this.props.projectId, this.props.id);
    } else if (this.props.isTeam) {
      return TEAM.format(this.props.id);
    } else if (this.props.userId === this.props.id && this.props.projectId) {
      return PROJECT_PROFILE.format(this.props.projectId);
    } else if (this.props.userId === this.props.id) {
      return PROFILE;
    } else if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.props.id);
    } else {
      return USER.format(this.props.id);
    }
  }

  render() {
    let image = this.props.image;
    let name = this.props.name;
    let link = this.getLink();

    return (
      <Link to={link}>
        <div className="leaderboard-player">
          <div
            className={
              "pure-g bottommargin" +
              (this.props.self ? " self highlightbox" : "")
            }
          >
            <div className="pure-u-3-24 relative">
              <h4 className="leaderboard-rank">{this.props.rank}</h4>
            </div>
            <div className="pure-u-3-24">
              {this.props.rank === 1 && (
                <div id="crown" className="photo-icon-wrap">
                  <span className="leaderboard-champion" />
                </div>
              )}
              <span className="square-image-wrapper">
                <span className="square-image circle">
                  <img src={image} alt={name} />
                </span>
              </span>
              {this.props.userRankStatusImage && (
                <div id="rankStatus" className="photo-icon-wrap">
                  <img
                    className="rank-status"
                    src={this.props.userRankStatusImage}
                    alt={this.props.userRankStatus}
                  />
                </div>
              )}
            </div>
            <div className="pure-u-1-24" />
            <div className="pure-u-10-24 relative">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </div>
            <div className="pure-u-6-24 relative">
              <div className="textright">
                <strong>
                  {ABBREVIATE_LEADERBOARD_POINTS
                    ? this.props.points.abbreviateNumber()
                    : this.props.points}
                </strong>
                <br />
                <span className="smalltext">
                  {localize("points_just_text", this.props.language)}
                </span>
              </div>
            </div>
            <div className="pure-u-1-24" />
          </div>
        </div>
      </Link>
    );
  }
}

LeaderboardPlayer.propTypes = propTypes;

export default LeaderboardPlayer;
