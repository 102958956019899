import React from "react";
import PropTypes from "prop-types";

import deviceServices from "services/deviceServices";
import localize from "lang/localize";
import PostIframe from "../../shared/PostIframe/PostIframe";

const propTypes = {
  sessionKey: PropTypes.string.isRequired,
  challengeReferralLink: PropTypes.string,
  language: PropTypes.string
};

const ChallengeAFriendPage = props => {
  return (
    <div
      className={
        "fullscreen-frame-wrap" +
        (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
      }
    >
      <PostIframe
        title={localize("challenge_referral_text", props.language)}
        hiddenFields={{
          session_key: props.sessionKey
        }}
        url={props.challengeReferralLink}
      ></PostIframe>
    </div>
  );
};

ChallengeAFriendPage.propTypes = propTypes;

export default ChallengeAFriendPage;
