import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ItemPage from "./ItemPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import { GET_ITEM, PURCHASE_ITEM, GET_TOPICS } from "services/api";
import {
  showAlertWithTimeout,
  setProject,
  setButtons,
  setTopbar
} from "actions";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
    projectId: state.projectId,
    user: state.user
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopbar: info => {
      dispatch(setTopbar(info));
    }
  };
};

const propTypes = {
  id: PropTypes.string.isRequired
};

export class ItemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      loggedIn: !!props.user.id,
      topics: null,
      error: null,
      code: null
    };
  }

  componentDidMount() {
    this.getItem();

    /* Retrieve Project information */
    if (this.props.projectId) {
      this.getTopics();
    }
  }

  getItem() {
    getApiGenerator(
      GET_ITEM.format(this.props.id),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ item: false });
        }
      } else {
        this.setState({
          item: res.body
        });

        /*
          Default topbar title is set in Pages.js, under topbarTitle() as "_ITEM".
          "_ITEM" points to button_home_rewards in setTitle() in TopbarContainer.js (default).
          This is further differentiated between Rewards and Achievements during a successful
          API call in ItemContainer.js, in getItem().
        */
        if (res.body.type === "reward") {
          this.props.setTopbar({
            title: "_ACHIEVEMENTS"
          });
        } else if (res.body.type === "store") {
          this.props.setTopbar({
            title: "_ITEM"
          });
        }
      }
    });
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: ""
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleRedeem(id) {
    pushApiGenerator(
      PURCHASE_ITEM,
      {
        type_id: id
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("buy_item_successful_text", this.props.language),
          type: "success"
        });
        this.getItem();
      }
    });
  }

  render() {
    const item = this.state.item;

    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (item) {
      return (
        <ItemPage
          type={item.type}
          id={item.id}
          name={item.name}
          imageSmall={item.imageSmall}
          earned={item.earned}
          description={item.description}
          descriptionAfter={item.descriptionAfter}
          descriptionBefore={item.descriptionBefore}
          unlockedAtFormatted={item.unlockedAtFormatted}
          price={item.price}
          quantityAvailable={item.quantityAvailable}
          ownedQuantity={item.ownedQuantity}
          ownedQuantityLimit={item.ownedQuantityLimit}
          adminOnly={item.adminToAward}
          handleClick={() => this.handleRedeem(item.id)}
          projectId={this.props.projectId}
          loggedIn={this.state.loggedIn}
        />
      );
    } else if (item === false) {
      return (
        <GenericErrorPage
          message={localize("unavailable_item", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

ItemContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);
