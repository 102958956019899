import React, { Fragment } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

import mentionsClass from "../mentions.module.css";
import Post from "../Post/Post";
import DiscussionComment from "./DiscussionComment";
import { MentionsInput, Mention } from "react-mentions";
import MultipleImageInput from "components/shared/Inputs/MultipleImageInput";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";

import localize from "lang/localize";
import getApiGenerator from "services/getApiGenerator";
import { SEARCH_PROJECT } from "services/api";
import { MENTIONS_LIMIT } from "config";
import Loading from "components/shared/Loading";

import "./DiscussionComment.css";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  more: PropTypes.bool,
  showInput: PropTypes.bool,
  input: PropTypes.string,
  handleMore: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.string,
  userId: PropTypes.number,
  // character limit
  charLimit: PropTypes.number,
  board: PropTypes.object,
  post: PropTypes.object,
  handleCommentLike: PropTypes.func,
  handleCommentBookmark: PropTypes.func,
  postUser: PropTypes.object,
  removeComment: PropTypes.func,
  removePost: PropTypes.func,
  showAlertWithTimeout: PropTypes.func,
  fileUploadLimit: PropTypes.number,
  fileTotalLimit: PropTypes.number,
  handlePostLike: PropTypes.func,
  handlePostBookmark: PropTypes.func,
  showLoginDialog: PropTypes.bool.isRequired,
  handleOpenLoginDialog: PropTypes.func.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
  // image dialogs
  showImageDialog: PropTypes.bool.isRequired,
  handleOpenImageDialog: PropTypes.func.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  currentImage: PropTypes.string,
};

const DiscussionComments = (props) => {
  if (
    props.comments &&
    Array.isArray(props.comments) &&
    props.comments.length > 0
  ) {
    return (
      <div className="topmargin-30">
        {props.comments.map((comment, index) => (
          <Fragment key={comment.id}>
            <DiscussionComment
              id={comment.id}
              userId={props.userId}
              projectId={props.projectId}
              commentUserName={comment.userName}
              commentUserId={comment.userId}
              commentUserImage={comment.userImage}
              userRankStatusImage={comment.userRankStatusImage}
              userRankStatus={comment.userRankStatus}
              commentTitle={comment.commentTitle}
              commentContent={comment.commentContent}
              commentContentAttributed={comment.commentContentAttributed}
              commentEmbeds={comment.commentEmbeds}
              commentVoteCount={comment.commentVoteCount}
              commentCommentCount={comment.commentCommentCount}
              commentBookmarkCount={comment.commentBookmarkCount}
              createdAtFormatted={comment.createdAtFormatted}
              updatedAtFormatted={comment.updatedAtFormatted}
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt}
              boardId={comment.boardId}
              board={props.board}
              post={props.post}
              type={props.type}
              removeComment={props.removeComment}
              isLiked={comment.commentVoted}
              handleLike={props.handleLike}
              isBookmarked={comment.commentBookmarked}
              handleBookmark={props.handleBookmark}
              language={props.language}
              showLoginDialog={props.showLoginDialog}
              handleOpenLoginDialog={props.handleOpenLoginDialog}
              handleCloseLoginDialog={props.handleCloseLoginDialog}
              // image dialogs
              showImageDialog={props.showImageDialog}
              handleOpenImageDialog={props.handleOpenImageDialog}
              handleCloseImageDialog={props.handleCloseImageDialog}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <p className="topmargin-30 text-align-center">
        {localize("empty_comment_table_text", props.language)}
      </p>
    );
  }
};

const CommentInput = (props) => {
  const [mentionsUserList, setMentionsUserList] = React.useState([]);
  const [storedUserList, setStoredUserList] = React.useState([]);

  // TODO: Move to API2 (may need services/api2.js)
  const getPlayers = debounce((search, callback) => {
    getApiGenerator(
      SEARCH_PROJECT.format(props.projectId),
      {
        type: "user",
        keywords: search,
      },
      props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          return [];
        }
      } else {
        const formattedPlayers = res.body.data.map((user) => ({
          id: user.id,
          display: user.userName ? user.userName : " ",
          userImage: user.userImage,
        }));
        setMentionsUserList(formattedPlayers);
        callback(formattedPlayers);
      }
    });
  }, 500);

  const onAdd = (search) => {
    const foundUser = mentionsUserList.find(
      (user) => user.id === parseInt(search),
    );
    setStoredUserList([...storedUserList, foundUser]);
  };

  // checks input against regex to count accurate number of mentions
  const checkInput = (event) => {
    const regexp = /(\[uid:[0-9]+\])/gm;
    const transformedInput = event.target.value.replace(
      /(\[uid:[0-9]+\])/gm,
      "",
    );
    let mentionsInInput = event.target.value.match(regexp);
    if (mentionsInInput !== null) {
      props.setNumberOfMentions(event.target.value.match(regexp).length);
    } else {
      props.setNumberOfMentions(0);
    }
    if (
      event.target.value.trim() === "" ||
      props.numberOfMentions > MENTIONS_LIMIT ||
      (props.charLimit !== 0 && transformedInput.length > props.charLimit)
    ) {
      props.setContentCheck(false);
    } else {
      props.setContentCheck(true);
    }
    props.handleInputChange(event);
  };

  if (!!props.showInput) {
    return (
      <div id="commentInput" className="bottompadding-5">
        <div className="pure-u-1 relative">
          <MentionsInput
            value={props.input}
            placeholder={localize("comment_placeholder_text", props.language)}
            onChange={checkInput}
            classNames={mentionsClass}
            allowSpaceInQuery={true}
            a11ySuggestionsListLabel="Suggested mentions"
          >
            <Mention
              trigger="@"
              data={getPlayers}
              renderSuggestion={(suggestion, search, highlightedDisplay) => (
                <div className="flex align-items-center">
                  <div className="rightmargin-20 suggestion-image-container">
                    <img
                      className="suggestion-image"
                      src={suggestion.userImage}
                      alt="pfp"
                    />
                  </div>
                  {suggestion.display}
                </div>
              )}
              displayTransform={(id) => {
                const foundUser = mentionsUserList.find(
                  (user) => user.id === parseInt(id),
                );
                const storedUser = storedUserList.find(
                  (user) => user.id === parseInt(id),
                );
                return foundUser
                  ? `@${foundUser.display}`
                  : `@${storedUser.display}`;
              }}
              onAdd={onAdd}
              markup="[uid:__id__]"
              className={mentionsClass.mentions__mention}
            />
          </MentionsInput>
          <CharacterCounter charLimit={props.charLimit} input={props.input} />
        </div>
        {props.numberOfMentions > MENTIONS_LIMIT && (
          <div className="pure-u-1">
            <span className="text-red">
              {localize("alert_exceeded_mentions_limit", props.language).format(
                MENTIONS_LIMIT,
              )}
            </span>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

const UploadInput = (props) => {
  const onImageChange = (images) => {
    if (images) {
      Array.from(images).forEach((image) =>
        props.setImages(!!props.images ? [...props.images, image] : [image]),
      );
    }
  };

  const onImageReset = () => {
    // this is used for error handling, but not for form submit
    props.setImages([]);
  };

  if (!!!props.showInput) {
    return null;
  }

  return (
    <div id="fileInput" className="discussions-post-image-upload">
      <div className="pure-u-1-1">
        <MultipleImageInput
          imagename={props.imageName} // no idea what this is
          images={props.images} // for resetting images after submit
          onDrop={onImageChange}
          resetFiles={onImageReset}
          showAlertWithTimeout={props.showAlertWithTimeout}
          disabled={!props.isLoggedIn}
          language={props.language}
          fileUploadLimit={props.fileUploadLimit}
          fileTotalLimit={props.fileTotalLimit}
        />
      </div>
    </div>
  );
};

/**
 * Component: Discussion Comments Page
 *
 * @param {*} props
 * @returns
 */
const DiscussionCommentsPage = (props) => {
  const [contentCheck, setContentCheck] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [imageName, setImageName] = React.useState(null);
  const [numberOfMentions, setNumberOfMentions] = React.useState(0);

  /**
   * Reset mentions count
   */
  const resetNumberOfMentions = () => {
    setNumberOfMentions(0);
  };

  /**
   * Reset image input
   */
  const resetImages = () => {
    setImages(null);
    setImageName(null);
  };

  /**
   * Handle the submit button for the comments post form
   *
   * @param {*} event - The submit event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(images);
    resetNumberOfMentions();
    resetImages(); // this does not update the preview!
  };

  /**
   * Render prompt for non-logged in users
   */
  const renderNotLoggedIn = (props) => {
    if (!props.sessionKey) {
      return (
        <React.Fragment>
          <div className="discussions-reply-container">
            <NotLoggedInContainer
              showLogin={false}
              message={localize("require_login_post_reply", props.language)}
              language={props.language}
            />
          </div>
        </React.Fragment>
      );
    }
  };

  /**
   * Render form to post a reply
   */
  const renderPostReplyForm = (props) => {
    if (props.sessionKey) {
      return (
        <div className="discussions-components discussions-reply-container">
          <h5>
            <i className="fa fa-pencil-alt rightmargin-10"></i>
            {localize("discussion_title_post_reply", props.language)}
          </h5>
          <div className="pure-form flex flex-direction-column">
            <CommentInput
              projectId={props.projectId}
              sessionKey={props.sessionKey}
              showInput={props.showInput}
              input={props.input}
              handleInputChange={props.handleInputChange}
              language={props.language}
              charLimit={props.charLimit}
              setContentCheck={setContentCheck}
              numberOfMentions={numberOfMentions}
              setNumberOfMentions={setNumberOfMentions}
            />
            <UploadInput
              setImageName={setImageName}
              imageName={imageName}
              isLoggedIn={!!props.userId}
              showInput={props.showInput}
              showAlertWithTimeout={props.showAlertWithTimeout}
              setImages={setImages}
              images={images}
              fileUploadLimit={props.fileUploadLimit}
              fileTotalLimit={props.fileTotalLimit}
              language={props.language}
            />
            {!!props.showInput && (
              <div className="discussions-post-submit">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!contentCheck}
                  className={"button nomargin" + (contentCheck ? " cta" : "")}
                >
                  {localize("button_submit", props.language)}
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div
        className="discussions-components discussions-replies-container container verticalpadding bottompadding-floating-extended"
        id="post-comments"
      >
        <div className="innerblock">
          <Post
            id={props.post.id}
            userId={props.userId}
            projectId={props.projectId}
            postUserName={props.post.userName}
            postUserId={props.post.userId}
            postUserImage={props.post.userImage}
            userRankStatusImage={props.userRankStatusImage}
            userRankStatus={props.userRankStatus}
            postTitle={props.post.postTitle}
            postContent={props.post.postContent}
            postContentAttributed={props.post.postContentAttributed}
            postEmbeds={props.post.postEmbeds}
            postVoteCount={props.post.postVoteCount}
            postCommentCount={props.post.postCommentCount}
            postBookmarkCount={props.post.postBookmarkCount}
            createdAtFormatted={props.post.createdAtFormatted}
            updatedAtFormatted={props.post.updatedAtFormatted}
            createdAt={props.post.createdAt}
            updatedAt={props.post.updatedAt}
            boardId={props.post.boardId}
            board={props.board}
            removePost={props.removePost}
            postVoted={props.post.postVoted}
            handleLike={props.handlePostLike}
            postBookmarked={props.post.postBookmarked}
            handleBookmark={props.handlePostBookmark}
            fromComments={true}
            showEmbeds={true}
            language={props.language}
            singlePost={true}
            postPinned={false}
            // login dialogs
            showBookmarkLoginDialog={props.showBookmarkLoginDialog}
            handleOpenBookmarkLoginDialog={props.handleOpenBookmarkLoginDialog}
            handleCloseBookmarkLoginDialog={
              props.handleCloseBookmarkLoginDialog
            }
            showLikeLoginDialog={props.showLikeLoginDialog}
            handleOpenLikeLoginDialog={props.handleOpenLikeLoginDialog}
            handleCloseLikeLoginDialog={props.handleCloseLikeLoginDialog}
            // Images for post - with popup support
            handleOpenImageDialog={props.handleOpenImageDialog}
            showImageDialog={props.showImageDialog}
            handleCloseImageDialog={props.handleCloseImageDialog}
            currentImage={props.currentImage}
          />
          {renderPostReplyForm(props)}
          {renderNotLoggedIn(props)}
          <DiscussionComments
            post={props.post}
            board={props.board}
            comments={props.comments}
            type={props.type}
            userId={props.userId}
            projectId={props.projectId}
            removeComment={props.removeComment}
            handleLike={props.handleCommentLike}
            handleBookmark={props.handleCommentBookmark}
            language={props.language}
            // login dialogs
            showBookmarkLoginDialog={props.showBookmarkLoginDialog}
            handleOpenBookmarkLoginDialog={props.handleOpenBookmarkLoginDialog}
            handleCloseBookmarkLoginDialog={
              props.handleCloseBookmarkLoginDialog
            }
            showLikeLoginDialog={props.showLikeLoginDialog}
            handleOpenLikeLoginDialog={props.handleOpenLikeLoginDialog}
            handleCloseLikeLoginDialog={props.handleCloseLikeLoginDialog}
            // Images for replies - with popup support
            handleOpenImageDialog={props.handleOpenImageDialog}
            showImageDialog={props.showImageDialog}
            handleCloseImageDialog={props.handleCloseImageDialog}
            currentImage={props.currentImage}
          />
          {props.isLoadingMore && <Loading />}
        </div>
      </div>
    </Fragment>
  );
};

DiscussionCommentsPage.propTypes = propTypes;

export default DiscussionCommentsPage;
