import React, { Component } from "react";
import { connect } from "react-redux";

import RegisterPage from "./RegisterPage";

import loginServices from "services/loginServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export class RegisterContainer extends Component {
  componentDidMount() {
    if (this.props.sessionKey && this.props.userId) {
      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        null,
        false
      );
    }
  }

  render() {
    return <RegisterPage language={this.props.language} />;
  }
}

export default connect(mapStateToProps)(RegisterContainer);
