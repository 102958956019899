import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import ResetActivityDialogContainer from "components/shared/Dialogs/ResetActivityDialog/ResetActivityDialogContainer";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";
import Link from "components/shared/Link/Link";
import placeholder_topic from "images/core/placeholders/placeholder_topic.jpg";
import "./TopicCard.css";

import { TOPIC, PROJECT_HOME } from "App/Routes";
import { SINGLE_PROJECT_APP } from "config";
import localize from "lang/localize";

import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import Dotdotdot from "react-dotdotdot";
import urlParse from "library/js/url";

const propTypes = {
  sessionKey: PropTypes.string,
  projectId: PropTypes.number,
  isMobile: PropTypes.bool,
  allowReset: PropTypes.bool,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  totalChallenges: PropTypes.number.isRequired,
  completedChallenges: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  started: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  language: PropTypes.string,
};

const defaultProps = {
  isMobile: false,
  allowReset: false,
  img: placeholder_topic,
  projectId: null,
};

class TopicCard extends Component {
  constructor() {
    super();
    this.state = {
      showResetActivityDialog: false,
      collapsibleState: false,
    };

    this.handleOpenResetActivityDialog =
      this.handleOpenResetActivityDialog.bind(this);
    this.handleCloseResetActivityDialog =
      this.handleCloseResetActivityDialog.bind(this);

    this.handleCollapsible = this.handleCollapsible.bind(this);
    this.handleCollapsibleMobile = this.handleCollapsibleMobile.bind(this);
  }

  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  /**
   * Get text for Topic card action button
   * @returns text
   */
  getActionButtonText() {
    if (this.props.showcase) {
      return localize("showcase_try_now", this.props.language);
    } else if (this.props.completed && this.props.totalChallenges !== 0) {
      return localize("button_quest_completed", this.props.language);
    } else if (!this.props.started) {
      return localize("button_quest_start", this.props.language);
    } else {
      return localize("button_quest_continue", this.props.language);
    }
  }

  /**
   * Get classnames for Topic card action button
   * @returns classes
   */
  getActionButtonClass() {
    if (this.props.completed && this.props.totalChallenges !== 0) {
      // Dark grey button for completed topics
      return "button active";
    } else {
      // CTA button for topics that require action
      return "button cta";
    }
  }

  renderActionButton() {
    if (this.props.locked) {
      return (
        <div
          className={
            "topic-card-action " +
            (this.state.collapsibleState ? "flex justify-content-center" : "")
          }
        >
          <button className="button inactive locked">
            {localize("button_quest_locked", this.props.language)}
          </button>
        </div>
      );
    } else if (this.props.expired) {
      return (
        <div
          className={
            "topic-card-action " +
            (this.state.collapsibleState ? "flex justify-content-center" : "")
          }
        >
          <button className="button inactive expired">
            {localize("button_quest_expired", this.props.language)}
          </button>
        </div>
      );
    } else if (this.props.showcase) {
      if (SINGLE_PROJECT_APP) {
        return (
          <a
            className={
              "topic-card-action " +
              (this.state.collapsibleState ? "flex justify-content-center" : "")
            }
            href={"https://app.gametize.com/project/" + this.props.id}
            target="_blank"
            rel="noreferrer noopener"
          >
            <button className={this.getActionButtonClass()}>
              {this.getActionButtonText()}
            </button>
          </a>
        );
      } else {
        return (
          <Link
            className={
              "topic-card-action " +
              (this.state.collapsibleState ? "flex justify-content-center" : "")
            }
            to={PROJECT_HOME.format(this.props.id)}
          >
            <button className={this.getActionButtonClass()}>
              {this.getActionButtonText()}
            </button>
          </Link>
        );
      }
    } else {
      return (
        <Link
          className={
            "topic-card-action " +
            (this.state.collapsibleState ? "flex justify-content-center" : "")
          }
          to={TOPIC.format(this.props.id)}
        >
          <button className={this.getActionButtonClass()}>
            {this.getActionButtonText()}
          </button>
        </Link>
      );
    }
  }

  renderDropdownButton() {
    if (this.props.allowReset && this.props.sessionKey) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
              this.handleOpenResetActivityDialog();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem eventKey={1} onSelect={(eventKey, event) => {}}>
                {localize("reset_topic", this.props.language)}
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  handleOpenResetActivityDialog() {
    this.setState({
      showResetActivityDialog: true,
    });
  }

  handleCloseResetActivityDialog() {
    this.setState({
      showResetActivityDialog: false,
    });
  }

  renderResetActivityDialog() {
    return (
      <ResetActivityDialogContainer
        context="project"
        showDialog={this.state.showResetActivityDialog}
        handleCloseDialog={this.handleCloseResetActivityDialog}
        topicTitle={this.props.title || ""}
        topicId={this.props.id}
        projectId={this.props.projectId}
      />
    );
  }

  handleCollapsible() {
    this.setState((prevState) => ({
      collapsibleState: !prevState.collapsibleState,
    }));
  }

  handleCollapsibleMobile() {
    this.handleCollapsible();
  }

  render() {
    let img = this.props.img;
    let title = this.props.title;

    let anchoredTopicDescription = this.addAnchors(this.props.description);

    let topicCard = (
      <div className="pure-g topic-card card-link-wrapper">
        {/* Topic Card Link */}
        {!this.props.locked && !this.props.expired ? (
          <Link
            className="card-link-overlay"
            to={
              this.props.showcase
                ? PROJECT_HOME.format(this.props.id)
                : TOPIC.format(this.props.id)
            }
          />
        ) : (
          ""
        )}
        <button
          className={
            this.state.collapsibleState
              ? "fas fa-caret-up cursor-pointer topic-card-toggle"
              : "fas fa-caret-down cursor-pointer topic-card-toggle"
          }
          onClick={this.handleCollapsible}
        />
        <div
          className={
            this.state.collapsibleState
              ? "pure-u-sm-11-24"
              : "pure-u-sm-9-24 pure-u-xl-6-24"
          }
        >
          {!this.props.locked && !this.props.expired ? (
            <div className="square-image-wrapper">
              <div className="square-image">
                <img
                  src={img}
                  alt="test"
                  className={
                    this.props.showcase
                      ? "top-left-rounded bottom-left-rounded"
                      : "top-left-rounded"
                  }
                />
              </div>
            </div>
          ) : (
            <div className="square-image-wrapper">
              <div className="square-image">
                <img
                  src={img}
                  alt="test"
                  className={
                    this.props.showcase
                      ? "top-left-rounded bottom-left-rounded"
                      : "top-left-rounded"
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={
            this.state.collapsibleState
              ? "pure-u-sm-13-24 relative " +
                (this.props.showcase ? "showcase" : "")
              : "pure-u-sm-15-24 pure-u-xl-18-24 relative " +
                (this.props.showcase ? "showcase" : "")
          }
        >
          <div className="topic-card-desc-wrapper">
            <div className="flex justify-content-space-between">
              <div className="flex topic-card-title">
                {this.state.collapsibleState ? (
                  <strong dangerouslySetInnerHTML={{ __html: title }} />
                ) : (
                  <Dotdotdot clamp={1}>
                    <strong dangerouslySetInnerHTML={{ __html: title }} />
                  </Dotdotdot>
                )}
                {this.props.showcase && (
                  <span className="sponsored">Sponsored</span>
                )}
              </div>
            </div>
            <p
              className={
                "description-content " +
                (this.state.collapsibleState
                  ? "topic-card-desc-expanded"
                  : "topic-card-desc text-truncated")
              }
              dangerouslySetInnerHTML={{ __html: anchoredTopicDescription }}
            />
            {this.renderActionButton()}
          </div>
        </div>
        {!this.props.showcase && (
          <div className="pure-u-1-1 topic-card-progress">
            <ProgressBar
              completed={this.props.completedChallenges}
              total={this.props.totalChallenges}
              language={this.props.language}
              hidetext={this.state.collapsibleState ? "false" : "true"}
            />
          </div>
        )}
      </div>
    );

    let topicCardMobile = (
      <div className="horizontalpadding-10">
        <div className="pure-g topic-card card-link-wrapper">
          {/* Topic Card Link */}
          {!this.props.locked && !this.props.expired ? (
            <Link
              className="card-link-overlay"
              to={
                this.props.showcase
                  ? PROJECT_HOME.format(this.props.id)
                  : TOPIC.format(this.props.id)
              }
            />
          ) : (
            ""
          )}
          <button
            className={
              this.state.collapsibleState
                ? "fas fa-caret-up cursor-pointer topic-card-toggle"
                : "fas fa-caret-down cursor-pointer topic-card-toggle"
            }
            onClick={this.handleCollapsible}
          />
          <div
            className={
              this.state.collapsibleState ? "pure-u-1-1 " : "pure-u-8-24 "
            }
          >
            {this.state.collapsibleState ? (
              <div
                className={
                  "flex justify-content-space-between verticalpadding-10 horizontalpadding-10 " +
                  (this.props.showcase ? "mobile-showcase expanded-title" : "")
                }
              >
                <div className="flex align-items-center topic-card-title">
                  <strong dangerouslySetInnerHTML={{ __html: title }} />
                  {this.props.showcase && (
                    <span className="sponsored">Sponsored</span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {!this.props.locked && !this.props.expired ? (
              <div className="square-image-wrapper">
                <div className="square-image">
                  <img
                    src={img}
                    alt="test"
                    className={
                      this.state.collapsibleState ? "" : "top-left-rounded"
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="square-image-wrapper">
                <div className="square-image">
                  <img
                    src={img}
                    alt="test"
                    className={
                      this.state.collapsibleState ? "" : "top-left-rounded"
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className={
              this.state.collapsibleState
                ? "pure-u-1-1 " +
                  (this.props.showcase ? "mobile-showcase expanded-desc" : "")
                : "pure-u-16-24 " +
                  (this.props.showcase ? "mobile-showcase contract" : "")
            }
          >
            <div className="topic-card-desc-wrapper">
              <div className="flex justify-content-space-between">
                <div className="flex topic-card-title">
                  {this.state.collapsibleState ? (
                    <div />
                  ) : (
                    <Dotdotdot clamp={1}>
                      <strong dangerouslySetInnerHTML={{ __html: title }} />
                    </Dotdotdot>
                  )}
                  {this.props.showcase && !this.state.collapsibleState && (
                    <span className="sponsored">Sponsored</span>
                  )}
                </div>
              </div>
              <p
                className={
                  this.state.collapsibleState
                    ? "topic-card-desc-expanded"
                    : "topic-card-desc text-truncated"
                }
                dangerouslySetInnerHTML={{ __html: anchoredTopicDescription }}
              />
              {this.renderActionButton()}
            </div>
          </div>
          {!this.props.showcase && (
            <div className="pure-u-1-1 topic-card-progress">
              <ProgressBar
                completed={this.props.completedChallenges}
                total={this.props.totalChallenges}
                language={this.props.language}
                hidetext={this.state.collapsibleState ? "false" : "true"}
              />
            </div>
          )}
        </div>
      </div>
    );

    /*
      Use id only for desktop view to prevent duplicate id:
      - Mobile and desktop topic cards are rendered
      in the Project component (with either being on display: none
      depending on resolution.
    */
    if (!this.props.isMobile) {
      return (
        <Fragment>
          {/* Empty span just so we can stack scrollable anchors */}
          <div id={"resettedtopic" + this.props.id}>
            <span></span>
          </div>
          <div id={"topic" + this.props.id}>{topicCard}</div>
          {this.renderResetActivityDialog()}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div id={"topicMobile" + this.props.id}>
            {this.props.isMobile ? topicCardMobile : topicCard}
          </div>
          {this.renderResetActivityDialog()}
        </Fragment>
      );
    }
  }
}

TopicCard.propTypes = propTypes;
TopicCard.defaultProps = defaultProps;

export default TopicCard;
