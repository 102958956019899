import React, { Component } from "react";
import PropTypes from "prop-types";

import "./AchievementsSlider.css";

import Slider from "react-slick";
import urlParse from "library/js/url";

const propTypes = {
  achievements: PropTypes.array.isRequired,
};

class AchievementsSlider extends Component {
  renderAchievementTitle(achievement) {
    let name = achievement ? achievement.name : "";

    if (achievement.name && name.trim() !== "") {
      return <h5 className="achievement-title text-align-center">{name}</h5>;
    } else {
      return null;
    }
  }

  renderAchievementImg(achievement) {
    let imageLarge = achievement ? achievement.imageLarge : "";
    let name = achievement ? achievement.name : "";

    if (achievement.imageLarge) {
      return (
        <div className="achievement-image-container">
          <img src={imageLarge} alt={name} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Add anchor text links
   *
   * @param {string} text - Text
   */
  addAnchors(text) {
    // add anchor tags to links
    if (text) {
    }
    return urlParse(text, true);
  }

  renderAchievementText(achievement) {
    let descriptionAfter = achievement ? achievement.descriptionAfter : "";
    let descriptionAfterAnchored = this.addAnchors(descriptionAfter);
    if (achievement.descriptionAfter && descriptionAfter.trim() !== "") {
      return (
        <div>
          <p
            className="achievement-description responsive-img-description text-align-center"
            dangerouslySetInnerHTML={{ __html: descriptionAfterAnchored }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      swipeToSlide: this.props.achievements.length > 1,
      swipe: this.props.achievements.length > 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      className:
        this.props.achievements.length > 1
          ? "achievementsSlider"
          : "achievementsSlider oneItem",
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      adaptiveHeight: false,
    };
    return (
      <Slider {...settings}>
        {this.props.achievements.map((achievement, index) => {
          return (
            <div key={index}>
              {this.renderAchievementTitle(achievement)}
              {this.renderAchievementImg(achievement)}
              {this.renderAchievementText(achievement)}
            </div>
          );
        })}
      </Slider>
    );
  }
}

function NextArrow(props) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

AchievementsSlider.propTypes = propTypes;

export default AchievementsSlider;
