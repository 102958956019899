import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import { QrReader } from "react-qr-reader";

import "./QRModal.css";

import localize from "lang/localize";

const propTypes = {
  showQRModal: PropTypes.bool.isRequired,
  handleCloseQRModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class QRModal extends Component {
  constructor() {
    super();
    this.imgRef = React.createRef();
    this.state = {
      loading: true,
      legacyMode: false,
      result: "No result",
      error: false,
      delay: 500,
      errorMessage: "",
      errorName: "",
      errorConstraint: ""
    };
  }

  handleScan(result) {
    if (result) {
      this.props.handleSubmit(result);
    }
  }

  handleError(err) {
    this.setState({
      errorConstraint: err && err.constraint ? err.constraint : "",
      errorMessage: err && err.message ? err.message : "",
      errorName: err && err.name ? err.name : "",
      legacyMode: true
    });
  }

  /* istanbul ignore next : cant get access to refs in unit test */
  handleImgSubmit() {
    this.imgRef.openImageDialog();
  }

  renderErrorMessage() {
    /* error message and name exist */
    if (
      this.state.errorMessage &&
      this.state.errorMessage !== "" &&
      this.state.errorName &&
      this.state.errorName !== ""
    ) {
      return (
        <div>
          {this.state.errorConstraint && (
            <p className="qr-error-message nomargin">
              {localize(
                "internet_error_with_desc_text",
                this.props.language
              ).format(this.state.errorConstraint)}
            </p>
          )}
          <p className="qr-error-message">
            {!this.state.errorConstraint
              ? localize(
                  "internet_error_with_desc_text",
                  this.props.language
                ).format(
                  this.state.errorName
                    .concat(" - ")
                    .concat(this.state.errorMessage)
                )
              : "" +
                this.state.errorName
                  .concat(" - ")
                  .concat(this.state.errorMessage)}
          </p>
        </div>
      );
    } else if (this.state.errorMessage && this.state.errorMessage !== "") {
      /* only error message exists */
      return (
        <div>
          {this.state.errorConstraint && (
            <p className="qr-error-message nomargin">
              {localize(
                "internet_error_with_desc_text",
                this.props.language
              ).format(this.state.errorConstraint)}
            </p>
          )}
          <p className="qr-error-message">
            {!this.state.errorConstraint
              ? localize(
                  "internet_error_with_desc_text",
                  this.props.language
                ).format(this.state.errorMessage)
              : "" + this.state.errorMessage}
          </p>
        </div>
      );
    } else if (this.state.errorName && this.state.errorName !== "") {
      /* only error name exists */
      return (
        <div>
          {this.state.errorConstraint && (
            <p className="qr-error-message nomargin">
              {localize(
                "internet_error_with_desc_text",
                this.props.language
              ).format(this.state.errorConstraint)}
            </p>
          )}
          <p className="qr-error-message">
            {!this.state.errorConstraint
              ? localize(
                  "internet_error_with_desc_text",
                  this.props.language
                ).format(this.state.errorName)
              : "" + this.state.errorName}
          </p>
        </div>
      );
    } else {
      /* none exists */
      return null;
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showQRModal}
        contentLabel={localize("challenge_type_qr_text", this.props.language)}
        onRequestClose={this.props.handleCloseQRModal}
        shouldCloseOnOverlayClick={false}
        className="dialog qr-modal"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("challenge_type_qr_text", this.props.language)}
            </h5>
            <button
              id="closeQRModalButton"
              onClick={() => {
                this.props.handleCloseQRModal();
              }}
              className="close-button-container"
            >
              <i className="fa close-button" aria-hidden="true" />
            </button>
          </div>
          <div className="dialog-body">
            <div>
              {this.state.legacyMode ? (
                <div>
                  {this.renderErrorMessage()}
                  <p>
                    {localize(
                      "qr_reader_not_supported_text",
                      this.props.language
                    )}
                  </p>
                  <p className="textcenter">
                    <button
                      className="button inline cta topmargin-10"
                      onClick={this.handleImgSubmit.bind(this)}
                    >
                      {localize(
                        "button_claim_qr_submit_photo",
                        this.props.language
                      )}
                    </button>
                  </p>
                </div>
              ) : null}
              <QrReader
                onError={this.handleError.bind(this)}
                onResult={this.handleScan.bind(this)}
                scanDelay={this.state.delay}
                legacyMode={this.state.legacyMode}
                innerRef={this.imgRef}
                constraints={{
                  facingMode: "environment"
                }}
                style={{ width: "100%", margin: "auto" }}
              />
              <br />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

QRModal.propTypes = propTypes;

export default QRModal;
