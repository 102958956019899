// eslint-disable-next-line
String.prototype.format = function() {
  let content = this;
  // const varRegexp = /\{\d\}/g;
  // let count = 0;
  // while (varRegexp.exec(content) !== null) {
  //   count++;
  // }
  // if (count !== arguments.length) {
  //   throw new Error("Incorrect number of arguments supplied");
  // }
  for (let i = 0; i < arguments.length; i++) {
    let replacement = "{" + i + "}";
    content = content.replace(replacement, arguments[i]);
  }
  return content;
};
