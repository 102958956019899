import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import { REGISTER_PAGE_LINK } from "config";
import deviceServices from "services/deviceServices";
import localize from "lang/localize";

const propTypes = {
  language: PropTypes.string
};

class LoginPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    // Set page identifier class to .component-container
    let mainComponent = ReactDOM.findDOMNode(this).parentNode;
    if (!mainComponent.classList.contains("registerPage")) {
      mainComponent.className += " registerPage";
    }

    // Add other page classes to body DOM
    let bodyDOM = document.body; // <body> tag
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    // Remove page identifier class from .component-container
    let mainComponent = ReactDOM.findDOMNode(this).parentNode;
    if (mainComponent.classList.contains("registerPage")) {
      mainComponent.classList.remove("registerPage");
    }

    // Remove other page classes from body DOM
    let bodyDOM = document.body;
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  render() {
    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <iframe
          src={REGISTER_PAGE_LINK}
          title={localize("login_signup_web_view_title", this.props.language)}
          frameBorder="0"
          className="fullscreen-frame"
        />
      </div>
    );
  }
}

LoginPage.propTypes = propTypes;

export default LoginPage;
