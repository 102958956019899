import React, { Component } from "react";
import PropTypes from "prop-types";

import deviceServices from "services/deviceServices";
import PostIframe from "../shared/PostIframe/PostIframe";

const propTypes = {
  url: PropTypes.string.isRequired,
  sessionKey: PropTypes.string.isRequired
};

class FramePage extends Component {
  render() {
    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          title={this.props.url}
          hiddenFields={{
            session_key: this.props.sessionKey
          }}
          url={this.props.url}
        ></PostIframe>
      </div>
    );
  }
}

FramePage.propTypes = propTypes;

export default FramePage;
