import React, { Component } from "react";
import PropTypes from "prop-types";

import Redeemed from "./Redeemed";

const propTypes = {
  id: PropTypes.number.isRequired,
  transactionId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  descriptionAfterRedeem: PropTypes.string,
  descriptionAfterCollect: PropTypes.string,
  option: PropTypes.string,
  usedAtFormatted: PropTypes.string,
  collectionCancelEnabled: PropTypes.bool,
  code: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  handleCancelAction: PropTypes.func,
  language: PropTypes.string,
  projectId: PropTypes.number,
  showAlertWithTimeout: PropTypes.func.isRequired,
};

class RedeemedContainer extends Component {
  constructor() {
    super();
    this.state = {
      showCollectRedeemedDialog: false,
      showConfirmCancelRedeemDialog: false,
    };

    // Collect Reward Dialog
    this.handleOpenCollectRedeemedDialog =
      this.handleOpenCollectRedeemedDialog.bind(this);
    this.handleCloseCollectRedeemedDialog =
      this.handleCloseCollectRedeemedDialog.bind(this);

    // Confirmation Dialog: Cancel Redemption
    this.handleOpenConfirmCancelRedeemDialog =
      this.handleOpenConfirmCancelRedeemDialog.bind(this);
    this.handleCloseConfirmCancelRedeemDialog =
      this.handleCloseConfirmCancelRedeemDialog.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.usedAtFormatted !== this.props.usedAtFormatted) {
      this.setState({
        showCollectRedeemedDialog: false,
      });
    }
  }

  /**
   * Open the "Collect Reward" Dialog
   */
  handleOpenCollectRedeemedDialog() {
    this.setState({
      showCollectRedeemedDialog: true,
    });
  }

  /**
   * Close the "Collect Reward" Dialog
   */
  handleCloseCollectRedeemedDialog() {
    this.setState({
      showCollectRedeemedDialog: false,
    });
  }

  /**
   * Open the "Confirm Cancel Reward Redemption" Dialog
   */
  handleOpenConfirmCancelRedeemDialog() {
    this.setState({
      showConfirmCancelRedeemDialog: true,
    });
  }

  /**
   * Close the "Confirm Cancel Reward Redemption" Dialog
   */
  handleCloseConfirmCancelRedeemDialog() {
    this.setState({
      showConfirmCancelRedeemDialog: false,
    });
  }

  /**
   * Render the Rewards component (State: Redeemed)
   */
  render() {
    return (
      <Redeemed
        id={this.props.id}
        transactionId={this.props.transactionId}
        title={this.props.title}
        description={this.props.description}
        img={this.props.img}
        descriptionAfterRedeem={this.props.descriptionAfterRedeem}
        descriptionAfterCollect={this.props.descriptionAfterCollect}
        option={this.props.option}
        usedAtFormatted={this.props.usedAtFormatted}
        collectionCancelEnabled={this.props.collectionCancelEnabled}
        code={this.props.code}
        type={this.props.type}
        handleClick={this.props.handleClick}
        handleCancelAction={this.props.handleCancelAction}
        language={this.props.language}
        projectId={this.props.projectId}
        showAlertWithTimeout={this.props.showAlertWithTimeout}
        // Collect Reward Dialog
        showCollectRedeemedDialog={this.state.showCollectRedeemedDialog}
        handleOpenCollectRedeemedDialog={this.handleOpenCollectRedeemedDialog}
        handleCloseCollectRedeemedDialog={this.handleCloseCollectRedeemedDialog}
        // Confirmation Dialog: Cancel Redemption
        showConfirmCancelRedeemDialog={this.state.showConfirmCancelRedeemDialog}
        handleOpenConfirmCancelRedeemDialog={
          this.handleOpenConfirmCancelRedeemDialog
        }
        handleCloseConfirmCancelRedeemDialog={
          this.handleCloseConfirmCancelRedeemDialog
        }
      />
    );
  }
}

RedeemedContainer.propTypes = propTypes;

export default RedeemedContainer;
