import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import AchievementsSlider from "../AchievementsSlider/AchievementsSlider.js";

import localize from "lang/localize";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  achievements: PropTypes.array
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class AchievementsModal extends Component {
  renderCloseButton() {
    return (
      <div>
        <button
          onClick={() => {
            this.props.handleCloseModal();
          }}
          className="close-button"
        >
          <i className="fa close-icon" aria-hidden="true" />
        </button>
      </div>
    );
  }

  renderAchievements(achievements) {
    return (
      <div className="modal-contentblock">
        <div className="achievements-container">
          <AchievementsSlider achievements={achievements} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel={
          localize("feedback_unlock_start", this.props.language) +
          this.props.achievements.length +
          " " +
          this.props.achievements.length.pluralize(
            localize("nav_bar_title_item_profile_text", this.props.language),
            localize("nav_bar_title_items_text", this.props.language)
          ) +
          "!"
        }
        onRequestClose={() => {
          this.props.handleCloseModal();
        }}
        shouldCloseOnOverlayClick={false}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {this.renderCloseButton()}

        <div className="modal-body">
          <h3 className="text-align-center">
            {localize("feedback_unlock_start", this.props.language) +
              this.props.achievements.length +
              " " +
              this.props.achievements.length.pluralize(
                localize(
                  "nav_bar_title_item_profile_text",
                  this.props.language
                ),
                localize("nav_bar_title_items_text", this.props.language)
              ) +
              "!"}
          </h3>

          {this.renderAchievements(this.props.achievements)}
        </div>
      </Modal>
    );
  }
}

AchievementsModal.propTypes = propTypes;

export default AchievementsModal;
