import React, { Component } from "react";
import PropTypes from "prop-types";

import "./PersonalityModal.css";

import Modal from "react-modal";
import localize from "lang/localize";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  personality: PropTypes.object.isRequired,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class PersonalityModal extends Component {
  renderCloseButton() {
    return (
      <div>
        <button
          onClick={() => {
            this.props.onRequestClose();
          }}
          className="close-button"
        >
          <i className="fa close-icon" aria-hidden="true" />
        </button>
      </div>
    );
  }

  render() {
    let personalityTag = this.props.personality
      ? this.props.personality.tag
      : "";
    let personalityImage = this.props.personality
      ? this.props.personality.image
      : "";
    let personalityDescription = this.props.personality
      ? this.props.personality.description
      : "";

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel=""
        onRequestClose={this.props.onRequestClose}
        shouldCloseOnOverlayClick={false}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {this.renderCloseButton()}

        <div className="modal-body textcenter">
          <div className="personality-modal-wrapper">
            {this.props.personality.tag && (
              <div>
                <h3>
                  {localize("tag_result_text", this.props.language) + ": "}
                </h3>
                <h4>
                  <span>{personalityTag}</span>
                </h4>
              </div>
            )}
            {this.props.personality.image && (
              <p>
                <img src={personalityImage} alt={personalityTag} />
              </p>
            )}
            {this.props.personality.description && (
              <p>{personalityDescription}</p>
            )}

            {/* Download result image if available */}
            {this.props.personality.image && (
              <p>
                <a
                  href={personalityImage}
                  target="_blank"
                  className="button link active inline"
                  rel="noreferrer"
                  download
                >
                  <span className="fas fa-download rightmargin-10"></span>{" "}
                  {localize("button_download", this.props.language)}
                </a>
              </p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

PersonalityModal.propTypes = propTypes;

export default PersonalityModal;
