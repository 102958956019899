import React, { Component } from "react";
import PropTypes from "prop-types";

import { SETTINGS } from "App/Routes";
import placeholder_profile from "images/core/placeholders/default_avatar.png";
import Link from "components/shared/Link/Link";
import ProjectCard from "./ProjectCard";
import Loading from "components/shared/Loading";

import { ENABLE_MASTERS_POINTS, ENABLE_SETTINGS_PAGES } from "config";
import localize from "lang/localize";

const propTypes = {
  projects: PropTypes.array.isRequired,
  more: PropTypes.bool,
  handleMore: PropTypes.func,
  language: PropTypes.string,
  user: PropTypes.object,
  isLoadingMore: PropTypes.bool
};

const defaultTypes = {
  more: false
};

class ProjectsListPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("projectsListPage")) {
      bodyDOM.className += " projectsListPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("projectsListPage")) {
      bodyDOM.classList.remove("projectsListPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderProjects() {
    const projects = this.props.projects;
    return projects.map((project, index) => (
      <ProjectCard
        key={index}
        text={project.title}
        img={project.bannerImage}
        id={project.id}
        description={project.description}
      />
    ));
  }

  renderProfile() {
    let name = this.props.user ? this.props.user.name : "";
    let photoSmall = this.props.user
      ? this.props.user.photoSmall
      : placeholder_profile;

    if (ENABLE_MASTERS_POINTS && this.props.user) {
      return (
        <div className="project-card-container bottommargin-10 flex align-items-center">
          <div className="pure-u-3-24">
            {ENABLE_SETTINGS_PAGES ? (
              <Link className="square-image-wrapper" to={SETTINGS}>
                <span className="square-image circle border-gray-light">
                  <img src={photoSmall} alt={name} />
                </span>
              </Link>
            ) : (
              <span className="square-image-wrapper">
                <span className="square-image circle border-gray-light">
                  <img src={photoSmall} alt={name} />
                </span>
              </span>
            )}
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-5-6">
            <h5 className="nomargin font-weight-normal text-truncated-table">
              <span className="text-truncated-cell text-truncated-parent">
                {ENABLE_SETTINGS_PAGES ? (
                  <Link to={SETTINGS}>{name}</Link>
                ) : (
                  <span>{name}</span>
                )}
              </span>
            </h5>
            <p className="nomargin text-truncated-table">
              <strong className="text-truncated-cell text-truncated-parent">
                {ENABLE_SETTINGS_PAGES ? (
                  <Link to={SETTINGS}>
                    {localize("points_text", this.props.language).format(
                      this.props.user.points
                    )}
                  </Link>
                ) : (
                  <span>
                    {localize("points_text", this.props.language).format(
                      this.props.user.points
                    )}
                  </span>
                )}
              </strong>
            </p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="projects-list container verticalpadding">
        <div className="innerblock project-cards-wrapper">
          {this.renderProfile()}
          {this.renderProjects()}
          {this.props.isLoadingMore ? <Loading /> : null}
          {/* {this.props.more && (
            <div className="more-button-container">
              <button
                className="button automargin"
                onClick={this.props.handleMore}
              >
                {localize("more_text", this.props.language)}
              </button>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

ProjectsListPage.propTypes = propTypes;
ProjectsListPage.defaultTypes = defaultTypes;

export default ProjectsListPage;
