import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import allReducers from "./reducers";

import * as serviceWorker from "./registerServiceWorker";

import "./library/js/string";
import "./library/js/number";

import "./library/pure-css/core/base.css";
import "./library/pure-css/core/forms.css";
import "./library/pure-css/core/grids.css";
import "./library/pure-css/core/grids-responsive.css";

import "./library/pure-css/custom/base.css";
import "./library/pure-css/custom/forms.css";
import "./library/pure-css/custom/grids.css";
import "./library/pure-css/custom/grids-responsive.css";

import "./library/css/core/style.css";
import "./library/css/core/buttons.css";
import "./library/css/core/text.css";
import "./library/css/core/icons.css";
import "./library/css/core/iframes.css";
import "./library/css/core/dialogs.css";
import "./library/css/core/modals.css";

import "./library/css/custom/style.css";
import "./library/css/custom/buttons.css";
import "./library/css/custom/text.css";
import "./library/css/custom/icons.css";
import "./library/css/custom/iframes.css";
import "./library/css/custom/dialogs.css";
import "./library/css/custom/modals.css";

import SingleProjectApp from "App/SingleProjectApp";
import MultipleProjectApp from "App/MultipleProjectApp";

import { SINGLE_PROJECT_APP, ENABLE_MICROSOFT_LOGIN } from "config";
import { MICROSOFT_CLIENT_INSTANCE } from "services/microsoftServices";
import { MsalProvider } from "@azure/msal-react";

/* App Version Management */
import packageJson from ".././package.json";
global.appVersion = packageJson.version;

let middlewares;

if (
  process.env.NODE_ENV === "development" &&
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  middlewares = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  middlewares = applyMiddleware(thunk);
}

export const store = createStore(allReducers, middlewares);

// store.subscribe(() => {
//   const state = store.getState();
//   console.log(state);
// });

const App = SINGLE_PROJECT_APP ? SingleProjectApp : MultipleProjectApp;

ReactDOM.render(
  <Provider store={store}>{renderAppWrapper()}</Provider>,
  document.getElementById("root") || document.createElement("div")
);

function renderAppWrapper() {
  if (ENABLE_MICROSOFT_LOGIN) {
    return (
      <MsalProvider instance={MICROSOFT_CLIENT_INSTANCE}>
        <App />
      </MsalProvider>
    );
  } else {
    return <App />;
  }
}

serviceWorker.unregister();
