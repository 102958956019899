import React, { Component } from "react";
import PropTypes from "prop-types";

import { TOPIC } from "App/Routes";
import placeholder_topic from "images/core/placeholders/placeholder_topic.jpg";
import expired_topic from "images/core/placeholders/home_topic_expired_icon.png";
import locked_topic from "images/core/placeholders/home_topic_locked_icon.png";
import Link from "components/shared/Link/Link";

import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  projectTitle: PropTypes.string.isRequired,
  topics: PropTypes.array.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal to view Topic list
 */
class TopicsListDialog extends Component {
  renderTopics(handleCloseDialog) {
    return this.props.topics.map((topic, index) => (
      <li key={topic.id}>
        {/*
          Links that have null in their `to` prop
          are disabled.
        */}
        <Link
          to={!topic.expired && !topic.locked ? TOPIC.format(topic.id) : null}
          className={!topic.expired && !topic.locked ? "" : "disabled"}
          onClick={handleCloseDialog}
        >
          <div className="pure-g">
            <div
              className={
                (!topic.expired && !topic.locked
                  ? "pure-u-1-1"
                  : "pure-u-21-24") + " flex align-items-center"
              }
            >
              <span className="square-image-wrapper">
                <span
                  className={
                    (!topic.expired && !topic.locked
                      ? "border-gray"
                      : "border-gray-light") +
                    " border-thin square-image circle"
                  }
                >
                  <img
                    src={topic.bannerImage || placeholder_topic}
                    alt={topic.title}
                  />
                </span>
              </span>
              <span>{topic.title}</span>
            </div>
            {!(!topic.expired && !topic.locked) ? (
              <div className="pure-u-3-24 flex align-items-center justify-content-flexend locked-expired-image-container">
                <span className="square-image-wrapper">
                  <span
                    className={
                      (!topic.expired && !topic.locked
                        ? "border-gray"
                        : "border-gray-light") +
                      " border-thin square-image circle"
                    }
                  >
                    <img
                      src={topic.locked ? locked_topic : expired_topic}
                      alt={topic.title}
                    />
                  </span>
                </span>
              </div>
            ) : null}
          </div>
        </Link>
      </li>
    ));
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={localize("share_text", this.props.language)}
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={true}
        className="dialog list-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">{this.props.projectTitle}</h5>
          </div>
          <div className="dialog-body">
            <ul className="dialog-list">
              {this.renderTopics(this.props.handleCloseDialog)}
            </ul>
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={e => {
                  e.preventDefault();
                  this.props.handleCloseDialog();
                }}
                id="cancelButton"
              >
                {localize("web_alert_close_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

TopicsListDialog.propTypes = propTypes;

export default TopicsListDialog;
