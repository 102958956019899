import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  AVAILABLE_LANGUAGES,
  PLATFORM_AVAILABLE_LANGUAGES_STRINGS
} from "config";
import localize from "lang/localize";

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  language: PropTypes.string
};

class LanguageSelectInput extends Component {
  /*
    In renderLanguageOptions, we won't be using VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS
    because we need the "text" and "lang" keys in AVAILABLE_LANGUAGES
    rendered.
  */
  renderLanguageOptions() {
    return AVAILABLE_LANGUAGES.map(function(option) {
      if (
        PLATFORM_AVAILABLE_LANGUAGES_STRINGS.indexOf(
          option.lang.toLowerCase()
        ) >= 0
      ) {
        return (
          <option
            value={option.lang.toLowerCase()}
            key={option.lang.toLowerCase()}
          >
            {option.text} ({option.lang})
          </option>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <form className="pure-form">
        <label htmlFor={this.props.name}>
          {localize(
            "dialog_language_selection_placeholder",
            this.props.language
          ) + ":"}
        </label>
        <select
          id={this.props.name}
          name={this.props.name}
          value={this.props.selectedValue}
          onChange={e => {
            this.props.onChange(e.target.value);
          }}
        >
          {this.renderLanguageOptions()}
        </select>
      </form>
    );
  }
}

LanguageSelectInput.propTypes = propTypes;

export default LanguageSelectInput;
