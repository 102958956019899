import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import Router from "router";

import Loading from "components/shared/Loading";
import DiscussionCommentsPage from "./DiscussionCommentsPage";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { PROJECT_DISCUSSIONS } from "App/Routes";
import {
  showAlertWithTimeout,
  setButtons,
  setProject,
  setBoard,
  setPost,
} from "actions";
import { LOAD_MORE_COUNT } from "config";
import {
  GET_POST_COMMENTS,
  BOOKMARK_POST_COMMENT,
  LIKE_POST_COMMENT,
  CREATE_POST_COMMENT,
  BOOKMARK_POST,
  LIKE_POST,
  GET_PROJECT_BUTTONS,
} from "services/api";
import listenerServices from "services/listenerServices";
import getApiGenerator from "services/getApiGenerator";
import getApiGenerator2 from "services/getApiGenerator2";
import pushApiGenerator2 from "services/pushApiGenerator2";
import localize from "lang/localize";

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  id2: PropTypes.string,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setBoard: (board) => {
      dispatch(setBoard(board));
    },
    setPost: (post) => {
      dispatch(setPost(post));
    },
  };
};

export class DiscussionCommentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id refers to projectId, id2 refers to boardId
      id: props.id,
      id2: props.id2,
      more: false,
      page: 1,
      input: "",
      // character limit
      charLimit: 0,
      board: null,
      post: null,
      comments: null,
      likeNo: 0,
      postUser: null,
      fileUploadLimit: 0,
      fileTotalLimit: 0,
      code: null,
      showLoginDialog: false,
      showImageDialog: false,
      currentImage: null,
    };

    this.removeComment = this.removeComment.bind(this);
    this.removePost = this.removePost.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getPostComments = this.getPostComments.bind(this);
    this.handleCommentBookmark = this.handleCommentBookmark.bind(this);
    this.handleCommentLike = this.handleCommentLike.bind(this);
    this.handlePostBookmark = this.handlePostBookmark.bind(this);
    this.handlePostLike = this.handlePostLike.bind(this);
    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this);
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this);
    /* Image Dialogs */
    this.handleOpenImageDialog = this.handleOpenImageDialog.bind(this);
    this.handleCloseImageDialog = this.handleCloseImageDialog.bind(this);
  }

  componentDidMount() {
    this.getPostComments(1);
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  getPostComments(page) {
    return getApiGenerator2(
      GET_POST_COMMENTS.format(this.state.id2),
      { page: page, limit: LOAD_MORE_COUNT },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.setState({
          comments: [],
          board: res.body ? res.body.board : null,
          post: res.body ? res.body.post : null,
          more: false,
          page: 1,
          isLoadingMore: false,
          charLimit: res.body.charLimit ? res.body.charLimit : 0,
          fileUploadLimit: res.body.fileUploadLimit
            ? res.body.fileUploadLimit
            : 0,
          fileTotalLimit: res.body.fileTotalLimit ? res.body.fileTotalLimit : 0,
          code: res.body ? res.body.code : 500,
        });

        this.props.setBoard(res.body ? res.body.board : null);
        this.props.setPost(res.body ? res.body.post : null);
      } else {
        this.setState({
          comments:
            this.state.comments && page > 1
              ? this.state.comments.slice().concat(res.body.data)
              : res.body.data,
          more: res.body.more,
          board: res.body.board,
          post: res.body.post,
          page: page + 1,
          isLoadingMore: false,
          charLimit: res.body.charLimit,
          fileUploadLimit: res.body.fileUploadLimit,
          fileTotalLimit: res.body.fileTotalLimit,
          code: res.body.code,
        });

        this.props.setBoard(res.body.board);
        this.props.setPost(res.body.post);
      }

      this.getProjectButtons(this.state.id);
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
        this.props.setProject(res.body.game);
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getPostComments,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getPostComments(page);
      }
    }
  }, 100);

  handleInputChange = (event) => {
    this.setState({ input: event.target.value });
  };

  handleSubmit = (images) => {
    this.postComment(images);
  };

  handleCommentBookmark = (event, commentId) => {
    event.preventDefault();
    this.bookmarkComment(commentId);
  };

  bookmarkComment = (comment_id) => {
    getApiGenerator2(
      BOOKMARK_POST_COMMENT.format(comment_id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        const comments = this.state.comments.slice();
        const comment = comments.filter(
          (comment) => comment.id === comment_id,
        )[0];
        comment.commentBookmarked = res.body.commentBookmarked;
        comment.commentBookmarkCount = res.body.commentBookmarkCount;
        this.setState({
          comments: comments,
        });
      }
    });
  };

  handleCommentLike = (event, commentId) => {
    event.preventDefault();
    this.likeComment(commentId);
  };

  likeComment = (comment_id) => {
    getApiGenerator2(
      LIKE_POST_COMMENT.format(comment_id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        const comments = this.state.comments.slice();
        const comment = comments.filter(
          (comment) => comment.id === comment_id,
        )[0];
        comment.commentVoted = res.body.commentVoted;
        comment.commentVoteCount = res.body.commentVoteCount;
        this.setState({
          comments: comments,
        });
      }
    });
  };

  handlePostBookmark = (event, postId) => {
    event.preventDefault();
    this.bookmarkPost(postId);
  };

  bookmarkPost = (post_id) => {
    getApiGenerator2(
      BOOKMARK_POST.format(post_id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        const post = this.state.post;
        post.postBookmarked = res.body.postBookmarked;
        post.postBookmarkCount = res.body.postBookmarkCount;
        this.setState({
          post: post,
        });
      }
    });
  };

  handlePostLike = (event, postId) => {
    event.preventDefault();
    this.likePost(postId);
  };

  handleOpenLoginDialog() {
    this.setState({
      showLoginDialog: true,
    });
  }

  handleCloseLoginDialog() {
    this.setState({
      showLoginDialog: false,
    });
  }

  handleOpenImageDialog(event) {
    if (event.target.src) {
      this.setState({
        showImageDialog: true,
        currentImage: event.target.src,
      });
    }
  }

  handleCloseImageDialog() {
    this.setState({
      showImageDialog: false,
    });
  }

  likePost = (post_id) => {
    getApiGenerator2(LIKE_POST.format(post_id), {}, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        } else {
          const post = this.state.post;
          post.postVoted = res.body.postVoted;
          post.postVoteCount = res.body.postVoteCount;
          this.setState({
            post: post,
          });
        }
      },
    );
  };

  postComment = (images = null) => {
    let query = {
      session_key: this.props.sessionKey,
      content: this.state.input,
    };
    let req = pushApiGenerator2(
      CREATE_POST_COMMENT.format(this.state.post.id),
      query,
      this.props.sessionKey,
    );
    if (images !== null) {
      Array.from(images).forEach((image) => {
        req.field("file", image);
        req.field("file_name", image.name);
      });
    }
    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.refreshPostComments();
        this.setState({ input: "" });
      }
    });
  };

  removeComment(commentId) {
    this.setState({
      comments: this.state.comments.filter(
        (element) => element.id !== commentId,
      ),
    });
    const post = this.state.post;
    post.postCommentCount =
      post.postCommentCount > 0 ? post.postCommentCount - 1 : 0;
    this.setState({
      post: post,
    });
  }

  removePost() {
    const projectId = this.state.id;
    setTimeout(function () {
      // Set a delay so that a new API call to retrieve posts will be made only after post is deleleted
      Router.navigate(PROJECT_DISCUSSIONS.format(projectId));
    }, 100);
  }

  refreshPostComments() {
    for (let i = 1; i < Math.max(this.state.page, 2); i++) {
      this.getPostComments(i);
    }
  }

  render() {
    if (this.state.post && this.state.comments) {
      return (
        <DiscussionCommentsPage
          sessionKey={this.props.sessionKey}
          isLoadingMore={this.state.isLoadingMore}
          more={this.state.more}
          showInput={!!this.props.sessionKey}
          input={this.state.input}
          handleMore={this.handleMore}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
          id={this.props.id}
          type={this.props.type}
          language={this.props.language}
          projectId={this.state.id}
          userId={this.props.userId}
          charLimit={
            this.state.charLimit !== undefined ? this.state.charLimit : 0
          }
          //discussions
          post={this.state.post}
          board={this.state.board}
          comments={this.state.comments}
          handleCommentLike={this.handleCommentLike}
          handleCommentBookmark={this.handleCommentBookmark}
          postUser={this.state.postUser}
          removeComment={this.removeComment}
          removePost={this.removePost}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          fileTotalLimit={this.state.fileTotalLimit}
          fileUploadLimit={this.state.fileUploadLimit}
          handlePostLike={this.handlePostLike}
          handlePostBookmark={this.handlePostBookmark}
          showLoginDialog={this.state.showLoginDialog}
          handleOpenLoginDialog={this.handleOpenLoginDialog}
          handleCloseLoginDialog={this.handleCloseLoginDialog}
          // image dialogs
          showImageDialog={this.state.showImageDialog}
          handleOpenImageDialog={this.handleOpenImageDialog}
          handleCloseImageDialog={this.handleCloseImageDialog}
          currentImage={this.state.currentImage}
        />
      );
    } else if (!!!this.state.post && this.state.code === 500) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          message={localize("discussion_post_invalid", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      /* Everything else */
      return <Loading />;
    }
  }
}

DiscussionCommentsContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscussionCommentsContainer);
