import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Post from "components/Discussions/Post/Post";
import BoardFilterDialogContainer from "./BoardFilterDialog/BoardFilterDialogContainer";
import Link from "components/shared/Link/Link";

import {
  PROJECT_NEW_POST,
  PROJECT_BOARD_NEW_POST,
  PROJECT_BOARD_POSTS,
} from "App/Routes";

import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import localize from "lang/localize";
import Loading from "components/shared/Loading";

import "./Discussions.css";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  more: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  boards: PropTypes.array,
  board: PropTypes.object,
  defaultBoard: PropTypes.object,
  selectedOption: PropTypes.object,
  search: PropTypes.string,
  sort: PropTypes.string,
  posts: PropTypes.array,
  handleLike: PropTypes.func,
  handleBookmark: PropTypes.func,
  showBoardFilterDialog: PropTypes.bool.isRequired,
  handleOpenBoardFilterDialog: PropTypes.func.isRequired,
  handleCloseBoardFilterDialog: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  showLoginDialog: PropTypes.bool.isRequired,
  handleOpenLoginDialog: PropTypes.func.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
};

const Posts = (props) => {
  if (props.posts && Array.isArray(props.posts) && props.posts.length > 0) {
    return (
      <div className="discussions-posts">
        {props.posts.map((post, index) => (
          <Fragment key={post.id}>
            <Post
              id={post.id}
              userId={props.userId}
              projectId={props.projectId}
              postUserName={post.userName}
              postUserId={post.userId}
              postUserImage={post.userImage}
              userRankStatusImage={post.userRankStatusImage}
              userRankStatus={post.userRankStatus}
              postPinned={post.postPinned}
              postTitle={post.postTitle}
              postContent={post.postContent}
              postContentAttributed={post.postContentAttributed}
              postEmbeds={post.postEmbeds}
              postVoteCount={post.postVoteCount}
              postCommentCount={post.postCommentCount}
              postBookmarkCount={post.postBookmarkCount}
              createdAtFormatted={post.createdAtFormatted}
              updatedAtFormatted={post.updatedAtFormatted}
              createdAt={post.createdAt}
              updatedAt={post.updatedAt}
              boardId={post.boardId}
              board={post.board ? post.board : props.board}
              removePost={props.removePost}
              handleLike={props.handleLike}
              handleBookmark={props.handleBookmark}
              postVoted={post.postVoted}
              postBookmarked={post.postBookmarked}
              language={props.language}
              // login dialogs
              showLoginDialog={props.showLoginDialog}
              handleOpenLoginDialog={props.handleOpenLoginDialog}
              handleCloseLoginDialog={props.handleCloseLoginDialog}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <p className="topmargin-10 text-align-center">
        {localize("empty_table_text", props.language)}
      </p>
    );
  }
};

/**
 * Render prompt login popup dialog
 */
const renderLoginDialog = (props) => {
  return (
    <LoginDialogContainer
      showModal={props.showLoginDialog}
      handleCloseLoginDialog={props.handleCloseLoginDialog}
    />
  );
};

/**
 * Render like button
 */
const renderNewPostButton = (props) => {
  if (props.sessionKey) {
    // User is logged in
    return (
      <Link
        to={
          props.board
            ? PROJECT_BOARD_NEW_POST.format(props.projectId, props.board.id)
            : PROJECT_NEW_POST.format(props.id)
        }
      >
        <button className="button cta">
          {localize("discussion_new_post", props.language)}
        </button>
      </Link>
    );
  } else {
    // User is not logged in
    return (
      <div>
        <button className="button cta" onClick={props.handleOpenLoginDialog}>
          {localize("discussion_new_post", props.language)}
        </button>
        {props.showLoginDialog && renderLoginDialog(props)}
      </div>
    );
  }
};

/**
 * Render available boards
 *
 * @param {*} props
 * @returns
 */
const BoardChips = (props) => {
  if (props.boards && Array.isArray(props.boards) && props.boards.length > 0) {
    return (
      <div className="discussions-board-chips">
        {/* Display current board at first position */}
        {props.board && (
          <Fragment key={props.board.id}>
            <button className="discussions-board-chip button inline small active">
              <span
                className="board-title"
                title={props.board.boardTitle}
                dangerouslySetInnerHTML={{
                  __html: props.board.boardTitle,
                }}
              />
            </button>
          </Fragment>
        )}
        {/* Display other boards */}
        {props.boards.map(
          (board) =>
            (props.board == null || props.board.id !== board.id) && (
              <Fragment key={board.id}>
                <Link
                  className="discussions-board-chip button inline small inactive"
                  to={PROJECT_BOARD_POSTS.format(props.projectId, board.id)}
                >
                  <span
                    className="board-title"
                    title={board.boardTitle}
                    dangerouslySetInnerHTML={{
                      __html: board.boardTitle,
                    }}
                  />
                </Link>
              </Fragment>
            ),
        )}
      </div>
    );
  } else {
    return null;
  }
};

const DiscussionsPage = (props) => {
  return (
    <Fragment>
      <div
        className="discussions-components discussions-main-container container verticalpadding bottompadding-floating-extended"
        id="discussions"
      >
        <div className="innerblock">
          <div className="flex discussions-header">
            <BoardChips
              boards={props.boards}
              board={props.board}
              projectId={props.id}
            />
            {/* TEMP: New post in separate page */}

            <div className="discussions-header-controls">
              {renderNewPostButton(props)}
              <div className="discussions-discussions-board-filters">
                <div className="discussions-board-filter-button">
                  <button
                    className={
                      "button" +
                      (props.search ||
                      props.sort ||
                      (props.board && !props.defaultBoard)
                        ? " cta"
                        : "")
                    }
                    onClick={(e) => {
                      props.handleOpenBoardFilterDialog(e);
                    }}
                  >
                    <i
                      className={
                        "fas " +
                        (props.search ||
                        props.sort ||
                        (props.board && !props.defaultBoard)
                          ? "fa-filter"
                          : "fa-sliders-h")
                      }
                    />
                  </button>
                  {props.showBoardFilterDialog && (
                    <BoardFilterDialogContainer
                      showBoardFilterDialog={props.showBoardFilterDialog}
                      handleCloseBoardFilterDialog={
                        props.handleCloseBoardFilterDialog
                      }
                      handleSortToggle={props.handleSortToggle}
                      handleBoardChange={props.handleBoardChange}
                      handleSearchInput={props.handleSearchInput}
                      handleBoardFilters={props.handleBoardFilters}
                      handleFilterReset={props.handleFilterReset}
                      currentBoard={props.board}
                      defaultBoard={props.defaultBoard}
                      selectedOption={props.selectedOption}
                      projectId={props.id}
                      boards={props.boards}
                      search={props.search}
                      sort={props.sort}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Posts
            posts={props.posts}
            board={props.board}
            type={props.type}
            userId={props.userId}
            projectId={props.id}
            removePost={props.removePost}
            handleLike={props.handleLike}
            handleBookmark={props.handleBookmark}
            language={props.language}
            // login dialogs
            showLoginDialog={props.showLoginDialog}
            handleOpenLoginDialog={props.handleOpenLoginDialog}
            handleCloseLoginDialog={props.handleCloseLoginDialog}
          />
          {props.isLoadingMore && <Loading />}
        </div>
        <div className="pure-u-1-24" />
      </div>
    </Fragment>
  );
};

DiscussionsPage.propTypes = propTypes;

export default DiscussionsPage;
