import React, { Component } from "react";
import PropTypes from "prop-types";

import { PLAYER_URL } from "config";
import urlServices from "services/urlServices";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperShareButton,
  InstapaperIcon,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton
} from "react-share";
import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal for Project sharing
 */
class ShareGameDialog extends Component {
  constructor() {
    super();
    this.state = {
      showSharableLinkCopied: false,
      showLinkCopied: false
    };
  }

  renderHeaderTitle() {
    return localize("share_to_text", this.props.language);
  }

  handleCopyLink = e => {
    this.input.select();
    document.execCommand("copy");
    this.setState({ showLinkCopied: true }, () => {
      setTimeout(() => {
        this.setState({ showLinkCopied: false });
      }, 1250);
    });
  };

  handleCopySharableLink = e => {
    this.sharableInput.select();
    document.execCommand("copy");
    this.setState({ showSharableLinkCopied: true }, () => {
      setTimeout(() => {
        this.setState({ showSharableLinkCopied: false });
      }, 1250);
    });
  };

  render() {
    const BASE_PLACEHOLDER_PROJECT_IMAGE =
      "https://gametize.com/images/core/business/placeholders/placeholder_project.png";

    const CURRENT_ABSOLUTE_PATH =
      PLAYER_URL + urlServices.removeFirstSlash(urlServices.defaultPath());

    let sharableLink = this.props.project.projectShareLink || "";
    let sharableImage =
      this.props.project && this.props.project.bannerImage
        ? this.props.project.bannerImage
        : BASE_PLACEHOLDER_PROJECT_IMAGE;

    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={localize("share_text", this.props.language)}
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={true}
        className="dialog list-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">{this.renderHeaderTitle()}</h5>
          </div>
          <div className="dialog-body">
            <ul className="dialog-list">
              <li>
                <button
                  onClick={this.handleCopyLink}
                  disabled={this.state.showLinkCopied}
                >
                  <div className="circle-icon-wrapper">
                    <i className="fas fa-copy"></i>
                  </div>
                  <div>
                    <p className="nomargin line-height-1">
                      {this.state.showLinkCopied
                        ? localize("copied_link_text", this.props.language)
                        : localize("copy_link_text", this.props.language)}
                    </p>
                    <p className="nomargin line-height-1">
                      <small>{CURRENT_ABSOLUTE_PATH}</small>
                    </p>
                  </div>
                  <input
                    className="copy-input"
                    type="text"
                    ref={input => (this.input = input)}
                    defaultValue={CURRENT_ABSOLUTE_PATH}
                    readOnly
                  />
                </button>
              </li>
              <li>
                <button
                  onClick={this.handleCopySharableLink}
                  disabled={this.state.showSharableLinkCopied}
                >
                  <div className="circle-icon-wrapper">
                    <i className="fas fa-copy"></i>
                  </div>
                  <div>
                    <p className="nomargin line-height-1">
                      {this.state.showSharableLinkCopied
                        ? localize("copied_link_text", this.props.language)
                        : localize(
                            "copy_sharable_text_link",
                            this.props.language
                          )}
                    </p>
                    <p className="nomargin line-height-1">
                      <small>{sharableLink}</small>
                    </p>
                  </div>
                  <input
                    className="copy-input"
                    type="text"
                    ref={sharableInput => (this.sharableInput = sharableInput)}
                    defaultValue={sharableLink}
                    readOnly
                  />
                </button>
              </li>
              <li>
                <EmailShareButton url={sharableLink}>
                  <EmailIcon size={24} round={true} />
                  <span>
                    {localize(
                      "login_view_email_placeholder_text",
                      this.props.language
                    )}
                  </span>
                </EmailShareButton>
              </li>
              <li>
                <FacebookShareButton url={sharableLink}>
                  <FacebookIcon size={24} round={true} />
                  <span>Facebook</span>
                </FacebookShareButton>
              </li>
              <li>
                <WorkplaceShareButton url={sharableLink}>
                  <WorkplaceIcon size={24} round={true} />
                  <span>Workplace by Facebook</span>
                </WorkplaceShareButton>
              </li>
              <li>
                <InstapaperShareButton url={sharableLink}>
                  <InstapaperIcon size={24} round={true} />
                  <span>Instapaper</span>
                </InstapaperShareButton>
              </li>
              <li>
                <LineShareButton url={sharableLink}>
                  <LineIcon size={24} round={true} />
                  <span>Line</span>
                </LineShareButton>
              </li>
              <li>
                <LinkedinShareButton url={sharableLink}>
                  <LinkedinIcon size={24} round={true} />
                  <span>LinkedIn</span>
                </LinkedinShareButton>
              </li>
              <li>
                <LivejournalShareButton url={sharableLink}>
                  <LivejournalIcon size={24} round={true} />
                  <span>LiveJournal</span>
                </LivejournalShareButton>
              </li>
              <li>
                {/*
                  `media` is a required prop for PinterestShareButton:
                  it is an absolute link to the image that will be pinned
                */}
                <PinterestShareButton url={sharableLink} media={sharableImage}>
                  <PinterestIcon size={24} round={true} />
                  <span>Pinterest</span>
                </PinterestShareButton>
              </li>
              <li>
                <PocketShareButton url={sharableLink}>
                  <PocketIcon size={24} round={true} />
                  <span>Pocket</span>
                </PocketShareButton>
              </li>
              <li>
                <RedditShareButton url={sharableLink}>
                  <RedditIcon size={24} round={true} />
                  <span>Reddit</span>
                </RedditShareButton>
              </li>
              <li>
                <TelegramShareButton url={sharableLink}>
                  <TelegramIcon size={24} round={true} />
                  <span>Telegram</span>
                </TelegramShareButton>
              </li>
              <li>
                <TumblrShareButton url={sharableLink}>
                  <TumblrIcon size={24} round={true} />
                  <span>Tumblr</span>
                </TumblrShareButton>
              </li>
              <li>
                <TwitterShareButton url={sharableLink}>
                  <TwitterIcon size={24} round={true} />
                  <span>Twitter</span>
                </TwitterShareButton>
              </li>
              <li>
                <ViberShareButton url={sharableLink}>
                  <ViberIcon size={24} round={true} />
                  <span>Viber</span>
                </ViberShareButton>
              </li>
              <li>
                <WeiboShareButton url={sharableLink}>
                  <WeiboIcon size={24} round={true} />
                  <span>Weibo</span>
                </WeiboShareButton>
              </li>
              <li>
                <WhatsappShareButton url={sharableLink}>
                  <WhatsappIcon size={24} round={true} />
                  <span>Whatsapp</span>
                </WhatsappShareButton>
              </li>
            </ul>
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseDialog}
                id="cancelButton"
              >
                {localize("web_alert_close_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ShareGameDialog.propTypes = propTypes;

export default ShareGameDialog;
