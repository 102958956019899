/* REGEX TO TAKE NOTE OF */
/*
   ((\/)?\?.*)?
   This regex is for matching parameterised URLs with '?'s annd '='s and '&'s. We
   use this for handling appended parameters to existing URLs, that the marketing
   team needs for tracking their URLs. Our app does not need or use the parameters
   for now, but this ensures that users can still access our URLs through the
   parameters, without error 404s due to our strict regex-URL matching.
   - '(\/)?\?' matches 1 or 0 forward slashes, then a question mark
   - '.*' matches 0 or more of any character (except line breaks) after the question mark
   - '((\/)?\?.*)?' putting them together, '/whatever', '/whatever/?',
     '/whatever?stuff1=here&stuff2=there', and '/whatever/?stuff1=here&stuff2=there'
     all work
*/
export const LOGIN_HOME = /^loginhome((\/)?\?.*)?$/,
  LOGIN = /^login((\/)?\?.*)?$/,
  LOGIN_SSO = /^loginsso((\/)?\?.*)?$/,
  POST_LOGIN_INTERCEPT = /^postloginintercept((\/)?\?.*)?$/,
  LOGIN_TOUR = /^tour((\/)?\?.*)?$/,
  SSO_LOGIN_SUCCESS = /^ssologinsuccess((\/)?\?.*)?$/,
  FRAME_LOGIN_SUCCESS = /^frameloginsuccess((\/)?\?.*)?$/,
  REGISTER = /^register((\/)?\?.*)?$/,
  RESET_PASSWORD = /^resetpassword((\/)?\?.*)?$/,
  SETTINGS = /^settings((\/)?\?.*)?$/,
  UPDATE_DEVICE_TOKEN = /^updateDeviceToken\?deviceToken=(.*)$/,
  ITEM = /^item\/([\d]{1,18})((\/)?\?.*)?$/,
  TEAM = /^team\/([\d]{1,18})((\/)?\?.*)?$/,
  TEAM_INVITE = /^team\/([\d]{1,18})\/invite((\/)?\?.*)?$/,
  TEAM_UPDATE = /^team\/([\d]{1,18})\/update((\/)?\?.*)?$/,
  HOME = /^(home)?((\/)?\?.*)?$/,
  TOPIC_CATEGORY = /^category\/([\d]{1,18})((\/)?\?.*)?$/,
  TOPIC = /^topic\/([\d]{1,18})((\/)?\?.*)?$/,
  CHALLENGE_CATEGORY =
    /^topic\/([\d]{1,18})\/category\/([\d]{1,18})((\/)?\?.*)?$/,
  TOPIC_COMMENTS = /^topic\/([\d]{1,18})\/comments((\/)?\?.*)?$/,
  TOPIC_COMMENTS_THREAD =
    /^topic\/([\d]{1,18})\/comments\/([\d]{1,18})\/thread((\/)?\?.*)?$/,
  CHALLENGE = /^challenge\/([\d]{1,18})((\/)?\?.*)?$/,
  CHALLENGE_A_FRIEND = /^challenge\/([\d]{1,18})\/refer((\/)?\?.*)?$/,
  CHALLENGE_COMMENTS = /^challenge\/([\d]{1,18})\/comments((\/)?\?.*)?$/,
  CHALLENGE_COMMENTS_THREAD =
    /^challenge\/([\d]{1,18})\/comments\/([\d]{1,18})\/thread((\/)?\?.*)?$/,
  CHALLENGE_ACTIVITY = /^challenge\/([\d]{1,18})\/activity((\/)?\?.*)?$/,
  CHALLENGE_MY_ACTIVITY = /^challenge\/([\d]{1,18})\/my-activity((\/)?\?.*)?$/,
  CHALLENGE_ENQUIRY = /^challenge\/([\d]{1,18})\/enquiry((\/)?\?.*)?$/,
  CLAIM = /^claim\/([\d]{1,18})((\/)?\?.*)?$/,
  CLAIM_ENQUIRY = /^claim\/([\d]{1,18})\/enquiry((\/)?\?.*)?$/,
  CLAIM_COMMENTS = /^claim\/([\d]{1,18})\/comments((\/)?\?.*)?$/,
  CLAIM_COMMENTS_THREAD =
    /^claim\/([\d]{1,18})\/comments\/([\d]{1,18})\/thread((\/)?\?.*)?$/,
  FRAME = /^frame\?url=(.{1,1024})?$/,
  ACHIEVEMENTS = /^achievements((\/)?\?.*)?$/,
  PROJECT_USER_ACHIEVEMENTS = /^user\/([\d]{1,18})\/achievements((\/)?\?.*)?$/,
  PROJECT_USER_FOLLOWS = /^user\/([\d]{1,18})\/follows((\/)?\?.*)?$/,
  PROJECT_ACTIVITY = /^activity((\/)?\?.*)?$/,
  PROJECT_BOOKMARKS = /^bookmarks((\/)?\?.*)?$/,
  PROJECT_LEADERBOARD = /^leaderboard((\/)?\?.*)?$/,
  PROJECT_NOTIFICATIONS = /^notifications((\/)?\?.*)?$/,
  INBOX = /^inbox((\/)?\?.*)?$/,
  PROJECT_PROFILE = /^profile((\/)?\?.*)?$/,
  PROJECT_REWARDS = /^rewards((\/)?\?.*)?$/,
  PROJECT_SEARCH = /^\?search=(.{1,18})$/,
  PROJECT_TEAMS = /^teams((\/)?\?.*)?$/,
  PROJECT_TEAM_CREATE = /^teams\/create((\/)?\?.*)?$/,
  PROJECT_USER = /^user\/([\d]{1,18})((\/)?\?.*)?$/,
  PROJECT_USER_ENQUIRY = /^user\/([\d]{1,18})\/enquiry((\/)?\?.*)?$/,
  PROJECT_USERS = /^users((\/)?\?.*)?$/,
  PROJECT_REFER_A_FRIEND = /^refer((\/)?\?.*)?$/,
  CERTIFICATES = /^certificates((\/)?\?.*)?$/,
  PROJECT_USER_CERTIFICATES = /^user\/([\d]{1,18})\/certificates((\/)?\?.*)?$/,
  /* MultipleProjectApp.js */
  PROJECT_HOME_MULTI = /^project\/([\d]{1,18})((\/)?\?.*)?$/,
  TOPIC_CATEGORY_MULTI =
    /^project\/([\d]{1,18})\/category\/([\d]{1,18})((\/)?\?.*)?$/,
  PROJECT_ACHIEVEMENTS_MULTI =
    /^project\/([\d]{1,18})\/achievements((\/)?\?.*)?$/,
  PROJECT_USER_ACHIEVEMENTS_MULTI =
    /^project\/([\d]{1,18})\/user\/([\d]{1,18})\/achievements((\/)?\?.*)?$/,
  PROJECT_USER_FOLLOWS_MULTI =
    /^project\/([\d]{1,18})\/user\/([\d]{1,18})\/follows((\/)?\?.*)?$/,
  PROJECT_ACTIVITY_MULTI = /^project\/([\d]{1,18})\/activity((\/)?\?.*)?$/,
  PROJECT_BOOKMARKS_MULTI = /^project\/([\d]{1,18})\/bookmarks((\/)?\?.*)?$/,
  PROJECT_LEADERBOARD_MULTI =
    /^project\/([\d]{1,18})\/leaderboard((\/)?\?.*)?$/,
  PROJECT_NOTIFICATIONS_MULTI =
    /^project\/([\d]{1,18})\/notifications((\/)?\?.*)?$/,
  PROJECT_INBOX = /^project\/([\d]{1,18})\/inbox((\/)?\?.*)?$/,
  PROJECT_PROFILE_MULTI = /^project\/([\d]{1,18})\/profile((\/)?\?.*)?$/,
  PROJECT_REWARDS_MULTI = /^project\/([\d]{1,18})\/rewards((\/)?\?.*)?$/,
  PROJECT_SEARCH_MULTI = /^project\/([\d]{1,18})\?search=(.{1,18})$/,
  PROJECT_TEAMS_MULTI = /^project\/([\d]{1,18})\/teams((\/)?\?.*)?$/,
  PROJECT_TEAM_CREATE_MULTI =
    /^project\/([\d]{1,18})\/teams\/create((\/)?\?.*)?$/,
  PROJECT_USER_MULTI =
    /^project\/([\d]{1,18})\/user\/([\d]{1,18})((\/)?\?.*)?$/,
  PROJECT_USER_ENQUIRY_MULTI =
    /^project\/([\d]{1,18})\/user\/([\d]{1,18})\/enquiry((\/)?\?.*)?$/,
  PROJECT_USERS_MULTI = /^project\/([\d]{1,18})\/users((\/)?\?.*)?$/,
  PROJECT_TEAM_MULTI =
    /^project\/([\d]{1,18})\/team\/([\d]{1,18})((\/)?\?.*)?$/,
  PROJECT_TEAM_INVITE_MULTI =
    /^project\/([\d]{1,18})\/team\/([\d]{1,18})\/invite((\/)?\?.*)?$/,
  PROJECT_TEAM_UPDATE_MULTI =
    /^project\/([\d]{1,18})\/team\/([\d]{1,18})\/update((\/)?\?.*)?$/,
  PROJECT_ITEM_MULTI =
    /^project\/([\d]{1,18})\/item\/([\d]{1,18})((\/)?\?.*)?$/,
  PROJECT_RESET_PASSWORD_MULTI =
    /^project\/([\d]{1,18})\/resetpassword((\/)?\?.*)?$/,
  PROJECT_LOGIN_HOME_MULTI = /^project\/([\d]{1,18})\/loginhome((\/)?\?.*)?$/,
  PROJECT_LOGIN_MULTI = /^project\/([\d]{1,18})\/login((\/)?\?.*)?$/,
  PROJECT_POST_LOGIN_INTERCEPT =
    /^project\/([\d]{1,18})\/postloginintercept((\/)?\?.*)?$/,
  PROJECT_LOGIN_SSO = /^project\/([\d]{1,18})\/loginsso((\/)?\?.*)?$/,
  PROJECT_SSO_LOGIN_SUCCESS =
    /^project\/([\d]{1,18})\/ssologinsuccess((\/)?\?.*)?$/,
  PROJECT_FRAME_LOGIN_SUCCESS =
    /^project\/([\d]{1,18})\/frameloginsuccess((\/)?\?.*)?$/,
  PROJECT_REGISTER_MULTI = /^project\/([\d]{1,18})\/register((\/)?\?.*)?$/,
  PROJECT_SETTINGS_MULTI = /^project\/([\d]{1,18})\/settings((\/)?\?.*)?$/,
  PROJECT_REFER_A_FRIEND_MULTI = /^project\/([\d]{1,18})\/refer((\/)?\?.*)?$/,
  PROJECT_FRAME = /^project\/([\d]{1,18})\/frame\?url=(.{1,1024})?$/,
  PROJECT_DISCUSSIONS = /^project\/([\d]{1,18})\/discussions((\/)?\?.*)?$/,
  PROJECT_BOARD_POSTS =
    /^project\/([\d]{1,18})\/discussions\/([\d]{1,18})((\/)?\?.*)?$/,
  PROJECT_NEW_POST = /^project\/([\d]{1,18})\/newpost((\/)?\?.*)?$/,
  PROJECT_BOARD_NEW_POST =
    /^project\/([\d]{1,18})\/discussions\/([\d]{1,18})\/newpost((\/)?\?.*)?$/,
  PROJECT_POST_COMMENTS =
    /^project\/([\d]{1,18})\/posts\/([\d]{1,18})\/comments((\/)?\?.*)?$/,
  PROJECT_CERTIFICATES_MULTI =
    /^project\/([\d]{1,18})\/certificates((\/)?\?.*)?$/,
  PROJECT_USER_CERTIFICATES_MULTI =
    /^project\/([\d]{1,18})\/user\/([\d]{1,18})\/certificates((\/)?\?.*)?$/,
  /* For Gametize app layout */
  FEATURED_PROJECTS = /^featured((\/)?\?.*)?$/,
  SEARCH_PROJECTS = /^searchprojects\?search=(.{1,18})?$/,
  MY_PROJECTS = /^myprojects((\/)?\?.*)?$/,
  GAME = /^game\/([\w]{1,18})((\/)?\?.*)?$/;
