import React, { Component } from "react";
import PropTypes from "prop-types";

import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import "./Profile.css";
import "./Certificates/Certificates.css";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
import { TEAM, PROJECT_TEAM, PROJECT_USER_ENQUIRY, CLAIM } from "App/Routes";
import ClaimResultContainer from "components/Project/Activity/ClaimResultContainer";
import Claim from "components/Project/Activity/Claim";
import Link from "components/shared/Link/Link";
import Loading from "components/shared/Loading";

import { getChallengeType } from "services/challengeServices";
import localize from "lang/localize";

import {
  SINGLE_PROJECT_APP,
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT,
  ENABLE_FOLLOWS,
  ENABLE_POINTS,
  ENABLE_ACHIEVEMENTS,
  ENABLE_PROFILE_KPIS,
  ENABLE_CERTIVATE,
} from "config";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  sessionKey: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  userRankStatus: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  showCompletions: PropTypes.bool.isRequired,
  completions: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  meta: PropTypes.array,
  items: PropTypes.array.isRequired,
  itemsLoading: PropTypes.bool,
  achievementsNavLink: PropTypes.string.isRequired,
  followingNavLink: PropTypes.string.isRequired,
  followersNavLink: PropTypes.string.isRequired,
  team: PropTypes.string,
  teamId: PropTypes.number,
  teamImg: PropTypes.string,
  claims: PropTypes.array,
  type: PropTypes.oneOf(["project", "challenge", "claim"]),
  projectId: PropTypes.number,
  more: PropTypes.bool,
  handleMore: PropTypes.func,
  handleLike: PropTypes.func,
  ownProfile: PropTypes.bool,
  isFollowing: PropTypes.bool,
  handleFollow: PropTypes.func,
  followingNo: PropTypes.number,
  followerNo: PropTypes.number,
  removeClaim: PropTypes.func,
  userId: PropTypes.number,
  handleComments: PropTypes.func.isRequired,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
  error: PropTypes.string,
  certificates: PropTypes.array,
  certificatesLoading: PropTypes.bool,
  // login dialogs
  showLoginDialog: PropTypes.bool.isRequired,
  handleOpenLoginDialog: PropTypes.func.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
};

const defaultProps = {
  team: null,
  teamId: null,
  teamImg: placeholder_team,
  contactEmail: CONTACT_EMAIL_DEFAULT,
};

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFollowing: this.props.isFollowing,
      followerNo: this.props.followerNo,
    };
  }
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("userProfilePage")) {
      bodyDOM.className += " userProfilePage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("userProfilePage")) {
      bodyDOM.classList.remove("userProfilePage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render the user's team information
   */
  renderTeam() {
    let teamImg = this.props.teamImg;
    let team = this.props.team;

    return (
      <Link
        to={
          this.props.projectId && !SINGLE_PROJECT_APP
            ? PROJECT_TEAM.format(this.props.projectId, this.props.teamId)
            : TEAM.format(this.props.teamId)
        }
      >
        <div
          id="team"
          className="container highlightbg topbar flex align-items-center"
        >
          <img
            className="iconxs circle rightmargin-10"
            src={teamImg}
            alt={team}
          />
          <div>
            <div dangerouslySetInnerHTML={{ __html: team }} />
          </div>
        </div>
      </Link>
    );
  }

  /**
   * Render dropdown menu button for supporting features
   */
  renderMenuDropdownButton() {
    /*
      renders dropdown button (report user) if both conditions are true:
      (1) there is sessionKey
      (2) profile belongs to another user
    */
    if (this.props.id !== this.props.userId && this.props.sessionKey) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {USE_ENQUIRY_PAGE ? (
                <Link
                  to={PROJECT_USER_ENQUIRY.format(
                    this.props.projectId,
                    this.props.id,
                  )}
                >
                  {localize("report_user", this.props.language)}
                </Link>
              ) : (
                <a
                  href={
                    "mailto:" +
                    this.props.contactEmail +
                    "?subject=" +
                    (CONTACT_EMAIL_TITLE_DEFAULT
                      ? CONTACT_EMAIL_TITLE_DEFAULT
                      : "I want to make a report on Gametize (User ID: " +
                        this.props.id +
                        ")")
                  }
                >
                  {localize("report_user", this.props.language)}
                </a>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render follower/following counts
   */
  renderFollows() {
    return (
      <div className="flex">
        <Link
          to={this.props.followersNavLink}
          className="button-metadata dark rightmargin-10 "
        >
          <div className="lowercase">
            <strong>{this.state.followerNo + " "}</strong>
            {localize("button_user_followers", this.props.language)}
          </div>
        </Link>
        <Link to={this.props.followingNavLink} className="button-metadata dark">
          <div className="lowercase">
            <strong>{this.props.followingNo + " "}</strong>
            {localize("button_user_following", this.props.language)}
          </div>
        </Link>
      </div>
    );
  }

  /**
   * Render the follow button
   * Depending on if the follow request is successful,
   * follow button and follower number will change locally
   */
  renderFollowButton() {
    if (this.props.sessionKey) {
      /* User is logged in */
      return (
        <button
          className={
            "button rightmargin-10 follow-button " +
            (!this.state.isFollowing ? "cta" : "inactive")
          }
          onClick={async () => {
            await this.props.handleFollow();
            if (this.props.error === "") {
              this.setState({
                isFollowing: !this.state.isFollowing,
              });
              this.setState({
                followerNo: this.state.isFollowing
                  ? this.state.followerNo + 1
                  : this.state.followerNo - 1,
              });
            }
          }}
        >
          {this.state.isFollowing
            ? localize("button_user_following")
            : localize("button_user_follow", this.props.language)}
        </button>
      );
    } else {
      return (
        /* User is not logged in */
        <div>
          <button
            className={"button rightmargin-10 follow-button cta"}
            onClick={this.props.handleOpenLoginDialog}
          >
            {localize("button_user_follow", this.props.language)}
          </button>
          {this.props.showLoginDialog && this.renderLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render the profile header
   */
  renderProfile() {
    let name = this.props.name;
    let img = this.props.img;

    return (
      <div>
        {/* Profile Header (Mobile View) */}
        <div className="hide-from-md">
          <div className="pure-g profile-container bottommargin">
            <div className="pure-u-5-24">
              <span className="square-image-wrapper">
                <span className="square-image circle border-gray-light">
                  <img src={img} alt={name} />
                </span>
              </span>
            </div>
            <div className="pure-u-1-24" />
            <div className="pure-u-18-24">
              <div className="leftmargin">
                <div className="pure-g bottommargin-10 flex align-items-center">
                  <div className="pure-u-20-24">
                    <h2 className="nomargin">{name}</h2>
                  </div>
                  <div className="pure-u-4-24">
                    {this.renderMenuDropdownButton()}
                  </div>
                </div>
                <div className="bottommargin-10">
                  {ENABLE_FOLLOWS && this.renderFollows()}
                </div>
                {ENABLE_FOLLOWS &&
                  !this.props.ownProfile &&
                  this.renderFollowButton()}
              </div>
            </div>
          </div>
          {ENABLE_PROFILE_KPIS && (
            <div>
              <hr className="dark" />
              <div className="pure-g topmargin">
                {ENABLE_POINTS && (
                  <div className="pure-u-1-5 pure-u-sm-1-3 relative">
                    <div className="textcenter uppercase">
                      <div className="textcenter font-weight-bold font-size-lg automargin">
                        {this.props.points.abbreviateNumber()}
                      </div>
                      <small>
                        {localize("points_just_text", this.props.language)}
                      </small>
                    </div>
                  </div>
                )}
                {this.props.showCompletions && (
                  <div
                    id="completions"
                    className="pure-u-2-5 pure-u-sm-1-3 relative"
                  >
                    <div className="textcenter uppercase">
                      <div className="textcenter font-weight-bold font-size-lg automargin">
                        {this.props.completions.abbreviateNumber()}
                      </div>
                      <small>
                        {localize("completions_text", this.props.language)}
                      </small>
                    </div>
                  </div>
                )}
                {ENABLE_ACHIEVEMENTS && (
                  <div className="pure-u-2-5 pure-u-sm-1-3 relative">
                    <div className="textcenter uppercase">
                      <div className="textcenter font-weight-bold font-size-lg automargin">
                        {this.props.items.length}
                      </div>
                      <small>
                        {localize(
                          "nav_bar_title_items_text",
                          this.props.language,
                        )}
                      </small>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Profile Header (Desktop View) */}
        <div className="hide-below-md pure-g profile-container flex justify-items-center">
          <div className="pure-u-md-2-24" />
          <div className="pure-u-md-3-24">
            <span className="square-image-wrapper">
              <span className="square-image circle border-gray-light">
                <img src={img} alt={name} />
              </span>
            </span>
            {this.props.userRankStatusImage && (
              <div id="rankStatus" className="photo-icon-wrap">
                <img
                  className="rank-status"
                  src={this.props.userRankStatusImage}
                  alt={this.props.userRankStatus}
                />
              </div>
            )}
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-md-18-24 relative">
            <div className="pure-g">
              <div className="pure-u-md-22-24">
                <h2 className="automargin">{name}</h2>
              </div>
              <div className="pure-u-md-2-24">
                {this.renderMenuDropdownButton()}
              </div>
            </div>
            <div className="topmargin-5 bottommargin flex align-items-center">
              {ENABLE_FOLLOWS &&
                !this.props.ownProfile &&
                this.renderFollowButton()}
              {ENABLE_FOLLOWS && this.renderFollows()}
            </div>
            {ENABLE_PROFILE_KPIS && (
              <div className="profile-points-completions flex">
                {ENABLE_POINTS && (
                  <div className="textcenter uppercase leftmargin rightmargin-30">
                    <div className="textcenter font-weight-bold font-size-xl automargin">
                      {this.props.points.abbreviateNumber()}
                    </div>
                    {localize("points_just_text", this.props.language)}
                  </div>
                )}
                {this.props.showCompletions && (
                  <div className="textcenter uppercase rightmargin-30">
                    <div className="font-size-xl font-weight-bold textcenter automargin">
                      {this.props.completions}
                    </div>
                    {localize("completions_text", this.props.language)}
                  </div>
                )}
                {ENABLE_ACHIEVEMENTS && (
                  <div className="textcenter uppercase">
                    <div className="font-size-xl font-weight-bold textcenter automargin">
                      {this.props.items.length}
                    </div>
                    {localize("nav_bar_title_items_text", this.props.language)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render the custom user options (if any)
   */
  renderMeta() {
    if (!this.props.meta) {
      return null;
    } else {
      return (
        <div className="pure-g">
          <div className="pure-u-1 pure-u-sm-16-24 automargin toppadding">
            {this.props.meta.map((field, index) => {
              let key = field.key;
              let keyValue = field.value;

              return (
                <p className="smaller textcenter" id="metaField" key={index}>
                  <strong>{key}</strong>: {keyValue}
                </p>
              );
            })}
          </div>
        </div>
      );
    }
  }

  /**
   * Render user's achievements section
   */
  renderAchievements() {
    const items = this.props.items;

    if (this.props.itemsLoading) {
      return (
        <div id="profileAchievements">
          <h3>{localize("nav_bar_title_items_text", this.props.language)}</h3>
          <Loading />
        </div>
      );
    } else {
      return (
        <div
          id="profileAchievements"
          className="profile-achievements-container innerpadding"
        >
          <h4 className="automargin">
            {localize("nav_bar_title_items_text", this.props.language)}
          </h4>
          {items && items.length > 0 ? (
            <Link to={this.props.achievementsNavLink} className="flex">
              <div className="pure-u-22-24 overflow-hidden">
                <div className="image-container">
                  {items.slice(0, 5).map((item) => {
                    let imageSmall = item.imageSmall;

                    return (
                      <div
                        className="pure-u-1-3 pure-u-md-1-5 innerpadding"
                        key={item.id}
                      >
                        <span className="square-image-wrapper">
                          <span className="square-image circle">
                            <img src={imageSmall} alt="" />
                            {!item.earned && (
                              <div className="lockoverlay circle" />
                            )}
                          </span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="pure-u-2-24 bottommargin rightmargin flex justify-content-flexend align-items-center">
                <i className="fas fa-angle-right font-size-xxl" />
              </div>
            </Link>
          ) : (
            <h6 className="fadeout">
              {this.props.id === this.props.userId
                ? localize("user_self_no_item_text", this.props.language)
                : localize("user_no_item_text", this.props.language)}
            </h6>
          )}
        </div>
      );
    }
  }

  /**
   * Render user's certificates section
   */
  renderCertificates() {
    const certificates = this.props.certificates;

    if (this.props.certificatesLoading) {
      return (
        <div id="profileCertificates">
          <h3>
            {localize("nav_bar_title_certificates_text", this.props.language)}
          </h3>
          <Loading />
        </div>
      );
    } else {
      return (
        <div
          id="profileCertificates"
          className="profile-achievements-container innerpadding"
        >
          <h4 className="automargin">
            {localize("nav_bar_title_certificates_text", this.props.language)}
          </h4>
          {certificates && certificates.length > 0 ? (
            <Link to={this.props.certificatesNavLink} className="flex">
              <div className="pure-u-22-24 overflow-hidden">
                <div className="image-container">
                  {certificates.slice(0, 5).map((certificate) => {
                    let image = certificate.certificateTemplateImage;

                    return (
                      <div
                        className="pure-u-1-3 pure-u-md-1-5 innerpadding"
                        key={certificate.certificateId}
                      >
                        <span className="square-image-wrapper">
                          <span className="square-image circle">
                            <img src={image} alt="" />
                            {certificate.certificateRevoked && (
                              <div className="revokeoverlay circle" />
                            )}
                          </span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="pure-u-2-24 bottommargin rightmargin flex justify-content-flexend align-items-center">
                <i className="fas fa-angle-right font-size-xxl" />
              </div>
            </Link>
          ) : (
            <h6 className="fadeout">
              {this.props.id === this.props.userId
                ? localize("user_no_cert_text", this.props.language)
                : localize("user_no_cert_text", this.props.language)}
            </h6>
          )}
        </div>
      );
    }
  }

  /**
   * Render user's activity feed
   */
  renderActivity() {
    return (
      <div id="profileActivity">
        {this.props.claims && this.props.claims.length > 0 ? (
          this.props.claims.map((claim, index) => (
            <div
              className="card-link-wrapper claimcard-line bottompadding"
              key={claim.id}
            >
              <Link to={CLAIM.format(claim.id)} className="card-link-overlay" />
              <Claim
                key={claim.id}
                projectId={this.props.projectId}
                claimUserId={claim.userId}
                userId={this.props.userId}
                userName={claim.userName}
                userImage={claim.userPhotoSmall}
                userRankStatus={claim.userRankStatus}
                userRankStatusImage={claim.userRankStatusImage}
                createdAtFormatted={claim.createdAtFormatted}
                points={claim.points}
                challengeId={claim.challenge.id}
                challengeTitle={claim.challenge.title}
                challengeType={getChallengeType(
                  claim.challenge.challengeTypeId,
                  claim.challenge.photoOnly,
                  claim.challenge.multiSelect,
                  claim.challenge.challengeType,
                )}
                challengeTypeId={claim.challenge.challengeTypeId}
                isConfirmationChallenge={
                  claim.challenge.challengeTypeId === 11 &&
                  claim.challenge.confirmation === true
                }
                correctAnswer={claim.correctAnswer}
                challengePhotoOnly={claim.challenge.photoOnly}
                privateClaim={claim.challenge.privateClaim}
                language={this.props.language}
              />
              <ClaimResultContainer
                id={claim.id}
                claimUserId={claim.userId}
                userId={this.props.userId}
                type={this.props.type}
                challengeTypeId={claim.challenge.challengeTypeId}
                claimImage={claim.claimPhotoSmall}
                entries={claim.entries}
                claimMessage={claim.message}
                likeNo={claim.likeNo}
                commentNo={claim.commentNo}
                ratedLike={claim.ratedLike}
                handleLike={(event) => this.props.handleLike(event, claim.id)}
                correctAnswer={claim.correctAnswer}
                removeClaim={this.props.removeClaim}
                privateClaim={claim.challenge.privateClaim}
                userName={claim.userName}
                handleComments={this.props.handleComments}
              />
            </div>
          ))
        ) : (
          <h6 className="fadeout">
            {localize("empty_claim_table_text", this.props.language)}
          </h6>
        )}
        {this.props.isLoadingMore ? <Loading /> : null}
        {/* {this.props.more && (
          <button className="button automargin" onClick={this.props.handleMore}>
            {localize("more_text", this.props.language)}
          </button>
        )} */}
      </div>
    );
  }

  /**
   * Render prompt login popup dialog
   */
  renderLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
      />
    );
  }

  /**
   * Render profile page
   */
  render() {
    return (
      <div className="profile">
        {this.props.team ? this.renderTeam() : null}
        <div className="container verticalpadding darkbg">
          <div className="innerblock">
            {this.renderProfile()}
            {this.renderMeta()}
          </div>
        </div>
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1-24 pure-u-sm-2-24" />
            <div className="pure-u-22-24 pure-u-sm-20-24">
              {ENABLE_ACHIEVEMENTS && this.renderAchievements()}
              {ENABLE_CERTIVATE && this.renderCertificates()}
              {this.props.showCompletions && this.renderActivity()}
            </div>
            <div className="pure-u-1-24 pure-u-sm-2-24" />
          </div>
        </div>
      </div>
    );
  }
}

ProfilePage.propTypes = propTypes;
ProfilePage.defaultProps = defaultProps;

export default ProfilePage;
