import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  HOME,
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
} from "App/Routes";
import EnquiryPage from "./EnquiryPage";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import Loading from "components/shared/Loading";

import { ENABLE_LOGIN_HOME } from "config";
import { setProject, setButtons } from "actions";
import {
  GET_CHALLENGE,
  GET_USER,
  GET_CLAIM_ACTIVITY,
  GET_PROJECT_BUTTONS,
} from "services/api";
import sessionStorageService from "services/sessionStorageService";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

const propTypes = {
  type: PropTypes.oneOf(["challenge", "player", "completion"]),
  id: PropTypes.string,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

export class EnquiryContainer extends Component {
  constructor() {
    super();
    this.state = {
      // 0: challenge, 1: player, 2: completion
      typeList: ["challenge", "player", "completion"],
      status: null,
    };
  }

  componentDidMount() {
    switch (this.props.type) {
      case "challenge":
        this.getChallenge();
        break;
      case "player":
        this.getUserProfile();
        break;
      case "completion":
        this.getClaimActivity();
        break;
      default:
        break;
    }
  }

  getChallenge = () => {
    getApiGenerator(
      GET_CHALLENGE.format(this.props.id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        /* Enquiry forms of locked challenges will still be shown */
        if (res.body.code === 500 && res.body.error.indexOf("locked") !== -1) {
          this.setState({ status: "error_challenge_locked" });
        } else {
          this.setState({ status: "error_challenge" });
        }
      } else {
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        this.setState({ status: "success" });
      }
    });
  };

  getUserProfile() {
    getApiGenerator(
      GET_USER.format(this.props.id),
      {
        bundle_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ status: "error_player" });
        }
      } else {
        this.setState({ status: "success" });
      }
    });
  }

  getClaimActivity() {
    getApiGenerator(
      GET_CLAIM_ACTIVITY.format(this.props.id),
      1,
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ status: "error_completion" });
        }
      } else {
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        this.setState({ status: "success" });
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  getLoginRoute() {
    /* Embedded project tests not necessary here, because loginRedirectUrl is saved */
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  render() {
    const enquiryType = this.state.typeList.indexOf(this.props.type);
    const UNAVAILABLE_CHALLENGE_ERROR_LIST = [
      localize("unavailable_challenge_private_topic", this.props.language),
      localize("unavailable_challenge_private_project", this.props.language),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language),
    ];
    const UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_challenge_private_topic_loggedin",
        this.props.language,
      ),
      localize(
        "unavailable_challenge_private_project_loggedin",
        this.props.language,
      ),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language),
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true";

    if (typeof this.state.status !== "string") {
      return <Loading />;
    } else if (this.state.status === "error_challenge_locked") {
      return (
        <GenericErrorPage
          message={localize("locked_challenge", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (
      this.state.status === "error_challenge" &&
      !this.props.sessionKey
    ) {
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST}
          endMessage={localize(
            "unavailable_challenge_login_prompt",
            this.props.language,
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.status === "error_challenge") {
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else if (this.state.status === "error_player") {
      return (
        <GenericErrorPage
          message={localize("unavailable_profile", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.status === "error_completion") {
      return (
        <GenericErrorPage
          message={localize("unavailable_completion", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return (
        <EnquiryPage
          type={enquiryType}
          id={this.props.id}
          projectId={this.props.projectId}
          sessionKey={this.props.sessionKey}
          language={this.props.language}
        />
      );
    }
  }
}

EnquiryContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryContainer);
