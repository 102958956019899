import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import ReactSoundCloud from "react-soundcloud-embedded";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  CHALLENGE_ENQUIRY,
  CHALLENGE_COMMENTS,
  CHALLENGE_A_FRIEND,
  TOPIC,
} from "App/Routes";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import ImageDialog from "components/shared/Dialogs/ImageDialog/ImageDialog";
import EmbedDialog from "components/shared/Dialogs/EmbedDialog/EmbedDialog";
import Link from "components/shared/Link/Link";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import YoutubePlayer from "components/shared/YoutubePlayer/YoutubePlayer";
import AdobePdfViewer from "components/shared/Adobe/AdobePdfViewer";
import "./Challenge.css";

import {
  ENABLE_CHALLENGE_OPTIONS_BUTTON,
  ENABLE_CONTENT_ENQUIRY,
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT,
  ENABLE_CHALLENGE_COMMENTS,
  ENABLE_CHALLENGE_BOOKMARK_BUTTON,
  ENABLE_CHALLENGE_LIKES,
  ENABLE_POINTS,
  USE_ADOBE_PDF_EMBED,
} from "config";
import deviceServices from "services/deviceServices";
import localize from "lang/localize";
import PostIframe from "../shared/PostIframe/PostIframe";
import urlParse from "library/js/url";

const propTypes = {
  user: PropTypes.object.isRequired,
  sessionKey: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  videoDurationLimit: PropTypes.number,
  // challengeresult
  id: PropTypes.number.isRequired,
  // image
  imageMedium: PropTypes.string,
  // embed
  medias: PropTypes.array,
  // text
  footnote: PropTypes.string,
  title: PropTypes.string,
  repeat: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  repeatAtFormatted: PropTypes.string,
  videoDurationLimitFormatted: PropTypes.string,
  // stats
  commentNo: PropTypes.number,
  claimNo: PropTypes.number,
  challengeType: PropTypes.string.isRequired,
  points: PropTypes.number,
  challengeReferralLink: PropTypes.string,
  isLiked: PropTypes.bool,
  likeNo: PropTypes.number,
  isBookmarked: PropTypes.bool,
  handleBookmark: PropTypes.func,
  handleLike: PropTypes.func,
  // login dialogs
  showLoginDialog: PropTypes.bool,
  handleOpenLoginDialog: PropTypes.func,
  handleCloseLoginDialog: PropTypes.func,
  // image dialogs
  showImageDialog: PropTypes.bool,
  handleOpenImageDialog: PropTypes.func,
  handleCloseImageDialog: PropTypes.func,
  // embed dialogs
  showEmbedDialog: PropTypes.bool,
  handleOpenEmbedDialog: PropTypes.func,
  handleCloseEmbedDialog: PropTypes.func,
  // language
  language: PropTypes.string,
  // contact email
  contactEmail: PropTypes.string,
  // locked content handling
  locked: PropTypes.bool,
  lockedChallengeTopicId: PropTypes.number,
  lockedChallengeTopicTitle: PropTypes.string,
  // project and topic information
  project: PropTypes.object,
  topic: PropTypes.object,
  // minigame
  challengeMinigameTitle: PropTypes.string,
  // context
  context: PropTypes.string,
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT,
};

class ChallengeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
    };
    this.sliderRef = React.createRef();
  }

  getFirstEmbeddableItem() {
    return this.props.medias.filter((media) => media.source === "Embed")[0];
  }

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("challengeInfo")) {
      bodyDOM.className += " challengeInfo";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("challengeInfo")) {
      bodyDOM.classList.remove("challengeInfo");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render challenge image
   */
  renderImage() {
    let imageMedium = this.props.imageMedium;

    return (
      <div>
        {/* Challenge metadata icons (e.g. type and points) */}
        <div className={"challenge-icons-position"}>
          <div
            className={
              "challengeicon icon " +
              (this.props.isConfirmationChallenge
                ? "confirmation"
                : this.props.challengeType)
            }
          />
          {ENABLE_POINTS && this.props.points > 0 && (
            <div className="challengeicon">
              <div className="points">
                +{this.props.points.abbreviateNumber()}
              </div>
            </div>
          )}
        </div>
        {/* Challenge image */}
        <div>
          <span className="square-image-wrapper">
            <span
              className="square-image challenge-image"
              onClick={this.props.handleOpenImageDialog}
            >
              <img src={imageMedium} alt="" />
            </span>
          </span>
          {this.props.showImageDialog && this.renderImageDialog()}
        </div>
      </div>
    );
  }

  /**
   * Check if challenge has any embeddable media
   */
  checkEmbed() {
    let embeddable = this.getFirstEmbeddableItem();

    /*
      For external challenges (challengeTypeId 15),
      we won't be rendering the embedded files like
      typical PDF or video embeds.
    */
    if (embeddable && this.props.challengeTypeId !== 15) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Render embedded media for challenge
   */
  renderEmbed() {
    let embeddable = this.getFirstEmbeddableItem();

    if (
      embeddable &&
      this.props.challengeTypeId !== 15 &&
      embeddable.url.indexOf("https://") === 0
    ) {
      switch (embeddable.type) {
        case "Video":
          return this.renderVideo(embeddable);
        case "PDF":
          return this.renderPdf(embeddable);
        case "Youtube":
          return this.renderYoutube(embeddable);
        case "Vimeo":
          return this.renderYoutubeVimeo(embeddable);
        case "SoundCloud":
          return this.renderSoundCloud(embeddable);
        case "Others":
          return this.renderOthers(embeddable);
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Render challenge media: Soundcloud
   */
  renderSoundCloud(embed) {
    return <ReactSoundCloud url={embed.url} height="100%" />;
  }

  /**
   * Render challenge media: Video
   *
   * @param {object} embed - The embeddable media object
   */
  renderVideo(embed) {
    let displayUrl = embed.displayUrl;

    return (
      <div
        key={displayUrl}
        className={
          "iframe-container" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          hiddenFields={{
            session_key: this.props.sessionKey,
          }}
          url={embed.url}
          title={displayUrl}
          allowFullScreen={true}
        />
      </div>
    );
  }

  /**
   * Render challenge media: Youtube
   *
   * @param {object} embed - The embeddable media object
   */
  renderYoutube(embed) {
    const YOUTUBE_ID = embed.url.match(
      new RegExp("https://www.youtube.com/embed/(.*)\\?rel=0"),
    )[1];

    return (
      <YoutubePlayer
        id={YOUTUBE_ID}
        user={this.props.user}
        contentId={this.props.id}
        contentTitle={this.props.title}
        project={this.props.project}
        topic={this.props.topic}
      />
    );
  }

  /**
   * Render challenge media: Youtube/Vimeo
   *
   * @param {object} embed - The embeddable media object
   */
  renderYoutubeVimeo(embed) {
    let displayUrl = embed.displayUrl;
    let url = embed.url;

    return (
      <div
        className={
          "iframe-container" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <iframe
          title={displayUrl}
          src={url} // use url for Youtube and Vimeo to avoid X-Frame-Options: sameorigin problems
          allowFullScreen
        />
      </div>
    );
  }

  /**
   * Render challenge media: PDF
   *
   * @param {object} embed - The embeddable media object
   */
  renderPdf(embed) {
    let url = embed.url;

    let filename = "File";

    try {
      filename = url
        .substring(url.lastIndexOf("/") + 1)
        .trim()
        .toLowerCase();

      if (!filename || !filename.endsWith(".pdf")) {
        filename = "File";
      }
    } catch (e) {
      filename = "File";
    }

    /*
      in the past, we use the "?.pdf" hack appended at the back of links
      to render websites when website embed wasn't available. to allow such
      embeds to continue being loaded seamlessly, we catch them by checking for "?.pdf"
      filetypes and forcing it into this.renderOthers();
    */
    if (embed.url.indexOf("?.pdf") !== -1) {
      return this.renderOthers(embed);
    } else if (USE_ADOBE_PDF_EMBED) {
      return (
        <div>
          {/* Iframe controls */}
          <div className="iframe-media-controls">
            {/* Icon to expand media (download file) */}
            <a href={url} rel="noopener noreferrer" target="_blank">
              <span className="fas fa-expand"></span>
            </a>
          </div>

          {/* Iframe embed */}
          <div
            className={
              "iframe-container adobe-pdf-viewer" +
              (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
            }
          >
            <AdobePdfViewer
              id={"adobePdfView" + this.props.id}
              url={url}
              encryptedUrl={embed.downloadUrl ? embed.downloadUrl : null}
              filename={filename}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {/* Iframe controls */}
          <div className="iframe-media-controls">
            {/* Icon to expand media (download file) */}
            <a href={url}>
              <span className="fas fa-expand"></span>
            </a>
          </div>

          {/* Iframe embed */}
          <div
            className={
              "iframe-container iframe-container-long" +
              (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
            }
          >
            <iframe
              src={
                "https://docs.google.com/viewer?url=" + url + "&embedded=true"
              }
              title={filename}
              allowFullScreen
              width="100%"
            />
          </div>
        </div>
      );
    }
  }

  /**
   * Render challenge media: Others
   *
   * @param {object} embed - The embeddable media object
   */
  renderOthers(embed) {
    let displayUrl = embed.displayUrl;
    let url = embed.url;

    /* hides iframe, replace with black background */
    if (this.props.showEmbedDialog) {
      return (
        <div
          className={
            "iframe-container" +
            (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
          }
        >
          {this.renderEmbedDialog(embed.url)}
        </div>
      );
    } else {
      /* shows iframe in challenge profile */
      return (
        <div>
          {/* Iframe controls */}
          <div className="iframe-media-controls">
            {/* Icon to expand media */}
            <button onClick={this.props.handleOpenEmbedDialog}>
              <span className="fas fa-expand"></span>
            </button>
          </div>

          {/* Iframe embed */}
          <div
            className={
              "iframe-container" +
              (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
            }
          >
            <iframe
              title={displayUrl}
              src={url}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      );
    }
  }

  /**
   * Add anchor text links
   *
   * @param {string} text - Text
   */
  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  /**
   * Render challenge text (title/description)
   */
  renderText() {
    let footnoteAnchored = this.addAnchors(this.props.footnote);
    let titleAnchored = this.addAnchors(this.props.title);

    return (
      <div className="responsive-img-description">
        {this.props.challengeTypeId === 15 && (
          <p className="smalltext uppercase">
            {" "}
            {this.props.challengeMinigameTitle}{" "}
          </p>
        )}
        <h5 dangerouslySetInnerHTML={{ __html: titleAnchored }} />
        {!(this.props.challengeTypeId === 15) && (
          <p
            className="description-content"
            dangerouslySetInnerHTML={{ __html: footnoteAnchored }}
          />
        )}
        {(this.props.repeat || this.props.videoDurationLimit) && (
          <p className="smalltext topmargin-30" id="repeattext">
            {this.getRepeatText(this.props.repeat)}
            {this.getVideoDurationLimitText()}
          </p>
        )}
      </div>
    );
  }

  /**
   * Render challenge repeatable message
   *
   * @param {boolean} repeat - Whether the challenge is repeatable or not
   */
  getRepeatText(repeat) {
    if (this.props.challengeTypeId === 4 || this.props.context === "comments") {
      return null;
    }

    /* passcode/fixed answer challenge */
    if (this.props.challengeTypeId === 9) {
      return localize(
        "challenge_profile_repeat_once_text",
        this.props.language,
      );
    }

    if (this.props.repeatUntilCorrect) {
      return localize(
        "challenge_profile_repeat_again_text",
        this.props.language,
      );
    } else if (!repeat) {
      return localize(
        "challenge_profile_repeat_once_text",
        this.props.language,
      );
    } else if (this.props.repeatAtFormatted) {
      return localize(
        "challenge_profile_repeat_again_text_with_duration",
        this.props.language,
      ).format(this.props.repeatAtFormatted);
    } else {
      return localize(
        "challenge_profile_repeat_again_text",
        this.props.language,
      );
    }
  }

  /**
   * Render allowable video duration for video challenges
   */
  getVideoDurationLimitText() {
    if (this.props.videoDurationLimitFormatted) {
      return (
        <Fragment>
          <br />
          {localize(
            "challenge_profile_max_duration_text",
            this.props.language,
          ).format(this.props.videoDurationLimitFormatted)}
        </Fragment>
      );
    }
  }

  /**
   * Render challenge statistics (comments/like count... etc)
   */
  renderStats() {
    if (
      this.props.challengeTypeId === 4 &&
      (ENABLE_CHALLENGE_LIKES || ENABLE_CHALLENGE_COMMENTS)
    ) {
      return (
        <p className="smalltext" id="stats">
          {ENABLE_CHALLENGE_COMMENTS
            ? this.props.commentNo.pluralize(
                localize("comment_text", this.props.language),
                localize("comments_text", this.props.language),
              )
            : null}
          {/* rendering spaces below, &nbsp; doesn't render well with JSX */}
          {ENABLE_CHALLENGE_COMMENTS & ENABLE_CHALLENGE_LIKES
            ? "\u00A0\u00A0\u00A0"
            : null}
          {ENABLE_CHALLENGE_LIKES
            ? this.props.likeNo.pluralize(
                localize("like_text", this.props.language),
                localize("likes_text", this.props.language),
              )
            : null}
        </p>
      );
    } else if (this.props.challengeTypeId === 4) {
      return null;
    } else if (ENABLE_CHALLENGE_COMMENTS) {
      return (
        <p className="smalltext" id="stats">
          {this.props.commentNo.pluralize(
            localize("comment_text", this.props.language),
            localize("comments_text", this.props.language),
          )}
          &nbsp;&nbsp;&nbsp;
          {localize("challenge_completed_text", this.props.language).format(
            this.props.claimNo,
          )}
        </p>
      );
    } else {
      return (
        <p className="smalltext" id="stats">
          {localize("challenge_completed_text", this.props.language).format(
            this.props.claimNo,
          )}
        </p>
      );
    }
  }

  /**
   * Render comment button
   */
  renderChallengeCommentsButton() {
    if (ENABLE_CHALLENGE_COMMENTS) {
      return (
        <Link
          to={CHALLENGE_COMMENTS.format(this.props.id) + "#comments"}
          id="commentButton"
          className="button icon inline-block"
        >
          <span className="comment-icon" />
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render all action buttons
   */
  renderButtons() {
    return (
      <div className="challenge-action-buttons-bottom">
        {this.renderBookmarkButton()}
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
        {this.renderMenuDropdownButton()}
      </div>
    );
  }

  /**
   * Render action buttons (bookmark/like, comments)
   * Displayed at the side of the challenge information segment
   */
  renderSideButtons() {
    return (
      <div className="challenge-action-buttons-side">
        {this.renderBookmarkButton()}
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
        {this.renderMenuDropdownButton()}
      </div>
    );
  }

  /**
   * Render menu dropdown buttons
   */
  renderMenuDropdownButton() {
    if (
      this.props.sessionKey &&
      ENABLE_CHALLENGE_OPTIONS_BUTTON &&
      (ENABLE_CONTENT_ENQUIRY || this.props.challengeReferralLink)
    ) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.challengeReferralLink ? (
                <Link to={CHALLENGE_A_FRIEND.format(this.props.id)}>
                  {localize("challenge_referral_text", this.props.language)}
                </Link>
              ) : null}
              {ENABLE_CONTENT_ENQUIRY ? (
                USE_ENQUIRY_PAGE ? (
                  <Link to={CHALLENGE_ENQUIRY.format(this.props.id)}>
                    {localize("report_challenge", this.props.language)}
                  </Link>
                ) : (
                  <a
                    href={
                      "mailto:" +
                      this.props.contactEmail +
                      "?subject=" +
                      (CONTACT_EMAIL_TITLE_DEFAULT
                        ? CONTACT_EMAIL_TITLE_DEFAULT
                        : "I want to make a report on Gametize (Challenge ID: " +
                          this.props.id +
                          ")")
                    }
                  >
                    {localize("report_challenge", this.props.language)}
                  </a>
                )
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render bookmark button
   */
  renderBookmarkButton() {
    if (!ENABLE_CHALLENGE_BOOKMARK_BUTTON) {
      return null;
    } else if (!!this.props.user.id) {
      return (
        <button
          id="bookmarkButton"
          className={
            "button icon inline" +
            (this.props.isBookmarked ? " bookmarked" : "")
          }
          onClick={(event) =>
            this.props.handleBookmark(
              event,
              this.props.id,
              this.props.isBookmarked,
            )
          }
        >
          <span className="bookmark-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            id="loginBeforeBookmarkButton"
            className={
              "button icon inline" +
              (this.props.isBookmarked ? " bookmarked" : "")
            }
            onClick={this.props.handleOpenLoginDialog}
          >
            <span className="bookmark-icon" />
          </button>
          {this.props.showLoginDialog && this.renderLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render like button
   */
  renderLikeButton() {
    if (!ENABLE_CHALLENGE_LIKES) {
      return null;
    } else if (!!this.props.user.id) {
      return (
        <button
          id="likeButton"
          className={
            "button icon inline" + (this.props.isLiked ? " liked" : "")
          }
          onClick={this.props.handleLike}
        >
          <span className="like-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            id="loginBeforeLikeButton"
            className={
              "button icon inline" + (this.props.isLiked ? " liked" : "")
            }
            onClick={this.props.handleOpenLoginDialog}
          >
            <span className="like-icon" />
          </button>
          {this.props.showLoginDialog && this.renderLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render prompt login popup dialog
   */
  renderLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
      />
    );
  }

  /**
   * Render popup dialog for challenge image
   */
  renderImageDialog() {
    return (
      <ImageDialog
        showModal={this.props.showImageDialog}
        handleCloseImageDialog={this.props.handleCloseImageDialog}
        img={this.props.imageMedium}
      />
    );
  }

  /**
   * Render popup dialog for other embeddable challenge media
   *
   * @param {string} url - URL to embed
   */
  renderEmbedDialog(url) {
    return (
      <EmbedDialog
        showDialog={this.props.showEmbedDialog}
        handleCloseEmbedDialog={this.props.handleCloseEmbedDialog}
        url={url}
        title={this.props.title}
      />
    );
  }

  /**
   * Render view
   */
  render() {
    let hasEmbed = this.checkEmbed();

    if (this.props.locked) {
      return (
        <div className="pure-g innerblock">
          <div className="pure-u-1-24" />
          <div className="pure-u-22-24 topmargin-30">
            <GenericErrorPage
              routeUrl={TOPIC.format(this.props.lockedChallengeTopicId)}
              routeName={"“" + this.props.lockedChallengeTopicTitle + "”"}
              message={localize(
                "locked_challenge_with_route",
                this.props.language,
              ).format(this.props.lockedChallengeTopicTitle)}
              language={this.props.language}
            />
          </div>
          <div className="pure-u-1-24" />
        </div>
      );
    } else {
      return (
        <div>
          <div className="container verticalpadding">
            <div className="pure-g innerblock display-block">
              <div className="pure-u-1-24" />
              <div className="pure-u-22-24 pure-u-sm-20-24">
                <div className="pure-u-sm-8-24 relative">
                  {this.renderImage()}
                </div>
                <div className="pure-u-sm-1-24" />
                <div className="pure-u-sm-15-24 topmargin-10">
                  {this.renderText()}
                  {this.props.context !== "comments" && this.renderStats()}
                  {this.props.context !== "comments" && this.renderButtons()}
                </div>
              </div>
              <div className="pure-u-sm-2-24 relative">
                {this.props.context !== "comments" && this.renderSideButtons()}
              </div>
              <div className="pure-u-1-24" />
            </div>
          </div>

          {hasEmbed && (
            <div>
              <div className="challenge-page-divider" />
              <div className="container verticalpadding">
                <div className="pure-g innerblock display-block">
                  <div className="pure-u-1-24 hide-below-md" />
                  <div className="pure-u-md-22-24">{this.renderEmbed()}</div>
                  <div className="pure-u-1-24 hide-below-md" />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

ChallengeInfo.propTypes = propTypes;
ChallengeInfo.defaultProps = defaultProps;

export default ChallengeInfo;
