import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  PROJECT_USER,
  USER,
  PROJECT_PROFILE,
  PROFILE,
  PROJECT_BOARD_POSTS,
  PROJECT_POST_COMMENTS,
} from "App/Routes";
import Link from "components/shared/Link/Link";
import Router from "router";
import ImageDialog from "components/shared/Dialogs/ImageDialog/ImageDialog";
import DeleteItemDialogContainer from "../DeleteItemDialog/DeleteItemDialogContainer";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import urlParse from "library/js/url";

import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import Dotdotdot from "react-dotdotdot";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import "./Post.css";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number,
  userId: PropTypes.number,
  projectId: PropTypes.string,
  postUserName: PropTypes.string,
  postUserId: PropTypes.number,
  postUserImage: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  userRankStatus: PropTypes.string,
  postPinned: PropTypes.bool,
  postTitle: PropTypes.string,
  postContent: PropTypes.string,
  postContentAttributed: PropTypes.array,
  postEmbeds: PropTypes.array,
  postVoteCount: PropTypes.number,
  postCommentCount: PropTypes.number,
  postBookmarkCount: PropTypes.number,
  createdAtFormatted: PropTypes.string,
  updatedAtFormatted: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  boardId: PropTypes.number,
  board: PropTypes.object,
  type: PropTypes.string,
  removePost: PropTypes.func,
  handleLike: PropTypes.func,
  postVoted: PropTypes.bool,
  handleBookmark: PropTypes.func,
  postBookmarked: PropTypes.bool,
  language: PropTypes.string,
  // Is main discussions page or single post page
  singlePost: PropTypes.bool,
  // login dialogs
  showLoginDialog: PropTypes.bool,
  handleOpenLoginDialog: PropTypes.func,
  handleCloseLoginDialog: PropTypes.func,
  // image dialogs
  showImageDialog: PropTypes.bool,
  handleOpenImageDialog: PropTypes.func,
  handleCloseImageDialog: PropTypes.func,
  currentImage: PropTypes.string,
};

const defaultProps = {
  isSubComment: false,
  isMainCommentInThreadPage: false,
};
// should use default pic

const getLink = (props) => {
  if (props.userId === props.postUserId && props.projectId) {
    return PROJECT_PROFILE.format(props.projectId);
  } else if (props.userId === props.postUserId) {
    return PROFILE;
  } else if (props.projectId) {
    return PROJECT_USER.format(props.projectId, props.postUserId);
  } else {
    return USER.format(props.postUserId);
  }
};

const getCommentsLink = (props) => {
  return PROJECT_POST_COMMENTS.format(props.projectId, props.id);
};

const getBoardLink = (props) => {
  return PROJECT_BOARD_POSTS.format(props.projectId, props.boardId);
};

const PostMenuDropdownButton = (props) => {
  if (props.userId === props.postUserId) {
    return (
      <div className="dropdown-button-group">
        <Dropdown
          dropup={true}
          onSelect={(eventKey, event) => {
            event.preventDefault();
          }}
          pullRight={true}
        >
          <Dropdown.Toggle
            btnStyle="flat"
            noCaret={true}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span className="more-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem
              eventKey={1}
              onSelect={(eventKey, event) => {
                event.preventDefault();
                props.setShowDeletePostDialog(true);
              }}
            >
              {localize("delete_post_text", props.language)}
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else {
    return null;
  }
};

const getContentWithMentions = (postContentAttributed, projectId) => {
  let contentArray = [];
  postContentAttributed.map((message, index) => {
    if (message.type === "regular") {
      if (
        message.context === "url-internal" ||
        message.context === "url-external"
      ) {
        contentArray.push(
          <a
            key={index}
            href={message.text}
            onClick={(e) => e.stopPropagation()}
            className="link default link-color underline-on-hover"
          >
            {message.text}
          </a>,
        );
      } else {
        contentArray.push(<span key={index}>{message.text}</span>);
      }
    } else if (message.type === "user") {
      let userLink = PROJECT_USER.format(projectId, message.context);
      contentArray.push(
        <a
          key={index}
          href={userLink}
          onClick={(e) => e.stopPropagation()}
          className="link default link-color underline-on-hover"
        >
          @{message.text}
        </a>,
      );
    }
    return null;
  });
  return contentArray;
};

const PostImages = (props) => {
  return (
    <div className="discussions-embeds-container">
      {props.postEmbeds.map((postFile, index) => (
        <div
          className={`embed-item embed-item-${index + 1}`}
          key={`postImg${index + 1}`}
        >
          <span className="square-image">
            <img
              src={postFile.embedUrl}
              alt="Media"
              onClick={props.handleOpenImageDialog}
            />
            {props.fromComments || props.postEmbeds.length < 3 ? null : (
              <div className="embed-item-overlay">
                <div className="embed-item-overlay-count">
                  +{props.postEmbeds.length - 1}
                </div>
              </div>
            )}
          </span>
          {props.showImageDialog && props.currentImage && (
            <ImageDialogPopup
              showImageDialog={props.showImageDialog}
              handleCloseImageDialog={props.handleCloseImageDialog}
              imageUrl={props.currentImage}
            />
          )}
        </div>
      ))}
    </div>
  );
};

/**
 * Render popup dialog for challenge image
 */
const ImageDialogPopup = (props) => {
  return (
    <ImageDialog
      showModal={props.showImageDialog}
      handleCloseImageDialog={props.handleCloseImageDialog}
      img={props.imageUrl}
      overlayClassName="discussions-images-overlay"
    />
  );
};

const PostMessage = (props) => {
  let postTitle = null;

  if (props.postTitle) {
    postTitle = urlParse(props.postTitle, true); // need hyperlink in title?
  } else {
    postTitle = localize("discussion_empty_title", props.language).format(
      props.postUserName,
    );
  }

  return (
    <div className="fullwidth discussions-post-body">
      <div className="discussions-post-content pure-g display-block">
        <div className="pure-u-1-1">
          <div className="discussions-post-title">
            <strong
              className={!props.fromComments ? "cursor-pointer" : null}
              dangerouslySetInnerHTML={{ __html: postTitle }}
            />
            <PostMenuDropdownButton
              userId={props.userId}
              postUserId={props.postUserId}
              setShowDeletePostDialog={props.setShowDeletePostDialog}
              language={props.language}
            />
          </div>
          <div
            className={
              "discussions-posts-content" +
              (!props.fromComments
                ? " cursor-pointer" + (props.postEmbeds ? " hide-from-md" : "")
                : "")
            }
          >
            {/* Can't seem to get dangerouslySetInnerHTML to work */}
            <Dotdotdot clamp={!props.fromComments ? 4 : 0}>
              {props.postContentAttributed
                ? getContentWithMentions(
                    props.postContentAttributed,
                    props.projectId,
                  ).map((postContent) => postContent)
                : props.postContent}
            </Dotdotdot>
          </div>
        </div>
        {props.postEmbeds && (
          <div
            className={
              "post-embeds-container" +
              (!props.fromComments
                ? " discussions-embeds-container-overflow hide-from-md"
                : "")
            }
          >
            <PostImages
              postEmbeds={props.postEmbeds}
              handleOpenImageDialog={props.handleOpenImageDialog}
              showImageDialog={props.showImageDialog}
              handleCloseImageDialog={props.handleCloseImageDialog}
              currentImage={props.currentImage}
              fromComments={props.fromComments}
            />
          </div>
        )}
      </div>
      <div
        className={
          "discussions-post-meta-below" +
          (props.postEmbeds && !props.fromComments ? " hide-from-md" : "")
        }
      >
        <PostMeta
          board={props.board}
          boardId={props.boardId}
          boardLink={getBoardLink(props)}
          link={getLink(props)}
          commentLink={
            !props.fromComments && props.commentLink ? props.commentLink : null
          }
          fromComments={props.fromComments}
          postUserName={props.postUserName}
          postUserImage={props.postUserImage}
          userRankStatusImage={props.userRankStatusImage}
          userRankStatus={props.userRankStatus}
          createdAtFormatted={props.createdAtFormatted}
        />
      </div>
      {props.postEmbeds && !props.fromComments && (
        <div className="discussions-post-content-summary purex-g hide-below-md">
          <div className="discussions-post-content purex-u-md-17-24">
            <div className="discussions-post-body">
              {/* Can't seem to get dangerouslySetInnerHTML to work */}
              <Dotdotdot clamp={!props.fromComments ? 4 : 0}>
                {props.postContentAttributed
                  ? getContentWithMentions(
                      props.postContentAttributed,
                      props.projectId,
                    ).map((postContent) => postContent)
                  : props.postContent}
              </Dotdotdot>
            </div>
            <PostMeta
              board={props.board}
              boardId={props.boardId}
              boardLink={getBoardLink(props)}
              link={getLink(props)}
              commentLink={
                !props.fromComments && props.commentLink
                  ? props.commentLink
                  : null
              }
              fromComments={props.fromComments}
              postUserName={props.postUserName}
              postUserImage={props.postUserImage}
              userRankStatusImage={props.userRankStatusImage}
              userRankStatus={props.userRankStatus}
              createdAtFormatted={props.createdAtFormatted}
            />
          </div>
          <div className="discussions-embeds-container-overflow purex-u-md-7-24">
            <PostImages
              postEmbeds={props.postEmbeds}
              handleOpenImageDialog={props.handleOpenImageDialog}
              showImageDialog={props.showImageDialog}
              handleCloseImageDialog={props.handleCloseImageDialog}
              currentImage={props.currentImage}
              fromComments={props.fromComments}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const PostMeta = (props) => {
  return (
    <div className="discussions-post-meta-container">
      <div className="discussions-post-meta-userphoto">
        <Link
          to={!!props.link ? props.link : props.commentLink}
          className="userphoto"
        >
          <img
            src={props.postUserImage}
            className="circle border-gray border-thin"
            alt={props.postUserName}
          />
          {props.userRankStatusImage && (
            <div id="rankStatus" className="photo-icon-wrap">
              <img
                className="rank-status"
                src={props.userRankStatusImage}
                alt={props.userRankStatus}
              />
            </div>
          )}
        </Link>
      </div>
      <div className="discussions-post-meta">
        <Link
          to={!!props.link ? props.link : props.commentLink}
          className="discussions-post-username link default underline-on-hover"
        >
          {props.postUserName}
        </Link>
        <br />
        <Link to={props.commentLink}>posted in</Link>
        &nbsp;
        <Link
          to={props.boardLink}
          className="font-weight-bold default link-color underline-on-hover"
        >
          {props.board.boardTitle}
        </Link>
        &nbsp;
        <Link
          to={props.fromComments ? null : props.commentLink}
          className="discussions-post-date"
        >
          - {props.createdAtFormatted}
        </Link>
      </div>
    </div>
  );
};

/**
 * Render prompt login popup dialog for bookmark button
 */
const LoginDialog = (props) => {
  return (
    <LoginDialogContainer
      showModal={props.showLoginDialog}
      handleCloseLoginDialog={props.handleCloseLoginDialog}
    />
  );
};

const BookmarkButton = (props) => {
  if (!!props.userId) {
    return (
      <button
        id="bookmarkButton"
        className={
          "button icon inline rightmargin-10" +
          (props.postBookmarked ? " bookmarked" : "")
        }
        onClick={(event) => props.handleBookmark(event, props.id)}
      >
        <span className="bookmark-icon" />
      </button>
    );
  } else {
    return (
      <div className="inline">
        <button
          id="loginBeforeBookmarkButton"
          className="button icon inline rightmargin-10"
          onClick={props.handleOpenLoginDialog}
        >
          <span className="bookmark-icon" />
        </button>
        {props.showLoginDialog && (
          <LoginDialog
            showLoginDialog={props.showLoginDialog}
            handleCloseLoginDialog={props.handleCloseLoginDialog}
          />
        )}
      </div>
    );
  }
};

const LikeButton = (props) => {
  if (!!props.userId) {
    return (
      <button
        id="likeButton"
        className={
          "button inline rightmargin-10" +
          (props.postVoted ? " liked" : "") +
          (props.postVoteCount > 0
            ? " small button-with-text like-button"
            : " icon")
        }
        onClick={(event) => props.handleLike(event, props.id)}
      >
        <span className="like-icon" />
        {props.postVoteCount > 0 && (
          <span className="button-text like-count">
            {props.postVoteCount.abbreviateNumber()}
          </span>
        )}
      </button>
    );
  } else {
    return (
      <div className="inline">
        <button
          id="loginBeforeLikeButton"
          className={
            "button inline rightmargin-10" +
            (props.postVoteCount > 0
              ? " small button-with-text like-button"
              : " icon")
          }
          onClick={props.handleOpenLoginDialog}
        >
          <span className="like-icon" />
          {props.postVoteCount > 0 && (
            <span className="button-text like-count">
              {props.postVoteCount.abbreviateNumber()}
            </span>
          )}
        </button>
        {props.showLoginDialog && (
          <LoginDialog
            showLoginDialog={props.showLoginDialog}
            handleCloseLoginDialog={props.handleCloseLoginDialog}
          />
        )}
      </div>
    );
  }
};

const Post = (props) => {
  const [showDeletePostDialog, setShowDeletePostDialog] = useState(false);

  return (
    <div className="box-sizing-border-box-all">
      <div
        id={"post" + props.id}
        className={
          "card-link-wrapper discussions-post-container" +
          (props.fromComments ? " post-with-comments" : "") +
          (props.postPinned && !props.fromComments && !props.singlePost
            ? " post-pinned"
            : "")
        }
      >
        {props.postPinned && !props.fromComments && !props.singlePost && (
          <div className="card-pinned-overlay">
            <i className="fa fa-light fa-thumbtack"></i>
          </div>
        )}
        {/* Link to Post */}
        {!props.singlePost && (
          <Link className="card-link-overlay" to={getCommentsLink(props)} />
        )}
        <PostMessage
          projectId={props.projectId}
          postContent={props.postContent}
          postContentAttributed={props.postContentAttributed}
          postEmbeds={props.postEmbeds}
          postTitle={props.postTitle}
          postUserName={props.postUserName}
          fromComments={props.fromComments}
          setShowDeletePostDialog={setShowDeletePostDialog}
          userId={props.userId}
          postUserId={props.postUserId}
          language={props.language}
          board={props.board}
          boardId={props.boardId}
          boardLink={getBoardLink(props)}
          link={getLink(props)}
          commentLink={!props.fromComments ? getCommentsLink(props) : null}
          postUserImage={props.postUserImage}
          userRankStatusImage={props.userRankStatusImage}
          userRankStatus={props.userRankStatus}
          createdAtFormatted={props.createdAtFormatted}
          // Popup for images
          handleOpenImageDialog={props.handleOpenImageDialog}
          showImageDialog={props.showImageDialog}
          handleCloseImageDialog={props.handleCloseImageDialog}
          currentImage={props.currentImage}
        />
        <div className="discussions-post-actions">
          <BookmarkButton
            id={props.id}
            handleBookmark={props.handleBookmark}
            postBookmarked={props.postBookmarked}
            showLoginDialog={props.showLoginDialog}
            handleOpenLoginDialog={props.handleOpenLoginDialog}
            handleCloseLoginDialog={props.handleCloseLoginDialog}
            userId={props.userId}
          />
          <LikeButton
            id={props.id}
            handleLike={props.handleLike}
            postVoted={props.postVoted}
            postVoteCount={props.postVoteCount}
            showLoginDialog={props.showLoginDialog}
            handleOpenLoginDialog={props.handleOpenLoginDialog}
            handleCloseLoginDialog={props.handleCloseLoginDialog}
            userId={props.userId}
          />
          <Link to={!props.fromComments ? getCommentsLink(props) : null}>
            <button
              id="commentButton"
              className={
                "button inline" +
                (props.postCommentCount > 0
                  ? " small button-with-text"
                  : " icon")
              }
              onClick={() =>
                !props.fromComments
                  ? Router.navigate(getCommentsLink(props))
                  : null
              }
            >
              <span className="comment-icon" />
              {props.postCommentCount > 0 && (
                <span className="button-text comment-count">
                  {props.postCommentCount.abbreviateNumber()}
                </span>
              )}
            </button>
          </Link>
        </div>
      </div>
      <DeleteItemDialogContainer
        showDialog={showDeletePostDialog}
        postId={props.id}
        isSubComment={props.isSubComment}
        handleCloseDialog={() => setShowDeletePostDialog(false)}
        removePost={props.removePost}
        type={"post"}
        language={props.language}
      />
    </div>
  );
};

Post.propTypes = propTypes;
Post.defaultProps = defaultProps;

export default Post;
