import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import {
  HOME,
  PROJECT_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
  PROJECT_LOGIN,
  LOGIN,
  TOPIC,
} from "App/Routes";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import CommentsPage from "./CommentsPage";

import {
  setProject,
  setButtons,
  setTopic,
  setChallenge,
  showAlertWithTimeout,
  addProfileDrawerPoints,
  showAwardableActionWithTimeout,
} from "actions";
import { LOAD_MORE_COUNT, ENABLE_LOGIN_HOME } from "config";
import {
  GET_CHALLENGES,
  GET_CHALLENGE,
  GET_PROJECT_BUTTONS,
  GET_CHALLENGE_COMMENTS,
  GET_CLAIM_COMMENTS,
  GET_TOPIC_COMMENTS,
  POST_COMMENT,
  GET_CLAIM_ACTIVITY,
} from "services/api";
import {
  startChallengeTimeTracking,
  endChallengeTimeTracking,
} from "services/challengeServices";
import sessionStorageService from "services/sessionStorageService";
import listenerServices from "services/listenerServices";
import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import localize from "lang/localize";

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    user: state.user ? state.user : null,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    setTopic: (topic) => {
      dispatch(setTopic(topic));
    },
    setChallenge: (challenge) => {
      dispatch(setChallenge(challenge));
    },
    addProfileDrawerPoints: (points) => {
      dispatch(addProfileDrawerPoints(points));
    },
    showAwardableActionWithTimeout: (awardableAction) => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    },
  };
};

export class CommentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      comments: null,
      more: false,
      page: 1,
      input: "",
      /* for displaying achievements after API call */
      showAchievements: false,
      achievements: [],
      // topic
      challenges: null,
      errorProject: null,
      // challenge
      challengeData: null,
      challengeLocked: false,
      errorTopic: null,
      // claim
      claims: null,
      challengeTypeId: null,
      isLoadingMore: false,
      // challenge time tracking,
      isChallengeComments: false,
      // character limit
      charLimit: 0,
    };

    this.setHideAchievements = this.setHideAchievements.bind(this);
    this.setAchievements = this.setAchievements.bind(this);

    this.removeComment = this.removeComment.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getComments = this.getComments.bind(this);
  }

  componentDidMount() {
    if (this.props.type === "quest" || this.props.type === "topic") {
      this.getChallenges(1);
    } else if (this.props.type === "challenge") {
      /* get challenge */
      this.getChallenge();
    } else if (
      this.props.type === "claim" ||
      this.props.type === "completion"
    ) {
      this.getActivity(1);
    }
    window.addEventListener("scroll", this.handleMore);

    /* start challenge time tracking if challenge comments*/
    if (window.location.href.includes("challenge") && this.props.sessionKey) {
      this.setState({ isChallengeComments: true });
      startChallengeTimeTracking(
        this.props.id,
        this.props.sessionKey,
        this.props.showAlertWithTimeout,
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      resolves API call problem:
      (1) checks if prevState.challenges is null, and not array (see constructor)
      (2) then checks if this.state.challenges is array
          (ie getChallenges called for first time, but did not save challenge list)
      (3) then checks if !this.state.challenges[0] (ie it is an empty array)
      (4) if conditions met, re-call API
    */
    if (
      this.props.type === "quest" &&
      !prevState.challenges &&
      !Array.isArray(prevState.challenges) &&
      Array.isArray(this.state.challenges) &&
      !this.state.challenges[0]
    ) {
      this.getChallenges(1);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);

    if (this.state.isChallengeComments && this.props.sessionKey) {
      endChallengeTimeTracking(
        this.props.id,
        this.props.sessionKey,
        this.props.showAlertWithTimeout,
        window.location.pathname,
      );
    }
  }

  getActivity(page) {
    const url = GET_CLAIM_ACTIVITY.format(this.props.id);
    let query = { page: page, limit: LOAD_MORE_COUNT };
    getApiGenerator(url, query, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ claims: [] });
        }
      } else {
        if (this.props.type === "claim" || this.props.type === "completion") {
          const claims = [res.body];
          this.setState({
            claims: claims,
          });
        } else {
          this.setState({
            claims: this.state.claims
              ? this.state.claims.slice().concat(res.body.data)
              : res.body.data,
          });
        }
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        if (res.body.quest) {
          this.props.setTopic(res.body.quest);
        }

        if (res.body.challenge) {
          this.props.setChallenge(res.body.challenge);
          this.setState({
            challengeTypeId: res.body.challenge.challengeTypeId,
          });
        }

        this.getComments(this.state.page);
      }
    });
  }

  getChallenges(page) {
    getApiGenerator(
      GET_CHALLENGES.format(this.state.id),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body && res.body.game) {
          this.setState({ challenges: [], errorProject: res.body.game }, () => {
            this.props.setProject(this.state.errorProject);
          });
        } else {
          this.setState({ challenges: [] });
        }
      } else {
        this.setState({
          challenges: res.body.data,
        });
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        this.props.setTopic(res.body.quest);

        this.getComments(this.state.page);
      }
    });
  }

  getChallenge = () => {
    getApiGenerator(
      GET_CHALLENGE.format(this.props.id),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        /* locked challenge check */
        if (
          res.body.error &&
          res.body.error.indexOf("locked") !== -1 &&
          res.body.quest
        ) {
          this.setState({ challengeData: res.body, challengeLocked: true });
        } else {
          this.setState({ challengeData: false });
        }

        this.setState(
          {
            errorTopic: res.body.quest,
          },
          () => {
            /* setting sidebar */
            if (this.state.errorTopic) {
              this.props.setTopic(this.state.errorTopic);
            }
            if (res.body.game) {
              this.props.setProject(res.body.game);
            }
          },
        );

        this.props.setChallenge(res.body);
      } else {
        this.setState({
          challengeData: res.body,
        });

        this.props.setProject(res.body.game);
        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }
        this.props.setTopic(res.body.quest);
        this.props.setChallenge(res.body);

        this.getComments(this.state.page);
      }
    });
  };

  getComments(page) {
    getApiGenerator(
      this.getCommentsApi(this.props.type),
      { page: page, limit: LOAD_MORE_COUNT },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            comments: [],
            more: false,
            page: 1,
            isLoadingMore: false,
          });
        }
      } else {
        this.setState({
          comments:
            this.state.comments && page > 1
              ? this.state.comments.slice().concat(res.body.data)
              : res.body.data,
          more: res.body.more,
          page: page + 1,
          isLoadingMore: false,
          charLimit: res.body.charLimit,
        });
      }
    });
  }

  getCommentsApi(type) {
    switch (type) {
      case "challenge":
        return GET_CHALLENGE_COMMENTS.format(this.props.id);
      case "claim":
        return GET_CLAIM_COMMENTS.format(this.props.id);
      case "quest":
        return GET_TOPIC_COMMENTS.format(this.props.id);
      default:
        return null;
    }
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  getLoginRoute() {
    /* Embedded project tests not necessary here, because loginRedirectUrl is saved */
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  handleMore = debounce(() => {
    const {
      getComments,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getComments(page);
      }
    }
  }, 100);

  handleInputChange = (event) => {
    this.setState({ input: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.postComment();
  };

  postComment() {
    let query = {
      type: this.props.type,
      type_id: this.state.id,
      content: this.state.input,
    };
    pushApiGenerator(POST_COMMENT, query, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error",
            });
          }
        } else {
          if (res.body.actionPoints && res.body.actionPoints > 0) {
            this.props.addProfileDrawerPoints(res.body.actionPoints);
            this.props.showAwardableActionWithTimeout({
              numberStr: "" + res.body.actionPoints.abbreviateNumber(),
              unit: localize("points_just_text", this.props.language),
            });
          }
          if (res.body.items && res.body.items.length > 0) {
            this.setAchievements(res.body.items);
          }
          this.refreshComments();
          this.setState({ input: "" });
        }
      },
    );
  }

  removeComment(commentId) {
    this.setState({
      comments: this.state.comments.filter(
        (element) => element.id !== commentId,
      ),
    });
  }

  setAchievements(achievements) {
    this.setState({ achievements: achievements, showAchievements: true });
  }

  setHideAchievements() {
    this.setState({ showAchievements: false });
  }

  refreshComments() {
    for (let i = 1; i < Math.max(this.state.page, 2); i++) {
      this.getComments(i);
    }
  }

  allowClaimComments(id) {
    return true; //allow comments for all challenge types
  }

  renderTopicCommentsPage(IS_EMBEDDED_PROJECT) {
    const UNAVAILABLE_TOPIC_ERROR_LIST = [
      localize("unavailable_topic_locked_private", this.props.language),
      localize("unavailable_topic_private_project", this.props.language),
      localize("unavailable_topic_unpublished", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
      localize("unavailable_topic_not_exist", this.props.language),
    ];
    const UNAVAILABLE_TOPIC_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_topic_locked_private_loggedin",
        this.props.language,
      ),
      localize(
        "unavailable_topic_private_project_loggedin",
        this.props.language,
      ),
      localize("unavailable_topic_unpublished", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
      localize("unavailable_topic_not_exist", this.props.language),
    ];
    const UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST = [
      localize("unavailable_topic_locked_private", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
    ];
    const UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_topic_locked_private_loggedin",
        this.props.language,
      ),
      localize("unavailable_topic_no_challenges", this.props.language),
    ];

    if (
      this.state.challenges &&
      this.state.challenges.length !== 0 &&
      this.state.comments
    ) {
      return (
        <CommentsPage
          sessionKey={this.props.sessionKey}
          isLoadingMore={this.state.isLoadingMore}
          comments={this.state.comments}
          more={this.state.more}
          showInput={!!this.props.sessionKey}
          input={this.state.input}
          handleMore={this.handleMore}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
          id={this.props.id}
          type={this.props.type}
          language={this.props.language}
          projectId={this.props.projectId}
          userId={this.props.userId}
          removeComment={this.removeComment}
          /* for switching to Achievements modal */
          showAchievements={this.state.showAchievements}
          achievements={this.state.achievements}
          setHideAchievements={this.setHideAchievements}
          setAchievements={this.setAchievements}
          charLimit={
            this.state.charLimit !== undefined ? this.state.charLimit : 0
          }
        />
      );
    } else if (this.state.challenges && this.state.challenges.length !== 0) {
      /* Comments not loaded yet */
      return <Loading />;
    } else if (
      this.state.challenges &&
      this.state.errorProject &&
      !this.props.sessionKey
    ) {
      /* Project information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST}
          endMessage={localize(
            "unavailable_topic_only_login_prompt",
            this.props.language,
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && this.state.errorProject) {
      /* Project information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={PROJECT_HOME.format(this.state.errorProject.id)}
          routeName={"“" + this.state.errorProject.title + "”"}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST_LOGGEDIN}
          endMessage={localize(
            "unavailable_topic_return_project_prompt",
            this.props.language,
          ).format(this.state.errorProject.title)}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && !this.props.sessionKey) {
      /* No information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_ERROR_LIST}
          endMessage={localize(
            "unavailable_topic_login_prompt",
            this.props.language,
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      /* Everything else */
      return <Loading />;
    }
  }

  renderChallengeCommentsPage(IS_EMBEDDED_PROJECT) {
    const data = this.state.challengeData;
    const errorTopic = this.state.errorTopic;
    const UNAVAILABLE_CHALLENGE_ERROR_LIST = [
      localize("unavailable_challenge_private_topic", this.props.language),
      localize("unavailable_challenge_private_project", this.props.language),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language),
    ];
    const UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_challenge_private_topic_loggedin",
        this.props.language,
      ),
      localize(
        "unavailable_challenge_private_project_loggedin",
        this.props.language,
      ),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language),
    ];

    if (data && this.state.challengeLocked) {
      /* Locked challenge */
      return (
        <GenericErrorPage
          routeUrl={errorTopic ? TOPIC.format(errorTopic.id) : null}
          routeName={errorTopic ? "“" + errorTopic.title + "”" : ""}
          message={
            errorTopic
              ? localize(
                  "locked_challenge_with_route",
                  this.props.language,
                ).format(errorTopic.title)
              : ""
          }
          language={this.props.language}
        />
      );
    } else if (data) {
      return (
        <CommentsPage
          sessionKey={this.props.sessionKey}
          comments={this.state.comments}
          more={this.state.more}
          showInput={!!this.props.sessionKey}
          input={this.state.input}
          handleMore={this.handleMore}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
          id={this.props.id}
          type={this.props.type}
          language={this.props.language}
          projectId={this.props.projectId}
          userId={this.props.userId}
          removeComment={this.removeComment}
          /* for switching to Achievements modal */
          showAchievements={this.state.showAchievements}
          achievements={this.state.achievements}
          setHideAchievements={this.setHideAchievements}
          setAchievements={this.setAchievements}
          charLimit={
            this.state.charLimit !== undefined ? this.state.charLimit : 0
          }
          challengeData={data}
          user={this.props.user}
        />
      );
    } else if (data === false && !this.props.sessionKey) {
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST}
          endMessage={localize(
            "unavailable_challenge_login_prompt",
            this.props.language,
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (data === false) {
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }

  renderClaimCommentsPage() {
    if (
      this.state.claims &&
      this.state.claims.length !== 0 &&
      this.allowClaimComments(this.state.challengeTypeId)
    ) {
      /* Completion exists, and correct challenge type */
      return (
        <CommentsPage
          sessionKey={this.props.sessionKey}
          comments={this.state.comments}
          more={this.state.more}
          showInput={!!this.props.sessionKey}
          input={this.state.input}
          handleMore={this.handleMore}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
          id={this.props.id}
          type={this.props.type}
          language={this.props.language}
          projectId={this.props.projectId}
          userId={this.props.userId}
          removeComment={this.removeComment}
          /* for switching to Achievements modal */
          showAchievements={this.state.showAchievements}
          achievements={this.state.achievements}
          setHideAchievements={this.setHideAchievements}
          setAchievements={this.setAchievements}
          charLimit={
            this.state.charLimit !== undefined ? this.state.charLimit : 0
          }
          claims={this.state.claims[0]}
        />
      );
    } else if (this.state.claims && this.state.claims.length !== 0) {
      /* Completion exists, and wrong challenge type */
      return (
        <GenericErrorPage
          message={localize(
            "unavailable_comments_invalid_challengetype",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (this.state.claims) {
      /* Completion does not exist */
      return (
        <GenericErrorPage
          message={localize(
            "unavailable_comments_invalid_completion",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }

  render() {
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true";

    switch (this.props.type) {
      case "quest":
      case "topic":
        return this.renderTopicCommentsPage(IS_EMBEDDED_PROJECT);
      case "challenge":
        return this.renderChallengeCommentsPage(IS_EMBEDDED_PROJECT);
      case "claim":
      case "completion":
        return this.renderClaimCommentsPage(IS_EMBEDDED_PROJECT);
      default:
        return <Loading />;
    }
  }
}

CommentsContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
