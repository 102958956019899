import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const CategorySelectInput = props => {
  let name = props.name;
  let placeholder = props.placeholder;

  return (
    <select name={name} value={props.selectedId} onChange={props.onChange}>
      <option value="">{placeholder}</option>
      {props.options.map(opt => {
        let optTitle = opt.title;

        return (
          <option key={opt.id} value={opt.id}>
            {optTitle}
          </option>
        );
      })}
    </select>
  );
};

CategorySelectInput.propTypes = propTypes;

export default CategorySelectInput;
