import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  PROJECT_USER,
  USER,
  PROJECT_PROFILE,
  PROFILE,
  TOPIC_COMMENTS_THREAD,
  CHALLENGE_COMMENTS_THREAD,
  CLAIM_COMMENTS_THREAD,
} from "App/Routes";
import Link from "components/shared/Link/Link";
import Router from "router";
import DeleteCommentDialogContainer from "./DeleteCommentDialog/DeleteCommentDialogContainer";

import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number,
  commentUserName: PropTypes.string,
  commentUserPhoto: PropTypes.string,
  commentUserId: PropTypes.number,
  userId: PropTypes.number,
  userRankStatus: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  message: PropTypes.string,
  isSubComment: PropTypes.bool,
  isMainCommentInThreadPage: PropTypes.bool,
  createdAtFormatted: PropTypes.string,
  projectId: PropTypes.number,
  removeComment: PropTypes.func,
  type: PropTypes.string,
  typeId: PropTypes.string,
  commentThreadCount: PropTypes.number,
};

const defaultProps = {
  isSubComment: false,
  isMainCommentInThreadPage: false,
};
// should use default pic

const getLink = (props) => {
  if (props.userId === props.commentUserId && props.projectId) {
    return PROJECT_PROFILE.format(props.projectId);
  } else if (props.userId === props.commentUserId) {
    return PROFILE;
  } else if (props.projectId) {
    return PROJECT_USER.format(props.projectId, props.commentUserId);
  } else {
    return USER.format(props.commentUserId);
  }
};

const getThreadLink = (props) => {
  if (props.type === "quest" || props.type === "topic") {
    return TOPIC_COMMENTS_THREAD.format(props.typeId, props.id);
  } else if (props.type === "challenge") {
    return CHALLENGE_COMMENTS_THREAD.format(props.typeId, props.id);
  } else if (props.type === "completion" || props.type === "claim") {
    return CLAIM_COMMENTS_THREAD.format(props.typeId, props.id);
  }
  return null;
};

const ThreadMenuDropdownButton = (props) => {
  if (
    props.userId === props.commentUserId &&
    !props.isMainCommentInThreadPage
  ) {
    return (
      <div className="dropdown-button-group">
        <Dropdown
          dropup={true}
          onSelect={(eventKey, event) => {
            event.preventDefault();
          }}
          pullRight={true}
        >
          <Dropdown.Toggle
            btnStyle="flat"
            noCaret={true}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span className="more-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem
              eventKey={1}
              onSelect={(eventKey, event) => {
                event.preventDefault();
                props.setShowDeleteCommentDialog(true);
              }}
            >
              {localize("delete_comment_text", props.language)}
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else {
    return null;
  }
};

const DeleteCommentDialogWrapper = (props) => {
  return (
    <DeleteCommentDialogContainer
      showDialog={props.showDeleteCommentDialog}
      commentId={props.id}
      isSubComment={props.isSubComment}
      handleCloseDialog={() => props.setShowDeleteCommentDialog(false)}
      removeComment={props.removeComment}
      type={props.type}
    />
  );
};

const MainThreadCommentCaret = (props) => {
  if (!props.isSubComment && !props.isMainCommentInThreadPage) {
    return (
      <div
        className="comment-caret-container flex justify-content-center"
        to={props.threadLink}
      >
        <i className="fas fa-chevron-right" />
      </div>
    );
  } else {
    return null;
  }
};

const CommentUserPhoto = (props) => {
  return (
    <Link
      to={props.link}
      className="iconxs-flex-basis rightmargin-20 flex-grow-none flex-shrink-none"
    >
      <img
        src={props.commentUserPhoto}
        className="iconxs circle border-gray border-thin"
        alt={props.commentUserName}
      />
      {props.userRankStatusImage && (
        <div id="rankStatus" className="photo-icon-wrap">
          <img
            className="rank-status"
            src={props.userRankStatusImage}
            alt={props.userRankStatus}
          />
        </div>
      )}
    </Link>
  );
};

const CommentUserNameText = (props) => {
  if (props.link) {
    return (
      <Link to={props.link} className="underline-on-hover">
        <strong>{props.commentUserName}</strong>
      </Link>
    );
  } else {
    return <strong>{props.commentUserName}</strong>;
  }
};

const CommentThreadCount = (props) => {
  if (
    !Number.isNaN(Number(props.commentThreadCount)) &&
    props.commentThreadCount > 0 &&
    !props.isSubComment &&
    !props.isMainCommentInThreadPage
  ) {
    return (
      <Link
        className="default topmargin-10 display-block underline-on-hover"
        to={props.threadLink}
      >
        {props.commentThreadCount.pluralize(
          localize("reply_text", props.language),
          localize("replies_text", props.language),
        )}
      </Link>
    );
  } else {
    return null;
  }
};

const getMessageWithMentions = (messageAttributed, projectId) => {
  let messageArray = [];
  messageAttributed.map((message, index) => {
    if (message.type === "regular") {
      messageArray.push(<span key={index}>{message.text}</span>);
    } else if (message.type === "user") {
      let userLink = PROJECT_USER.format(projectId, message.context);
      messageArray.push(
        <a
          key={index}
          href={userLink}
          onClick={(e) => e.stopPropagation()}
          className="link default link-color underline-on-hover"
        >
          @{message.text}
        </a>,
      );
    }
    return null;
  });
  return messageArray;
};

const CommentMessage = (props) => {
  if (
    !props.isSubComment &&
    !props.isMainCommentInThreadPage &&
    props.threadLink
  ) {
    return (
      <div
        className="message font-size-md display-block cursor-pointer"
        onClick={() => Router.navigate(props.threadLink)}
      >
        {props.messageAttributed
          ? getMessageWithMentions(
              props.messageAttributed,
              props.projectId,
            ).map((message) => message)
          : props.message}
      </div>
    );
  } else {
    return (
      <p className="message font-size-md nomargin">
        {props.messageAttributed
          ? getMessageWithMentions(
              props.messageAttributed,
              props.projectId,
            ).map((message) => message)
          : props.message}
      </p>
    );
  }
};

const CommentCreatedAtTimeText = (props) => {
  if (
    !props.isSubComment &&
    !props.isMainCommentInThreadPage &&
    props.threadLink
  ) {
    return (
      <Link className="smalltext display-block" to={props.threadLink}>
        {props.createdAtFormatted}
      </Link>
    );
  } else {
    return <p className="smalltext nomargin">{props.createdAtFormatted}</p>;
  }
};

const CommentButtonsAndCaretSection = (props) => {
  if (!props.isSubComment) {
    return (
      <Link
        className="pure-u-6-24 pure-u-sm-3-24 pure-u-md-2-24 relative flex align-items-center justify-content-center"
        to={props.threadLink}
      >
        <ThreadMenuDropdownButton
          userId={props.userId}
          commentUserId={props.commentUserId}
          setShowDeleteCommentDialog={props.setShowDeleteCommentDialog}
          isMainCommentInThreadPage={props.isMainCommentInThreadPage}
          language={props.language}
        />
        <MainThreadCommentCaret
          isSubComment={props.isSubComment}
          isMainCommentInThreadPage={props.isMainCommentInThreadPage}
          threadLink={props.threadLink}
        />
      </Link>
    );
  } else {
    return (
      <div className="pure-u-6-24 pure-u-sm-3-24 pure-u-md-2-24 relative flex align-items-center justify-content-center">
        <ThreadMenuDropdownButton
          userId={props.userId}
          commentUserId={props.commentUserId}
          setShowDeleteCommentDialog={props.setShowDeleteCommentDialog}
          isMainCommentInThreadPage={props.isMainCommentInThreadPage}
          language={props.language}
        />
        <MainThreadCommentCaret
          isSubComment={props.isSubComment}
          threadLink={props.threadLink}
        />
      </div>
    );
  }
};

const Comment = (props) => {
  const [showDeleteCommentDialog, setShowDeleteCommentDialog] = useState(false);

  return (
    <div className="bottommargin box-sizing-border-box-all">
      <div className="pure-g main-thread-comment-container">
        <div className="pure-u-18-24 pure-u-sm-21-24 pure-u-md-22-24 flex align-items-center">
          <div className="flex align-items-flexstart fullwidth">
            <CommentUserPhoto
              link={getLink(props)}
              commentUserPhoto={props.commentUserPhoto}
              commentUserName={props.commentUserName}
              isMainCommentInThreadPage={props.isMainCommentInThreadPage}
              userRankStatus={props.userRankStatus}
              userRankStatusImage={props.userRankStatusImage}
            />
            <div className="flex-grow-1">
              <CommentUserNameText
                link={getLink(props)}
                commentUserName={props.commentUserName}
              />
              <br />
              <CommentMessage
                projectId={props.projectId}
                isSubComment={props.isSubComment}
                isMainCommentInThreadPage={props.isMainCommentInThreadPage}
                threadLink={getThreadLink(props)}
                message={props.message}
                messageAttributed={props.messageAttributed}
              />
              <CommentCreatedAtTimeText
                threadLink={getThreadLink(props)}
                createdAtFormatted={props.createdAtFormatted}
                isSubComment={props.isSubComment}
                isMainCommentInThreadPage={props.isMainCommentInThreadPage}
              />
              <CommentThreadCount
                commentThreadCount={props.commentThreadCount}
                threadLink={getThreadLink(props)}
                isSubComment={props.isSubComment}
                isMainCommentInThreadPage={props.isMainCommentInThreadPage}
              />
            </div>
          </div>
        </div>
        <CommentButtonsAndCaretSection
          userId={props.userId}
          commentUserId={props.commentUserId}
          threadLink={getThreadLink(props)}
          setShowDeleteCommentDialog={setShowDeleteCommentDialog}
          isSubComment={props.isSubComment}
          isMainCommentInThreadPage={props.isMainCommentInThreadPage}
          language={props.language}
        />
      </div>
      <DeleteCommentDialogWrapper
        showDeleteCommentDialog={showDeleteCommentDialog}
        id={props.id}
        isSubComment={props.isSubComment}
        setShowDeleteCommentDialog={setShowDeleteCommentDialog}
        removeComment={props.removeComment}
        type={props.type}
      />
    </div>
  );
};

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default Comment;
