import React from "react";
import { connect } from "react-redux";
import {
  ProjectsListContainer,
  mapStateToProps
} from "./ProjectsListContainer";
import getApiGenerator from "services/getApiGenerator";

import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import FeaturedProjectsPage from "./FeaturedProjectsPage";

import { LOAD_MORE_COUNT } from "config";
import localize from "lang/localize";

class FeaturedProjectsContainer extends ProjectsListContainer {
  componentDidMount() {
    this.getProjects(this.state.page);
  }

  getProjects(page) {
    getApiGenerator(
      "masters.json",
      {
        type: "featured",
        page: page,
        limit: LOAD_MORE_COUNT
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ projects: [], isLoadingMore: false });
        }
      } else {
        this.setState({
          projects: this.state.projects
            ? this.state.projects.slice().concat(res.body.data)
            : res.body.data,
          more: res.body.more,
          page: this.state.page + 1,
          isLoadingMore: false
        });
      }
    });
  }

  render() {
    if (this.state.projects && this.state.projects.length !== 0) {
      return (
        <FeaturedProjectsPage
          projects={this.state.projects}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          language={this.props.language}
          isLoadingMore={this.state.isLoadingMore}
        />
      );
    } else if (this.state.projects) {
      return (
        <GenericErrorPage
          message={localize("empty_bundles_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps)(FeaturedProjectsContainer);
