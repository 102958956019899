// modified from
// http://krasimirtsonev.com/blog/article/A-modern-JavaScript-router-in-100-lines-history-api-pushState-hash-url

import scrollToId from "library/js/scroll";

const Router = {
  routes: [],
  root: "/",
  redirectUrl: null,
  config: function(options) {
    this.root =
      options && options.root
        ? "/" + this.clearSlashes(options.root) + "/"
        : "/";
    return this;
  },
  getFragment: function() {
    let fragment = "";

    fragment = this.clearSlashes(
      decodeURI(window.location.pathname + window.location.search)
    );
    // fragment = fragment.replace(/\?(.*)$/, "");
    fragment =
      this.root !== "/"
        ? fragment.replace(this.clearSlashes(this.root), "")
        : fragment;
    return this.clearSlashes(fragment);
  },
  clearSlashes: function(path) {
    return path
      .toString()
      .replace(/\/$/, "")
      .replace(/^\//, "");
  },
  add: function(re, handler, category = null) {
    if (typeof re === "function") {
      handler = re;
      re = "";
      // put default route at the end of the list
      this.routes.push({ re: re, handler: handler, category: category });
    } else {
      this.routes.unshift({ re: re, handler: handler, category: category });
    }
    return this;
  },
  remove: function(param) {
    const newRoutes = this.routes.filter(r => {
      return (
        r.handler !== param &&
        r.re.toString() !== param.toString() &&
        r.category !== param
      );
    });
    this.routes = newRoutes;
    return this;
  },
  check: function(f) {
    const self = this;
    const fragment = f || this.getFragment();
    for (let i = 0; i < this.routes.length; i++) {
      const match = fragment.match(this.routes[i].re);
      if (match) {
        match.shift();
        this.routes[i].handler.apply({}, match);
        setTimeout(function() {
          self.restoreScroll();
        }, 200);
        return this;
      }
    }
    return this;
  },
  listen: function() {
    const self = this;
    let current = self.getFragment();
    let hash = null;
    const fn = function() {
      if (hash !== window.location.hash) {
        hash = window.location.hash;
        if (hash !== "") {
          self.hashScroll = setTimeout(scrollToId, 250, hash.replace("#", ""));
        }
      }
      if (current !== self.getFragment()) {
        current = self.getFragment();
        self.check(current);
      }
    };
    clearInterval(this.interval);
    this.interval = setInterval(fn, 50);
    return this;
  },
  navigate: function(path) {
    saveScrollPosition();
    path = path ? path : "";
    window.history.pushState(null, "", this.root + this.clearSlashes(path));
    return this;
  },
  replaceAndNavigate: function(path) {
    saveScrollPosition();
    path = path ? path : "";
    window.history.replaceState(null, "", this.root + this.clearSlashes(path));
    return this;
  },
  restoreScroll: function() {
    let scrollPos = window.history.state
      ? window.history.state.scrollPos
      : undefined;
    if (window.history.state && scrollPos !== undefined) {
      clearTimeout(this.hashScroll); // restoring scroll takes priority over hash scroll
      window.scrollTo(0, scrollPos);
      return scrollPos;
    }
  },
  redirectOrNavigate: function(path) {
    if (this.redirectUrl) {
      this.navigate(this.redirectUrl);
      let redirectCopy = this.redirectUrl;
      this.redirectUrl = null;
      return redirectCopy;
    } else {
      this.navigate(path);
      return path;
    }
  },
  replaceAndRedirectOrNavigate: function(path) {
    if (this.redirectUrl) {
      this.replaceAndNavigate(this.redirectUrl);
      let redirectCopy = this.redirectUrl;
      this.redirectUrl = null;
      return redirectCopy;
    } else {
      this.replaceAndNavigate(path);
      return path;
    }
  }
};

const getScrollPosition = () => window.pageYOffset || window.scrollY;

const saveScrollPosition = () => {
  window.history.replaceState({ scrollPos: getScrollPosition() }, null);
};

export default Router;
