import React, { Fragment } from "react";
import PropTypes from "prop-types";

import MyProjectCardContainer from "./MyProjectCardContainer";
import Loading from "components/shared/Loading";
import SearchBar from "components/shared/SearchBar/SearchBar";
import ProjectsListPage from "./ProjectsListPage";

import { ENABLE_MY_GAMES_SEARCH } from "config";
import localize from "lang/localize";

const propTypes = {
  projects: PropTypes.array,
  more: PropTypes.bool,
  handleMore: PropTypes.func,
  language: PropTypes.string,
  removeProject: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool
};

const defaultTypes = {
  more: false
};

class MyProjectsPage extends ProjectsListPage {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("myProjectsPage")) {
      bodyDOM.className += " myProjectsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("myProjectsPage")) {
      bodyDOM.classList.remove("myProjectsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderProjects() {
    const projects = this.props.projects;
    return projects.map((project, index) => (
      <MyProjectCardContainer
        key={index}
        title={project.title}
        description={project.description}
        img={project.bannerImage}
        id={project.id}
        removeProject={this.props.removeProject}
        private={project.private}
        project={project}
        language={this.props.language}
      />
    ));
  }

  renderProjectsList() {
    if (this.props.projects && this.props.projects.length !== 0) {
      return (
        <div className="projects-list bottompadding-20">
          <div className="innerblock project-cards-wrapper">
            {this.renderProjects()}
            {this.props.isLoadingMore ? <Loading /> : null}
          </div>
        </div>
      );
    } else if (this.props.projects) {
      return (
        <div className="projects-list bottompadding-20">
          <div className="innerblock project-cards-wrapper">
            <div className="project-card-container">
              {localize("empty_bundles_table_text", this.props.language)}
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }

  renderMyProjectsSearchBar() {
    if (ENABLE_MY_GAMES_SEARCH) {
      return (
        <div className="project-cards-wrapper">
          <div className="project-card-container">
            <SearchBar
              handleSubmit={this.props.handleSubmit}
              handleKeyPress={this.props.handleKeyPress}
              handleSearchChange={this.props.handleSearchChange}
              placeholder={localize(
                "home_search_game_placeholder_text",
                this.props.language
              )}
              defaultValue={this.props.keywords}
              language={this.props.language}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <Fragment>
        <div className="container topmargin-30">
          {this.renderMyProjectsSearchBar()}
          <div className="project-cards-wrapper topmargin-20">
            <div className="project-card-container">
              <h4>
                {localize("nav_bar_title_my_bundles_text", this.props.language)}
              </h4>
            </div>
          </div>
          {this.renderProjectsList()}
        </div>
      </Fragment>
    );
  }
}

MyProjectsPage.propTypes = propTypes;
MyProjectsPage.defaultTypes = defaultTypes;

export default MyProjectsPage;
