import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import RedeemRewardDialog from "./RedeemRewardDialog";

const propTypes = {
  showRedeemRewardDialog: PropTypes.bool.isRequired,
  handleCloseRedeemRewardDialog: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  rewardTitle: PropTypes.string.isRequired,
  rewardPoints: PropTypes.number.isRequired,
  rewardId: PropTypes.number.isRequired,
  rewardImg: PropTypes.string.isRequired,
  rewardDescription: PropTypes.string.isRequired,
  rewardDescriptionAfterRedeem: PropTypes.string,
  rewardOwned: PropTypes.number.isRequired,
  rewardOptions: PropTypes.array,
  rewardType: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired
};

export const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language
  };
};

export class RedeemRewardDialogContainer extends Component {
  render() {
    return (
      <RedeemRewardDialog
        showRedeemRewardDialog={this.props.showRedeemRewardDialog}
        handleCloseRedeemRewardDialog={this.props.handleCloseRedeemRewardDialog}
        handleClick={this.props.handleClick}
        rewardTitle={this.props.rewardTitle}
        rewardPoints={this.props.rewardPoints}
        rewardId={this.props.rewardId}
        rewardImg={this.props.rewardImg}
        rewardDescription={this.props.rewardDescription}
        rewardDescriptionAfterRedeem={this.props.rewardDescriptionAfterRedeem}
        rewardOwned={this.props.rewardOwned}
        rewardOptions={this.props.rewardOptions}
        rewardType={this.props.rewardType}
        buttonClass={this.props.buttonClass}
        language={this.props.language}
      />
    );
  }
}

RedeemRewardDialogContainer.propTypes = propTypes;

export default connect(mapStateToProps)(RedeemRewardDialogContainer);
